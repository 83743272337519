import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common'
import { IClaim } from '../../interfaces/claim';
import { ClaimsRetrieve } from '../../models/claims-retrieve.model';
import { ClaimsService } from '../../services/claims.service';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../interfaces/customer.interface';
import { IWarranty } from '../../interfaces/warranty.interface';
import { WarrantyRetrieve } from '../../models/warranty-retrieve.model';
import { WarrantyService } from '../../services/warranty.service';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';
import { MaintenanceAdd } from '../maintenance/maintenance-add/maintenance-add.component';
import { NoteAddDialogComponent } from '../note-add-dialog/note-add-dialog.component';
import { CustomerNotesService } from '../../services/customer-notes.service';
import { ICustomerNote } from '../../interfaces/customer-note.interface';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { ProvinceStateService } from 'src-private/app/services/province-states.service';
import { Subject, of, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  public faEdit = faEdit;
  public faPlus = faPlus;

  public customerId: number
  public claimsId: number;

  public customer$: BehaviorSubject<ICustomer>;
  public warrenties$: BehaviorSubject<IWarranty[]>;
  public notes$: BehaviorSubject<ICustomerNote[]>;
  public claims$: BehaviorSubject<IClaim[]>;

  public postalCodeStateLabel: string
  public postalCodeStateMask: string;
  public provinceStateLabel: string
  private userId: string

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
    private customerService: CustomerService,
    private warrantyService: WarrantyService,
    private accountService: AccountService,
    private noteService: CustomerNotesService,
    private toastr: ToastrService,
    private provinceStateService: ProvinceStateService) {
    super()
  }

  @ViewChild("claimsTable") claimsTable: TableAdapterComponent<IClaim>
  @ViewChild("notesTable") notesTable: TableAdapterComponent<ICustomerNote>
  @ViewChild("warrantiesTable") warrantiesTable: TableAdapterComponent<IWarranty>

  public warrantiesTableColumns: CellBuilder[] = [

    new CellBuilder("Warranty", "description", CellType.text),
    new CellBuilder("VIN", "vehicle.vin", CellType.text),
    new CellBuilder("Year", "vehicle.year", CellType.number),
    new CellBuilder("Make", "vehicle.make", CellType.text),
    new CellBuilder("Model", "vehicle.model", CellType.text),
    new CellBuilder("Dealer Name", "dealerName", CellType.text),
  ];

  public claimsTableColumns: CellBuilder[] = [
    new CellBuilder("ID", "id", CellType.text),
    new CellBuilder("Created Date", "claimsDateEntered", CellType.date),
    new CellBuilder("Closed Date", "claimsDateClosed", CellType.date),
    new CellBuilder("Assigned Adjuster", "claimsOwner", CellType.text),
    new CellBuilder("Status", "claimsStatus", CellType.text)
  ];

  public notesTableColumns: CellBuilder[] = [
    new CellBuilder("Adjuster", "adjusterName", CellType.text),
    new CellBuilder("Note", "note", CellType.text),
    new CellBuilder("Created Date", "createdDate", CellType.date)
  ];

  ngOnInit() {
    this.customer$ = new BehaviorSubject<ICustomer>({} as ICustomer);
    this.warrenties$ = new BehaviorSubject<IWarranty[]>([] as IWarranty[]);
    this.notes$ = new BehaviorSubject<ICustomerNote[]>([] as ICustomerNote[]);
    this.claims$ = new BehaviorSubject<IClaim[]>([] as IClaim[]);

    this.claimsId = +this.route.snapshot.paramMap.get("claimsid");
    this.customerId = +this.route.snapshot.paramMap.get('id');
    this.userId = this.accountService.getUserId();
  }

  ngAfterViewInit() {
    this.loadCustomer();
    this.loadWarranties();
    this.loadClaims();
    this.loadNotes();
    super.build(this, 'customer-component')
  }

  reload(data: ICustomer): void {
    this.customer$.next(data);

    if (data.countryId == 1) {
      this.customer$.value.countryName = "Canada";
    }

    else if (data.countryId == 2) {
      this.customer$.value.countryName = "USA";
    }

    this.updatePostalCodeStateLabel();
    this.updateProvinceStateLabel();
  }

  editCustomer() {
    let dialogRef = this.dialog.open(CustomerEditComponent, { width: '550px', data: this.customer$.value });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.data) {
          const data: ICustomer = res.data;
          if (res.data['country'] == "Canada") {
            data.countryId = 1;
          }
          else if (res.data['country'] == "USA") {
            data.countryId = 2;
          }
          if (res.data.toString().toLowerCase() != "cancel") {
            this.provinceStateService.retrieveProvinceByAbbreviation(res.data['province'])
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(
                response => {
                  var provinceData = response[0] as number
                  data.provinceStateId = provinceData;
                  data.modifiedDate = new Date();
                  data.modifiedBy = this.userId;

                  if (res.data.status == undefined) {
                    this.customerService.update(data)
                      .pipe(takeUntil(this.ngUnsubscribe))
                      .subscribe(res => {
                        this.toastr.success("Customer successfully updated. ", 'Update Customer');
                        this.reload(data);
                      })
                  }
                  else if (res.data.status == 'refresh') {
                    this.reload(data);
                  }
                }
              )
          }
        }
      })
  }

  loadCustomer() {
    if (this.customerId) {

      this.customerService.retrieve(this.customerId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(customer => {
          this.loadProvinceName(customer.provinceStateId);
          this.reload(customer);
        });
    }
  }

  loadProvinceName(provinceId: number) {
    this.provinceStateService.retrieveProvinceById(provinceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          var data = response[0] as string;
          this.customer$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.customer$.value.provinceName = data);
        }
      );
  }

  loadWarranties() {
    const warrantyRetrieve = new WarrantyRetrieve();
    warrantyRetrieve["customerId"] = this.customerId;

    this.warrantiesTable.refresh(this.warrantyService.retrieveAll(warrantyRetrieve))
    this.warrantyService.retrieveAll(warrantyRetrieve)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(warranties => this.warrenties$.next(warranties));
  }

  loadClaims() {
    const claimsRetrieve = new ClaimsRetrieve();
    claimsRetrieve["customerId"] = this.customerId;

    this.claimsTable.refresh(this.claimsService.retrieveAll(claimsRetrieve))
    this.claimsService.retrieveAll(claimsRetrieve)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(claims => {
        this.claims$.next(claims)
      });
  }

  loadNotes() {
    this.notesTable.refresh(this.noteService.retrieveAll(this.customerId));
  }

  addMaintenance() {
    this.dialog.open(MaintenanceAdd, { width: '400px' });
  }

  addNote() {
    const addNoteDialogRef = this.dialog.open(NoteAddDialogComponent,
      {
        width: '600px'
      });

    addNoteDialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.data) {
          res.data.customerId = this.customerId;
          res.data.claimId = this.claimsId;
          this.noteService.add(res.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.toastr.success("Note successfully created. ", 'Create Note');
              this.loadNotes();
            });
        }
      });
  }

  updatePostalCodeStateLabel(): void {
    this.postalCodeStateLabel = this.isCanada ? 'Postal Code' : 'Zip Code'
    this.postalCodeStateMask = this.isCanada ? 'S0S 0S0' : '00000'
  }

  updateProvinceStateLabel(): void {
    this.provinceStateLabel = this.isCanada ? 'Province' : 'State'
  }

  get isCanada(): boolean {
    return this.customer$.value.countryName === Country[Country.Canada]
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
