/*-- Interfaces --*/
import { IClaimsRetrieve } from '../interfaces/claims-retrieve.interface';

export class ClaimsRetrieve implements IClaimsRetrieve {
  claimId: number;
  warrantyId: number;
  customerFirstname: string;
  customerLastname: string;
  vin: string;
  dealer: string;
  assignedAdjuster: string;
  status: string;
  openedBy: string;

  constructor() {
    this.claimId = null;
    this.warrantyId = null;
    this.customerFirstname = null;
    this.customerLastname = null;
    this.vin = null;
    this.dealer = null;
    this.assignedAdjuster = null;
    this.status = 'All';
    this.openedBy = 'All';
  }
}
