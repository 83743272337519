<div>
    <div class="sidebar-header">
        <h4 class="sidebar-title">Quick Add</h4>
    </div>
    <div class="sidebar-body">
        <div class="subtitle">Repair Centre </div>
        <button class="btn custom-button sidePanelButton" (click)="openRepairCenterDialog()" routerLinkActive="active">
            <fa-icon [icon]="faPlus" size="md"></fa-icon>
            <div class="btntext">Add a New Repair Centre...</div>
        </button>
        <div class="subtitle">Dealer</div>
        <button class="btn custom-button sidePanelButton" (click)="navigateToDealer()" routerLinkActive="active">
            <fa-icon [icon]="faPlus" size="md"></fa-icon>
            <div class="btntext">Add a New Dealer...</div>
        </button>
    </div>
</div>