import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { environment } from 'src-private/environments/environment';
import { Notification } from '../interfaces/notification.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, private router: Router) { }

  list(userId: string, isRead: boolean): Observable<Object> {
    const params = {
      userId: userId,
      isRead: isRead,
      type: null
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.get<Notification>(environment.serverUrl + 'api/Notification/list',
      { params: queryParams });
  }

  update(notification: Notification) {
    const params = {
      ID: notification.ID,
      userID: notification.userID,
      data: notification.data,
      title: notification.title,
      description: notification.description,
      isRead: notification.isRead,
      typeID: notification.typeID,
      action: notification.action
    }

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.post(environment.serverUrl + 'api/Notification/update', params);
  }

  create(notification: Notification) {
    const params = {
      ID: notification.ID,
      userID: notification.userID,
      data: notification.data,
      title: notification.title,
      description: notification.description,
      isRead: notification.isRead,
      typeID: notification.typeID,
      action: notification.action
    }

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);

    return this.http.post(environment.serverUrl + 'api/Notification/create', params);
  }

  createWithNotify(notification: Notification): Observable<Notification> {
    let httpParams = new HttpParams();

    Object.keys(notification).forEach(function (key) {
      httpParams = httpParams.append(key, notification[key]);
    });

    return this.http.post<Notification>(`${environment.resourceUrl}api/gwc/notification`, httpParams);
  }

  navigateFunction(param: any) {
    var json = JSON.parse(param.data);
    if (param.action == "claim")
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['claims/' + json.claim.id + '/detail']));
    else if (param.action == "warranty") {
      // Check if the Warranty has a Claim associated with it
      if (json.claim == undefined) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/warranty/' + json.warranty.id]));
      } else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['claims/' + json.claim.id + '/warranty/' + json.warranty.id]));
      }
    }
  }
}
