<div [ngClass]="{content : !claimId, standalone : !claimId}">
  <div class="row">
    <div class="col-xl-7">
      <fieldset class="fieldset-form">
        <legend>Warranty</legend>
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-2">
              <div class="form-group-static">
                <label for="Warranty_ID">Warranty ID</label>
                <input id="Warranty_ID" type="text" readonly class="w-100" [ngModel]="(warranty$ | async)?.id"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group-static">
                <label for="Warranty_Status">Warranty Status</label>
                <input id="Warranty_Status" type="text" readonly class="w-100" [ngModel]="status"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-sm-8">
              <div class="form-group-static">
                <label for="Warranty_Desc">Description</label>
                <input id="Warranty_Desc" type="text" readonly class="w-100"
                  [ngModel]="(warranty$ | async)?.description" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <div class="form-group-static">
                <label for="Warranty_Odometer">Odometer</label>
                <input id="Warranty_Odometer" type="text" readonly class="w-100"
                  [ngModel]="(warranty$ | async)?.odometer" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group-static">
                <label for="Warranty_Odometer_RD">Odometer Read Date</label>
                <input id="Warranty_Odometer_RD" type="text" readonly class="w-100"
                  [ngModel]="(warranty$ | async)?.odometerReadDate | GWDateFormat"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group-static">
                <label for="Warranty_Expiry">Expiry Date</label>
                <input id="Warranty_Expiry" type="text" readonly class="w-100"
                  [ngModel]="(warranty$ | async)?.expiryDate | GWDateFormat" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group-static">
                <label for="Warranty_Deductible">Deductible</label>
                <input id="Warranty_Deductible" type="text" readonly class="w-100"
                  [ngModel]="(warranty$ | async)?.deductible" [ngModelOptions]="{standalone: true}" prefix="$"
                  mask="comma_separator">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group-static">
                <label for="Warranty_Pricing">Pricing</label>
                <input id="Warranty_Pricing" type="text" readonly class="w-100" [ngModel]="(warranty$ | async)?.pricing"
                  [ngModelOptions]="{standalone: true}" prefix="$" mask="comma_separator">
              </div>
            </div>
            <!--<div class="col-md-1"></div>-->
          </div>


        </div>
      </fieldset>

      <fieldset class="fieldset-form">
        <legend>Warranty Options</legend>
        <table-adapter #warrantyOptionsTable [columns]="warrantyOptionsTableColumns"
          [tableLabel]="'warranty-options-table'"></table-adapter>
      </fieldset>
    </div>

    <div class="col-xl-5">
      <fieldset class="fieldset-form">
        <legend>Details</legend>
        <div class="container-fluid form-group">
          <div class="row " style="margin-bottom: 5.03125px;">
            <div class="col-md-2">
              <div class="">
                <label>Customer:</label>
              </div>
            </div>
            <div class="col-md-10">
              <div class="" *ngIf="(customer$ | async)">
                <span>{{(customer$ | async)?.firstName}} {{(customer$ | async)?.lastName}} (<a class="link" (click)="navigateCustomer($event)" routerLinkActive="active">{{(customer$ | async)?.customerId}}</a>)</span>
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom: 5.03125px;">
            <div class="col-md-2">
              <div class="">
                <label>Vehicle:</label>
              </div>
            </div>
            <div class="col-md-10">
              <div *ngIf="(warranty$ | async)?.vehicle">
                <span>{{(warranty$ | async)?.vehicle.year}} {{(warranty$ | async)?.vehicle.make}} {{(warranty$ |
                  async)?.vehicle.model}} (<span class="link" (click)="addMaintenance()"><a class="link">{{(warranty$ |
                      async)?.vehicle.vin}}</a></span>)</span>
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom: 5.03125px;">
            <div class="col-md-2">
              <div class="">
                <label>Dealer:</label>
              </div>
            </div>
            <div class="col-md-10">
              <div class="">
                <span><a class="link" (click)="navigateDealer()">{{(warranty$ | async)?.dealerName}}</a></span>
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom: 5.03125px;">
            <div class="col-md-2">
              <div class="">
                <label for="">LeinHolder:</label>
              </div>
            </div>
            <div class="col-md-10">
              <div class="">
                <span></span>
              </div>
            </div>
          </div>

        </div>
      </fieldset>

      <fieldset class="fieldset-form">
        <legend>
          Claims
          <div *ngIf="funcs['addClaim'];else noAddClaim" class="float-right">
            <button class="btn custom-button float-right" type="button" (click)="addClaim()">
              <fa-icon [icon]="faPlus"></fa-icon> Add Claim
            </button>
          </div>
          <ng-template #noAddClaim>
            <framework-hidden [display]="button"></framework-hidden>
          </ng-template>
        </legend>
        <table-adapter #warrantyClaimsTable [columns]="warrantyClaimsTableColumns"
          [tableLabel]="'warranty-claims-table'" (update)="navigateClaim($event)"></table-adapter>
      </fieldset>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <fieldset class="fieldset-form">
        <legend>
          Documents
          <div *ngIf="funcs['addDocument'];else noAddDocument">
            <button class="btn custom-button float-right" type="button" (click)="addDocument()" [disabled]="!claimId" [ngClass]="{'disableButton': !claimId}">
              <fa-icon [icon]="faPlus"></fa-icon> Add Document
            </button>
          </div>
          <ng-template #noAddDocument>
            <framework-hidden [display]="button"></framework-hidden>
          </ng-template>
        </legend>
        <table-adapter #warrantyDocumentsTable [columns]="warrantyDocumentsTableColumns"
          [tableLabel]="'warranty-documents-table'" (context)="updateDocument($event)"></table-adapter>
      </fieldset>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <fieldset class="fieldset-form">
        <legend>
          Notes
          <div *ngIf="funcs['addNote'];else noAddNote">
            <button class="btn custom-button float-right" type="button" (click)="addNote()" [disabled]="!claimId" [ngClass]="{'disableButton': !claimId}">
              <fa-icon [icon]="faPlus"></fa-icon> Add Note
            </button>
          </div>
          <ng-template #noAddNote>
            <framework-hidden [display]="button"></framework-hidden>
          </ng-template>
        </legend>
        <table-adapter #warrantyNotesTable [tableLabel]="'warranty-notes-table'" [columns]="warrantyNotesTableColumns">
        </table-adapter>
      </fieldset>
    </div>
  </div>
</div>
