import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { dealersRouting } from './dealers.routing';

/*-- Components ~ Dynamic --*/
import { AddNoteComponent } from './components/notes-contacts/notes/add-note/add-note.component';
import { ContactFormComponent } from './components/notes-contacts/contacts/contact-form/contact-form.component';
import { FileFormComponent } from '../eazeetrak/components/file-form/file-form.component';
import { RemoveContactComponent } from './components/notes-contacts/contacts/remove-contact/remove-contact.component';
import { ReviewFileComponent } from '../eazeetrak/components/review-file/review-file.component';

/*-- Components - Static --*/
import { AddContactComponent } from '../eazeetrak/components/add-contact/add-contact.component';
import { AddFileComponent } from '../eazeetrak/components/add-file/add-file.component';
import { AffiliatesComponent } from './components/notes-contacts/affiliates/affiliates.component';
import { AppComponent } from '../../app.component';
import { CompetitorsComponent } from './components/dealer-info/competitors/competitors.component';
import { ContactComponent } from './components/dealer-info/contact/contact.component';
import { ContactsComponent } from './components/notes-contacts/contacts/contacts.component';
import { DealerInfoComponent } from './components/dealer-info/dealer-info.component';
import { DealersComponent } from './components/dealers.component';
import { DealsAndRebatesComponent } from './components/deals-stats/deals-and-rebates/deals-and-rebates.component';
import { DealsStatsComponent } from './components/deals-stats/deals-stats.component';
import { LatestApplicationComponent } from './components/deals-stats/latest-application/latest-application.component';
import { NoteCommentComponent } from './components/notes-contacts/notes/note-comment/note-comment.component';
import { NotesComponent } from './components/notes-contacts/notes/notes.component';
import { NotesContactsComponent } from './components/notes-contacts/notes-contacts.component';
import { SettingsComponent } from './components/dealer-info/settings/settings.component';
import { StatisticsComponent } from './components/deals-stats/statistics/statistics.component';
import { BonusBucksComponent } from './components/bonus-bucks/bonus-bucks.component';
import { CoveragesComponent } from './components/bonus-bucks/coverages/coverages.component';
import { DistributionComponent } from './components/bonus-bucks/distribution/distribution.component';
import { UserRegistrationComponent } from './components/bonus-bucks/distribution/user-registration/user-registration.component';
import { DealerAuthorizationComponent } from './components/bonus-bucks/distribution/dealer-authorization/dealer-authorization.component';
import { FrontPageComponent } from './components/bonus-bucks/front-page/front-page.component';
import { SummaryComponent } from './components/bonus-bucks/summary/summary.component';
import { SelectedCoveragesComponent } from './components/bonus-bucks/coverages/selected-coverages/selected-coverages.component';
import { ChangeComponent } from './components/bonus-bucks/summary/change/change.component';
import { IncompleteRegistrationComponent } from './components/bonus-bucks/incomplete-registration/incomplete-registration.component';
import { EftImageComponent } from './components/bonus-bucks/distribution/eft-image/eft-image.component';
/*-- Modules --*/
import { MaterialModule } from "../../modules/material.module";
import { SharedModule } from '../../modules/shared.module';

/*-- Pipes --*/
import { FilterByPipe } from '../../pipes/filter-by.pipe';
import { AddressFormatPipe } from '../../pipes/address-format.pipe';

/*-- Services --*/
import { ProvinceStateService } from '../../services/province-states.service';
import { BbFormManagementService } from './services/bb-form-management.service';
import { BBAmountService } from './services/bb-selected-amount.service';

/*-- Third Party --*/
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { OrderModule } from 'ngx-order-pipe';
import { QuillModule } from 'ngx-quill';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouteParamsHelper } from '../../helpers/route-params-helper';
import { CRAValidatorDirective } from './directives/CRA-validator-directive';
import { BlockUIModule } from 'ng-block-ui';
import { BBAlterationService } from './services/bb-alteration.service';
import { CoverageComponent } from './components/bonus-bucks/coverages/coverage/coverage.component';
import { EftSelectionComponent } from './components/bonus-bucks/distribution/eft-selection/eft-selection.component';


@NgModule({
  declarations: [
    /*-- Components --*/
    AddContactComponent,
    AddFileComponent,
    AddNoteComponent,
    AffiliatesComponent,
    BonusBucksComponent,
    CoveragesComponent,
    SelectedCoveragesComponent,
    DistributionComponent,
    UserRegistrationComponent,
    IncompleteRegistrationComponent,
    DealerAuthorizationComponent,
    FrontPageComponent,
    SummaryComponent,
    CompetitorsComponent,
    ContactComponent,
    ContactFormComponent,
    ContactsComponent,
    DealerInfoComponent,
    DealersComponent,
    DealsAndRebatesComponent,
    DealsStatsComponent,
    FileFormComponent,
    LatestApplicationComponent,
    NoteCommentComponent,
    NotesComponent,
    NotesContactsComponent,
    RemoveContactComponent,
    ReviewFileComponent,
    SettingsComponent,
    StatisticsComponent,
    /*-- Pipes --*/
    AddressFormatPipe,
    FilterByPipe,
    CRAValidatorDirective,
    ChangeComponent,
    CoverageComponent,
    EftImageComponent,
    EftSelectionComponent
  ],
  entryComponents: [
    AddNoteComponent,
    ContactFormComponent,
    FileFormComponent,
    RemoveContactComponent,
    ReviewFileComponent,
    EftImageComponent
  ],
  exports: [
    RouterModule
  ],
  imports: [
    dealersRouting,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OrderModule,
    RouterModule,
    SharedModule,
    BlockUIModule,
    /*-- Third Party --*/
    NgxMaskModule.forRoot(),
    NgxTrimDirectiveModule,
    FontAwesomeModule,
    MatDatepickerModule, // TODO,
    QuillModule
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    // RequestCacheService
    AppComponent,
    RouteParamsHelper,
    ProvinceStateService,
    BbFormManagementService,
    BBAmountService,
    BBAlterationService,
  ]
})
export class DealersModule { }
