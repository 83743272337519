import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';

/*-- Componenents - Dynamic --*/
import { ReportFilterComponent } from './components/report-filter/report-filter.component';

/*-- Componenents - Static --*/
import { ReportsComponent } from './components/reports.component';

/*-- Modules --*/
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from  '../../modules/shared.module';

/*-- Pipes --*/
import { SafePipe } from '../../pipes/safe.pipe';

/*-- Third Party --*/
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    ReportsComponent,
    ReportFilterComponent,
    SafePipe
  ],
  entryComponents: [ReportFilterComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
    LayoutModule,
    ReactiveFormsModule,
    SharedModule,
    /*-- Third Party --*/
    FontAwesomeModule,
  ],
  providers: []
})
export class ReportsModule { }
