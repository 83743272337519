import { EventEmitter, Injectable } from '@angular/core';
import { hubConnection } from 'signalr-no-jquery';
import { environment } from 'src-private/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationSocketService {

    private hubConnection: any
    private hubProxy: any
    public refreshRequested: EventEmitter<any>;
    private hubConnected: boolean = false;

    public startNotificationSocket() {
        this.refreshRequested = new EventEmitter<any>();

        this.hubConnection = hubConnection(`${environment.socketUrl}`);
        this.hubProxy = this.hubConnection.createHubProxy(`notificationhub`);

        try {
            this.hubConnection.start().done(() => {
                this.hubConnected = true;
            });

            this.hubConnection.reconnecting(() => {
                this.hubConnected = false;
            })

            this.hubConnection.reconnected(() => {
                this.hubConnected = true;
            })

            this.hubProxy.on('RefreshNotifications', () => {
                this.refreshRequested.emit(null);
            });

        } catch (error) {
            console.log("ERROR SingnalR socket: " + error);
        }
    }

    public notifyHubtoEmitRefreshNotice() {
        if (this.hubConnected) {
            this.hubProxy.invoke('NotifyAllToRefreshNotifications')
        }
    }
}
