declare var resourceUrl: string;
declare var serverUrl: string;

export const environment = {
  production: false,
  mapApiKey: 'AIzaSyAlIhOiTy90nSOl4KtIa2MJl3cM7Xhu_70',
  mapCityRadius: 2,
  serverUrl: 'https://alpha.e2.api.globalwarranty.net/',
  resourceUrl: 'https://alpha.api.globalwarranty.net/',
  socketUrl: 'https://alpha.api.globalwarranty.net/',
  messages: {
    apiError: 'An error has occured.  Please contact your administrator.'
  },
  claimsListHighlightRedDaysRangeStart: 3,
  claimsListHighlightRedDaysRangeEnd: 5,
  claimsListHighlightYellowDaysRangeStart: 2,
  claimsListHighlightYellowDaysRangeEnd: 3
};
