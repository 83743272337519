import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ICustomer } from '../interfaces/customer.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src-private/environments/environment';
import { UtilsService } from 'src-private/app/services/utils.service';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  retrieve(id: number): Observable<ICustomer> {
    const params = {
      customerId: id
    };

    const queryParams: HttpParams = UtilsService.buildQueryParams(params);
    return this.http.get<ICustomer>(environment.resourceUrl + 'api/gwc/customer/',
      { params: queryParams });
  }

  update(customer: ICustomer): Observable<ICustomer> {
    return this.http.put<ICustomer>(`${environment.resourceUrl}api/gwc/customer/`, customer);
  }
}
