import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IRepairCentre } from '../interfaces/repair-centre.interface';
import { environment } from 'src-private/environments/environment';
import { IRepairCentreStatuses } from '../interfaces/repair-centre-statuses.interface';
import { IRepairCentreTypes } from '../interfaces/repair-centre-type.interface';

@Injectable({
  providedIn: 'root'
})
export class RepairCentreService {

  constructor(private http: HttpClient) { }

  retrieve(id: number): Observable<IRepairCentre> {
    return this.http.get<IRepairCentre>(`${environment.resourceUrl}api/gwc/repaircentre`,
      { params: { id: id.toString() } });
  }

  retrieveRepairCentresInCity(lat: number, lng: number, radius: number): Observable<IRepairCentre[]> {

    return this.http.get<IRepairCentre[]>(`${environment.resourceUrl}api/gwc/repaircentres/radius`,
      { params: { lat: lat.toString(), lng: lng.toString(), radius: radius.toString() } });
  }

  repairCentreLookupByName(lookupText: string): Observable<IRepairCentre[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('lookupText', lookupText);
    return this.http.get<IRepairCentre[]>(environment.resourceUrl + 'api/gwc/repaircentre/lookupbyname', { params: httpParams });
  }

  update(repairCentre: IRepairCentre): Observable<IRepairCentre> {
    console.log("update")
    return this.http.put<IRepairCentre>(`${environment.resourceUrl}api/gwc/repaircentre/`, repairCentre);
  }

  add(repairCentre: IRepairCentre): Observable<IRepairCentre> {
    return this.http.post<IRepairCentre>(`${environment.resourceUrl}api/gwc/repaircentre`, repairCentre);
  }

  retrieveStatuses(): Observable<IRepairCentreStatuses[]> {
    return this.http.get<IRepairCentreStatuses[]>(`${environment.resourceUrl}api/gwc/repaircentre/statuses/`);
  }

  retrieveTypes(): Observable<IRepairCentreTypes[]> {
    return this.http.get<IRepairCentreTypes[]>(`${environment.resourceUrl}api/gwc/repaircentre/types/`);
  }
}
