import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CoverageChange, DistributionChange, CoveragePackage } from '../../../models/bonus-bucks.Models';
import { BBAlterationService } from '../../../services/bb-alteration.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<boolean>();

  changedCoveragePackages: CoveragePackage[];
  distributionChanges: Array<DistributionChange>;

  submitForm = new FormGroup({
    isTermsAgreed: new FormControl(false, Validators.requiredTrue),
  });

  loading = true;

  constructor(private alterationService: BBAlterationService) { }

  ngOnInit() {
    this.distributionChanges = new Array<DistributionChange>();
    this.changedCoveragePackages = this.alterationService.getChangedCoverages();
    this.distributionChanges = this.alterationService.getDistributionChanges();
    this.loading = false;
  }

  get isCoveragesInvalid(): boolean {
    return this.changedCoveragePackages.some(cp => cp.Coverages.some(cov => cov.IsChecked && cov.BonusBucksAmount === 0));
  }

  onSubmit() {
    this.submitClicked.emit(true);
  }

  covPackAllSelected(cp: CoveragePackage): boolean {
    return cp.Coverages.every(cov => cov.IsChecked);
  }
}
