import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {JwtHelperService} from '@auth0/angular-jwt';
import {OidcSecurityService} from "angular-auth-oidc-client";

declare const Promise: any;

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  jwtHelper: any;
  token: any = null;

  constructor(private http: HttpClient, public dialog: MatDialog, private oidcSecurityService: OidcSecurityService) {
    this.jwtHelper = new JwtHelperService();
  }

  public getToken(): any {
    if (this.token) {
      return this.token;
    }
    this.token = this.jwtHelper.decodeToken(this.oidcSecurityService.getToken());
    return this.token;
  }

  public getUserName(): string {
    return this.getToken().FirstName + ' ' + this.getToken().LastName;
  }

  public getUserId(): string {
    return this.getToken().UserId;
  }

  public getUserRoles(): string {
    return this.getToken().Roles;
  }

  public isInRole(role: string): boolean {
    return this.getUserRoles().indexOf(role) > -1;
  }

  isAuthenticated(): boolean {
    return true;
  }

  logout() {
    this.oidcSecurityService.logoff();
  }
}
