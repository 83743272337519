import { Injectable } from "@angular/core";
import { IClaim } from "../interfaces/claim";

@Injectable({
    providedIn: 'root'
  })
export class Claim implements IClaim {
  id: number;
  claimsDateEntered: Date;
  claimsDateClosed: Date;
  claimsRepairCenterId: number;
  warrantyId: number;
  customerId: number;
  customerFirstname: string;
  customerLastname: string;
  vin: string;
  vendorId: number;
  vendorName: string;
  assignedAdjuster: string;
  assignedAdjusterName: string;
  claimsStatus: string;
  claimsCorrection: string;
  claimsOwner: string;
  claimsTransProc: boolean;
  claimTotal: number;
  warrantyVIN: string;
  repairCenterName: string;
  previousClaimsStatus: string;
  previousAssignedAdjuster: string;
  version: number;
}
