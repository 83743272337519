import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { dealersRouting } from './claims.routing';

//Components
import { ClaimsComponent } from './components/claims.component';
import { CustomerComponent } from './components/customer/customer.component';
import { CustomerEditComponent } from './components/customer/customer-edit/customer-edit.component';
import { ClaimsListComponent } from './components/claims-list/claims-list.component';
import { WarrantyComponent } from './components/warranty/warranty.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MaintenanceAdd } from './components/maintenance/maintenance-add/maintenance-add.component';
import { DetailComponent } from './components/detail/detail.component';
import { ComplaintAddDialogComponent } from './components/complaint-add-dialog/complaint-add-dialog.component';
import { DocumentAddDialogComponent } from './components/document-add-dialog/document-add-dialog.component';
import { NoteAddDialogComponent } from './components/note-add-dialog/note-add-dialog.component';
import { RepairCentreSearchDialogComponent } from './components/repair-centre/repair-centre-search-dialog/repair-centre-search-dialog.component';
import { PartDialogComponent } from './components/part-dialog/part-dialog.component';
import { ClaimEditComponent } from './components/claims-list/claim-edit/claim-edit.component';
import { ClaimLockComponent } from './components/claims-list/claim-lock/claim-lock.component';

import { MaterialModule } from "../../modules/material.module";

//Third Party
import { BlockUIModule } from 'ng-block-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RepairCentreComponent } from './components/repair-centre/repair-centre.component';
import { RepairCentreEditComponent } from './components/repair-centre/repair-centre-edit/repair-centre-edit.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { SharedModule } from 'src-private/app/modules/shared.module';
import {DataTablesModule} from "angular-datatables";




@NgModule({
  declarations: [
    ClaimsComponent,
    CustomerComponent,
    CustomerEditComponent,
    ClaimsListComponent,
    CustomerEditComponent,
    WarrantyComponent,
    DetailComponent,
    MaintenanceComponent,
    RepairCentreComponent,
    RepairCentreEditComponent,
    ComplaintAddDialogComponent,
    DocumentAddDialogComponent,
    NoteAddDialogComponent,
    RepairCentreSearchDialogComponent,
    MaintenanceAdd,
    PartDialogComponent,
    ClaimEditComponent,
    ClaimLockComponent
  ],
  imports: [
    dealersRouting,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    FontAwesomeModule,
    RouterModule,
    MaterialModule,
    NgxTrimDirectiveModule,
    NgxMaskModule.forRoot(),
    DataTablesModule
  ],
  entryComponents: [
    CustomerEditComponent,
    ClaimEditComponent,
    RepairCentreEditComponent,
    ComplaintAddDialogComponent,
    DocumentAddDialogComponent,
    NoteAddDialogComponent,
    RepairCentreSearchDialogComponent,
    MaintenanceAdd,
    PartDialogComponent,
    ClaimLockComponent
  ]
})
export class ClaimsModule { }
