import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IClaimLock } from '../../../interfaces/claim-lock.interface';
import { faWindowClose, faLock, faLockOpen } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-claim-lock',
  templateUrl: './claim-lock.component.html',
  styleUrls: ['./claim-lock.component.scss']
})
export class ClaimLockComponent implements OnInit {

  public claimLock: IClaimLock
  public faLock = faLock;
  public faWindowClose = faWindowClose;
  public faLockOpen = faLockOpen;

  constructor(
    private dialogRef: MatDialogRef<ClaimLockComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.claimLock = data
  }

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  manuallyUnlock() {
    this.dialogRef.close({ manuallyUnlock: true })
  }
}
