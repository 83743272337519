import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILoginUser } from '../../interfaces/loginuser.interface';
import { AccountService } from "../../../account/services/account.service";
import { LoginUserService } from '../../services/loginuser.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { ComplaineeType } from 'src-private/app/enums/complainee-type';

@Component({
  selector: 'app-complaint-add-dialog',
  templateUrl: './complaint-add-dialog.component.html',
  styleUrls: ['./complaint-add-dialog.component.scss']
})
export class ComplaintAddDialogComponent implements OnInit {

  public complaintAddForm: FormGroup;
  public parts: string[];
  public complainees: ILoginUser[];
  public filteredComplainees: ILoginUser[];
  public adjusterAutoCompleteText: string;
  public complaineeTypes = new Map<string, number>();
  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public complaintBox: string = ''
  public complaineeText: string = ''
  public partText: string = ''
  private prevComplaineeText:string =''
  private prevPartText:string =''

  public isSubmitted: boolean = false;
  public isLoading: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private accountService:AccountService,
    private loginUserService: LoginUserService,
    private dialogRef: MatDialogRef<ComplaintAddDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.parts = data;
  }

  ngOnInit() {
    this.complaintAddForm = this.formBuilder.group({
      complainee: ['', Validators.required],
      part: ['', Validators.required],
      complaint: ['', Validators.required],
      assignedAdjuster:['',Validators.required]
    });

    this.setComplaineeType()
    this.complaintAddForm.patchValue({assignedAdjuster: this.accountService.getUserId()})
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  addComplaint() {
    this.isSubmitted = true;
    if (AppValidators.validateAll(this.complaintAddForm)) {

      var complaintData = {
        complainee: this.complaintAddForm.value.complainee,
        part: this.complaintAddForm.value.part,
        complaint: this.complaintAddForm.value.complaint,
        assignedAdjuster:this.complaintAddForm.value.assignedAdjuster,
      }
      this.dialogRef.close({ data: complaintData })
    }
  }

  fillTextarea() {
    if(this.complaintAddForm.controls['part'].valid  && this.complaintAddForm.controls['complainee'].valid)
    {
      this.complaintBox = this.complaintAddForm.value.complaint ;
      this.partText =" part " + ' " '+this.complaintAddForm.value.part.partName+' "  ';
      this.complaineeText = ComplaineeType[this.complaintAddForm.value.complainee].split('_').join(" ");
      var compNum :number = 0;

     if(this.complaintBox != "")
     {
       if(this.complaintBox.includes(this.prevPartText))
       {
          this.complaintBox = this.complaintBox.replace(this.prevPartText, this.partText)
          compNum =compNum + 1
        }


       if(this.complaintBox.includes(this.prevComplaineeText))
       {
          this.complaintBox = this.complaintBox.replace(this.prevComplaineeText, this.complaineeText)
          compNum = compNum +1
        }

        else if(compNum != 2 )
        {
          this.complaintBox = this.complaineeText+this.partText+' '+this.complaintBox;
        }
        this.complaintAddForm.patchValue({complaint:this.complaintBox});
      }
      else
      {
        this.complaintBox = this.complaineeText + this.partText
        this.complaintAddForm.patchValue({ complaint: this.complaintBox })

      }

      this.prevPartText = this.partText;
      this.prevComplaineeText = this.complaineeText;
    }
  }

  setComplaineeType(){
   Object.keys(ComplaineeType).forEach(ele=>{
    if(isNaN(Number(ele)) === false) {
      let val : String =ComplaineeType[ele]
      this.complaineeTypes.set(val.split('_').join(" "), Number.parseInt(ele))
    }
   })
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
