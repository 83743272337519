<form [formGroup]="dealerForm" (ngSubmit)="onSubmitDealer()" novalidate>

  <fieldset class="fieldset-form"
            [hidden]="bp < 3 && hideContact">
    <legend>Contact</legend>

    <app-contact [contactForm]="dealerForm.controls.contact"
                 [formState]="formState"
                 [submitted]="isSubmitted"></app-contact>

  </fieldset>

  <fieldset class="fieldset-form"
            [hidden]="bp < 3 && hideSettings">
    <legend>Settings</legend>

    <app-settings [settingsForm]="dealerForm.controls.settings"
                  [dealer]="dealer"
                  [formState]="formState"
                  [submitted]="isSubmitted"></app-settings>

  </fieldset>

  <fieldset class="fieldset-form"
            [hidden]="bp < 3 && hideCompetitors">
    <legend>Competitors</legend>

    <app-competitors [competitorsForm]="dealerForm.controls.competitors"
                     [dealer]="dealer"
                     [formState]="formState"
                     [submitted]="isSubmitted"></app-competitors>

  </fieldset>

  <footer>

    <ul class="list-unstyled button-row">
      <li class="button-cell"
          *ngIf="showCancelButton">

        <button class="btn btn-lg btn-dark btn-icon btn-cancel"
                type="button"
                (click)="onCancelClick()">
                <fa-icon [icon]="faTimes" class="text-danger"></fa-icon> Cancel
        </button>

      </li>
      <li class="button-cell"
          *ngIf="showSaveButton">

        <button class="btn btn-lg btn-dark btn-icon btn-save"
                type="submit">
                <fa-icon [icon]="faCheck" class="text-success"></fa-icon> Save
        </button>

      </li>
    </ul>

  </footer>

</form>
