import { AbstractControl, ValidatorFn, ValidationErrors, FormControl } from '@angular/forms';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';

export function sinValidator(country: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (country.value === Country.UnitedStates) {
      return null;
    }
    const sin = control.value;
    if (sin === 'xxxxxxxxx' || sin === null) {
      return null;
    }
    let alternate = false;
    let sum = 0;
    for (let i = sin.length - 1; i >= 0; i--) {
      if (sin[i] < '0' || sin[i] > '9') { continue; }

      const n = sin[i] * 1;
      if (alternate) {
        let dbl = n * 2;
        if (dbl > 9) { dbl = dbl - 9; }
        sum = sum + dbl;
      } else {
        sum = sum + n;
      }
      alternate = !alternate;
    }

    if (sum % 10 === 0) {
      return null;
    }
    return { 'validateSin': true };
  };
}
