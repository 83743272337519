import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AccountService} from '../areas/account/services/account.service';
import {MatDialog} from "@angular/material/dialog";
import {OidcSecurityService} from "angular-auth-oidc-client";



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService, private oidcSecurityService: OidcSecurityService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentToken = this.oidcSecurityService.getToken();
    if (currentToken !== '') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`
        }
      });
    }

    return next.handle(request);
  }
}
