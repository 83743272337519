import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Dealer } from '../../../models/dealer.model';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { faTimes, IconDefinition, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { BbFormManagementService } from '../../../services/bb-form-management.service';
import { DistributionType, UserType } from 'src-private/app/enums/bonus-bucks.enums';
import { DistributionUser, DealerPrincipal } from '../../../models/bonus-bucks.Models';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent implements OnInit {

  @Input() distributionUsers: Array<DistributionUser>;
  @Input() dealer: Dealer;

  private individualUserTypes: Map<String, UserType>;

  distributionForm: FormGroup;
  dealerPrincipal: DealerPrincipal;
  userTypes: any;

  constructor(private fb: FormBuilder, private formManagerService: BbFormManagementService, private cd: ChangeDetectorRef) {
    this.individualUserTypes = new Map<String, UserType>();
  }

  ngOnInit() {
    this.userTypes = Object.keys(UserType).filter(Number).map(k => UserType[k]);
    this.distributionForm = this.formManagerService.distributionForm;
    this.distributionsForm.controls.forEach(element => {
      const distro = element as FormGroup;
      const userType = distro.value.userType as string;
      if (distro.value.id && userType) {
        this.individualUserTypes.set(distro.value.id, UserType[userType]);
      }
    });
 
    this.dealerPrincipal = this.formManagerService.dealerPrincipal;
    this.updateRows();
  }

  onRebateCheckboxChange(checked: boolean, distro: FormGroup) {
    const userRegistration = <FormGroup>distro.get('userRegistrationFG');
    if (!checked) {
      this.setDistributionEnabled(distro, false);
    } else {
      this.setDistributionEnabled(distro, true);
      const userType = distro.value.userType as string;
      if (userType) {
        this.toggleFields(userRegistration, UserType[userType]);
      }
    }
  }

  setDistributionEnabled(distro: FormGroup, enable: boolean) {
    const userType = distro.get("userType")
    const userRegistration = distro.get('userRegistrationFG');
    if (enable) {
      userRegistration.enable();
      userType.enable();
    } else {
      userRegistration.disable();
      userType.disable();
    }
  }

  onDistributionTypeChange(distTypeId: number) {
    this.distributionForm.get('distributionTypeId').patchValue(distTypeId);
    this.updateRows();
  }

  onUserTypeChange(distro: FormGroup, userTypeVal: string) {
    const userType = UserType[userTypeVal];
    this.individualUserTypes.set(distro.value.id, userType)
    this.setUserType(distro, userType);
    this.toggleFields(<FormGroup>distro.get('userRegistrationFG'), userType);
  }
  
  setUserType(distro: FormGroup, userType: UserType) {
    const userRegistration = <FormGroup>this.formManagerService.getUserRegistrationForm(distro.value.id, userType)
    distro.patchValue({ userType: UserType[userType] });
    distro.get('userRegistrationFG').disable()
    distro.setControl("userRegistrationFG", userRegistration);
    this.updateDistribution(userRegistration);
  }

  toggleFields(userRegistration: FormGroup, userType: UserType) {
    if (userType === UserType.Dealer) {
      const excluded = ['currentDistribution', 'paymentType' ];
      for (const name in userRegistration.controls) {
        const field = userRegistration.get(name);
        setTimeout(() => {
          field.enable();
          field.markAsUntouched();
          if (field.value && field.valid && !field.dirty && !excluded.includes(name)) {
            if (field.enabled) {
              field.disable();
            }
          }
        });
      }
    } else {
      setTimeout(() => userRegistration.enable());
    }
  }

  updateRows() {
    this.distributionsForm.controls.forEach(e => this.updateRow(e as FormGroup));
  }

  updateRow(distro: FormGroup) {
    const selectedControl = distro.get('selected');
    const name = distro.get('name').value;

    if (name === this.dealer.legalName
      || name === 'Holding Company'
      || name === 'Dealer Group') {
      if (this.isPercentage) {
        selectedControl.enable();
      } else {
        selectedControl.setValue(false);
        selectedControl.disable();
      }
    } else {
      let userType = this.individualUserTypes.get(distro.value.id);
      if (userType) {
        this.setUserType(distro, userType); 
      }
    }

    if (selectedControl.value) {
      const userRegistration = <FormGroup>distro.get('userRegistrationFG')
      if (userRegistration) {
        const userType = distro.value.userType as string;
        this.toggleFields(userRegistration, UserType[userType]);
      }
    }
  }

  updateDistribution(userRegistration: FormGroup) {
    const currentDistribution = userRegistration.get('currentDistribution');
    if (this.isPercentage) {
      currentDistribution.patchValue(userRegistration.get('previousDistribution').value || 0);
      if (!userRegistration.disabled) {
        currentDistribution.enable();
      }
    } else {
      currentDistribution.patchValue(100);
      currentDistribution.disable();
    }
  }

  getUserTypeDesc(userType: string) : String {
    return UserType[userType] === UserType.HoldingCompany ? 'Holding Company' : userType;
  }

  getRadioControlState(distro: FormGroup) {
    return !distro.get('userType').value ? 'error' : '';
  }

  canShowUserType(distro: FormGroup) : boolean {
    const name = distro.get('name').value;
    return !(name === this.dealer.legalName || name === 'Holding Company' || name === 'Dealer Group')
  }

  get distributionsForm(): FormArray {
    return <FormArray>this.distributionForm.get('distributions');
  }

  get icon(): IconDefinition {
    return this.total === 100 ? faCheck : faTimes;
  }

  get arrow(): IconDefinition {
    return faArrowLeft;
  }

  get total(): number {
    return this.distributionsForm.value.reduce((accumulator: number, currentValue) => {
      if(currentValue.selected && currentValue.userRegistrationFG) {
        return accumulator + currentValue.userRegistrationFG.currentDistribution;
      } else {
        return accumulator;
      }
    }, 0);
  }

  get isIndividual() : boolean {
    return this.distributionForm.value.distributionTypeId === DistributionType.Individual;
  }

  get isPercentage() : boolean {
    return this.distributionForm.value.distributionTypeId === DistributionType.Percentage;
  }

  get validationErrors(): string {
    if (this.distributionForm.hasError('invalidTotal')) {
      return 'Distribution Total is Invalid';
    } else if (this.distributionForm.hasError('invalidDistributionType')) {
      return 'No Distribution Type Selected';
    } else if (this.distributionForm.hasError('notAllChecked')) {
      return 'All Users must be Selected';
    } else if (this.distributionForm.hasError('noDistribution')) {
      return 'No Distribution Selected';
    } else if (this.distributionForm.hasError('noRecipient')) {
      return 'All Users must be assigned a payee';
    } else {
      return 'Missing Required Field\n';
    }
  }
}
