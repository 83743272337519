<div class="header">

  <form [formGroup]="form">

    <div class="container-fluid">

      <div class="form-row">
        <div [ngClass]="{ 'offset-sm-3': bp === 6 }">
        </div>
        <div [ngClass]="{ 'col-sm-9': bp === 6, 'col-sm-12': bp !== 6  }">

          <div class="form-row">
            <div [ngClass]="{ 'col-sm-6': bp < 5, 'col-sm-5': bp > 4 }">

              <div class="form-row">
                <div class="col-6">

                  <div class="header-field-outer">
                    <div class="header-field-inner">
                      <label class="form-field-label">Status</label>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="fileStatusOpen" formControlName="fileStatus"
                               class="custom-control-input" value="Open" (change)="onFileStatusChange()">
                        <label class="custom-control-label" for="fileStatusOpen">Open</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="fileStatusClosed" formControlName="fileStatus"
                               class="custom-control-input" value="Closed" (change)="onFileStatusChange()">
                        <label class="custom-control-label" for="fileStatusClosed">Closed</label>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-6">

                  <div class="header-field-outer">
                    <div class="header-field-inner">
                      <label class="form-field-label">Opened By</label>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input"
                               id="openedByAll"
                               type="radio"
                               value="All"
                               formControlName="openedBy"
                               (change)="onOpenedByChange()">
                        <label class="custom-control-label" for="openedByAll">All</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input"
                               id="openedBySelf"
                               type="radio"
                               value="Self"
                               formControlName="openedBy"
                               (change)="onOpenedByChange()">
                        <label class="custom-control-label" for="openedBySelf">Self</label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div [ngClass]="{ 'col-sm-6': bp < 5, 'col-sm-7': bp > 4 }">

              <div class="form-row">
                <div class="col-6">

                  <div class="header-field-outer">
                    <div class="header-field-inner">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-field-label" id="agent-addon">Agent</span>
                        </div>
                        <select aria-describedby="agent-addon"
                                aria-label="agent"
                                class="custom-select custom-select"
                                formControlName="dealerAccountManager"
                                (change)="onDealerAccountManagerChange()">
                          <option [value]="item.dealerAccountManagerId" *ngFor="let item of dealerAccountManagers">
                            {{item.dealerAccountManager}}
                          </option>
                        </select>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="col-6">

                  <div class="header-field-outer">
                    <div class="header-field-inner">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="search-addon"> <fa-icon [icon]="faSearch"></fa-icon></span>
                        </div>
                        <input aria-describedby="search-addon"
                               aria-label="Search"
                               class="form-control"
                               placeholder="Search"
                               type="text"
                               formControlName="search"
                               (keydown.esc)="onSearchTextEscKeyDown()"
                               (keyup)="onSearchTextKeyUp()"/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>


        </div>
      </div>

    </div>

  </form>

</div>

<div class="content">

  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table claim-list-table" >
    <thead>
    <tr>
      <th></th>
      <th>ID</th>
      <th>Dealer</th>
      <th>Reason</th>
      <th>Result</th>
      <th>Opened Date</th>
      <th>Due Date</th>
      <th>ClosedDate</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of filteredEazeeTrakFiles">
      <tr>
        <td class="file-status"><span class="dot" [ngClass]="getOverdueClass(item)"></span></td>
        <td>{{ item.eazeeTrakFileId }}</td>
        <td>{{ item.alias }}</td>
        <td>{{ item.reason }}</td>
        <td>{{ item.result }}</td>
        <td>{{ item.openedDate | date: 'MMM d, yyyy' }}</td>
        <td>{{ item.dueDate | date: 'MMM d, yyyy' }}</td>
        <td>{{ item.closedDate | date: 'MMM d, yyyy' }}</td>
        <td class="data-cell-action">
          <a href="javascript:void(0);"
             (click)="onReviewClick($event, item)">
              <span class="icon">
                <fa-icon [icon]="faEye"></fa-icon>
              </span>
            <span class="label">
                Review
              </span>
          </a>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>

</div>
