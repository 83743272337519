import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { IClaimsNotes } from '../../interfaces/claims-notes.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { LoginUserService } from '../../services/loginuser.service';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ILoginUser } from '../../interfaces/loginuser.interface';
import { AccountService } from 'src-private/app/areas/account/services/account.service';


@Component({
  selector: 'app-note-add-dialog',
  templateUrl: './note-add-dialog.component.html',
  styleUrls: ['./note-add-dialog.component.scss']
})
export class NoteAddDialogComponent implements OnInit {

  public noteAddForm: FormGroup;
  public filteredAdjusters: ILoginUser[];

  public faSave = faSave;
  public faWindowClose = faWindowClose;

  private ngUnsubscribe: Subject<any> = new Subject();
  public isSubmitted: boolean = false;
  public isLoading: boolean = false;
  public adjusterAutoCompleteText: string;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private loginUserService: LoginUserService,
    @Inject(MAT_DIALOG_DATA)
    public claimNote: IClaimsNotes,
    private dialogRef: MatDialogRef<NoteAddDialogComponent>) { }

  ngOnInit() {
    this.noteAddForm = this.formBuilder.group({
      adjusterId: ['', Validators.required],
      note: ['', Validators.required],
      createdDate: [''],
    });

    this.noteAddForm.patchValue({adjusterId:this.accountService.getUserId()});
    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  addNote() {
    this.isSubmitted = true;
    if (AppValidators.validateAll(this.noteAddForm)) {
      this.noteAddForm.patchValue({ createdDate: new Date().toJSON(), });

      // //add adjusterId to object, assign adjuster value to adjusterId and delete adjuster
      // this.noteAddForm.value.adjusterId = this.noteAddForm.value.adjuster;
      // delete this.noteAddForm.value["adjuster"];

      this.dialogRef.close({ data: this.noteAddForm.value })
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
