import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { sinValidator } from '../../../directives/sin-validator';
import { ProvinceState, DistributionUser } from '../../../models/bonus-bucks.Models';
import { IncompleteRegistrationService } from '../../../services/incomplete-registration.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProvinceStateService } from 'src-private/app/services/province-states.service';
import { Country, PaymentMethod } from 'src-private/app/enums/bonus-bucks.enums';
import { incompleteRegistrationsValidator } from '../../../directives/incomplete-registrations-validator';
import { MatDialog } from '@angular/material/dialog';
import { EftImageComponent } from '../distribution/eft-image/eft-image.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-incomplete-registration',
  templateUrl: './incomplete-registration.component.html',
  styleUrls: ['./incomplete-registration.component.scss']
})
export class IncompleteRegistrationComponent implements OnInit {

  isAuthenticated: boolean;
  userRegistrationForm: FormGroup;
  user$: DistributionUser;
  guid: string;
  provinceStates: ProvinceState[];
  submitting = false;

  constructor(
    private fb: FormBuilder,
    private incRegService: IncompleteRegistrationService,
    private provStateService: ProvinceStateService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService) {   }

  ngOnInit() {
    this.initializeForm();
    this.getProvinceStates();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.guid = params.get('guid');
      this.incRegService.getAuthorizedUser(this.guid).subscribe((user) => this.onUserChange(user));
    });
    this.userRegistrationForm.setValidators([incompleteRegistrationsValidator]);
    this.userRegistrationForm.markAllAsTouched();
  }

  getProvinceStates() {
    this.provStateService.currentStates.subscribe((response: ProvinceState[]) => this.provinceStates = response);
  }

  onUserChange(user: DistributionUser) {
    this.user$ = user;
    this.getAuthentication();
    if (this.isAuthenticated) {
      const provLabel = this.provinceStates.find((prov) => prov.ProvinceStateId === user.UserRegistrationDetails.ProvinceStateId).Label;
      this.userRegistrationForm.setValue({
        firstName: user.FirstName,
        lastName: user.LastName,
        address: user.UserRegistrationDetails.Address,
        phone: user.UserRegistrationDetails.Phone,
        email: user.Email,
        country: user.UserRegistrationDetails.CountryId,
        city: user.UserRegistrationDetails.City,
        postalZipCode: user.UserRegistrationDetails.PostalZipCode,
        paymentType: user.UserRegistrationDetails.PaymentTypeId,
        provinceState: provLabel,
        id: user.Id,
        sin: user.UserRegistrationDetails.SocialInsuranceNumber ? user.UserRegistrationDetails.SocialInsuranceNumber : '',
        branchRouting: user.UserRegistrationDetails.BranchRouting,
        institutionCheck: user.UserRegistrationDetails.InstitutionCheck,
        account: user.UserRegistrationDetails.Account,
        eftimage: user.UserRegistrationDetails.EFTImage
      });
    }
  }

  initializeForm() {
    this.userRegistrationForm = this.fb.group({
      firstName: [{ value: '', disabled: true }],
      lastName: [{ value: '', disabled: true }],
      address: [{ value: '', disabled: true }, Validators.required],
      phone: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(10)]],
      email: [{ value: '', disabled: true }, [Validators.email, Validators.required]],
      country: [{ value: 1, disabled: true }],
      postalZipCode: [{ value: '', disabled: true }, Validators.required],
      city: [{ value: '', disabled: true }, Validators.required],
      provinceState: [{ value: '', disabled: true }, Validators.required],
      paymentType: [{ value: 0, disabled: true }, Validators.required],
      id: [''],
      branchRouting: null,
      institutionCheck: null,
      account: null,
      eftimage: null,
    });

    // Add user specific controls
    this.userRegistrationForm.addControl('sin', new FormControl('', [
      Validators.minLength(9),
      Validators.maxLength(9),
      Validators.required,
      sinValidator(this.userRegistrationForm.get('country') as FormControl)]));
  }

  getAuthentication() {
    this.isAuthenticated = !!this.user$;
  }

  onSubmit() {
    if (this.userRegistrationForm.valid) {
      this.submitting = true;
      const bbd = this.getBonusBucksDistribution();
      this.incRegService.submitRegistration(bbd, this.guid).subscribe((newUser: DistributionUser) => {
        this.toastr.success('Your profile has been updated successfully.', 'User Registration');
        this.submitting = false;
      },
      err => {
        this.toastr.error('Your profile was not updated.', 'User Registration');
        this.submitting = false;
      });
    }
  }

  getBonusBucksDistribution(): DistributionUser {
    this.user$.UserRegistrationDetails.SocialInsuranceNumber = this.userRegistrationForm.value.sin;
    if (this.userRegistrationForm.get('paymentType').value === PaymentMethod.EFT) {
      this.user$.UserRegistrationDetails.EFTImage = this.userRegistrationForm.value.eftimage;
      this.user$.UserRegistrationDetails.BranchRouting = this.userRegistrationForm.value.branchRouting;
      this.user$.UserRegistrationDetails.InstitutionCheck = this.userRegistrationForm.value.institutionCheck;
      this.user$.UserRegistrationDetails.Account = this.userRegistrationForm.value.account;
    }
    return this.user$;
  }

  _keyDown(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (keyCode === 8 && this.sinControl.value.includes('x')) {
      this.sinControl.reset();
    }

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  onChequeUpload(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = () => {
        this.userRegistrationForm.get('eftimage').patchValue(reader.result);
      };
    }
  }

  viewLastEFT() {
    this.dialog.open(EftImageComponent, {data: {eftimage: this.userRegistrationForm.get('eftimage').value}});
  }

  getValidation(): boolean {
    if (this.userRegistrationForm.hasError('invalidEFT')) {
      return true; // true = disabled
    } else if (this.userRegistrationForm.hasError('invalidSIN')) {
      return true;
    } else if (this.userRegistrationForm.status === 'INVALID') {
      return true;
    }
  }

  get phoneControl() { return this.userRegistrationForm.get('phone'); }
  get addressControl() { return this.userRegistrationForm.get('address'); }
  get emailControl() { return this.userRegistrationForm.get('email'); }
  get countryControl() { return this.userRegistrationForm.get('country'); }
  isCanada(): boolean { return this.countryControl.value === Country.Canada; }
  get postalControl() { return this.userRegistrationForm.get('postalZipCode'); }
  get cityControl() { return this.userRegistrationForm.get('city'); }
  get postalZipCodePlaceholder() { return this.isCanada() ? 'Postal Code' : 'Zip Code'; }
  get postalZipCodeMask() { return this.isCanada() ? 'S0S0S0' : '99999'; }
  get provinceStateControl() { return this.userRegistrationForm.get('provinceState'); }
  get provincesOrStates() { return null; }
  get paymentMethodControl() { return this.userRegistrationForm.get('paymentType'); }
  get nameControl() { return this.userRegistrationForm.get('name'); }
  get sinControl() { return this.userRegistrationForm.get('sin'); }
  get craNumControl() { return this.userRegistrationForm.get('craNum'); }
  get paymentMethod() { return PaymentMethod; }

}
