import { IMaintenanceRetrieve } from '../interfaces/maintenance-retrieve.interface';

export class MaintenanceRetrieve implements IMaintenanceRetrieve {
  warrantyId: number;
  claimId: number;
  constructor() {
    this.warrantyId = null;
    this.claimId = null;
  }
}
