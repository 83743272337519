<form [formGroup]="claimForm">
  <fieldset class="fieldset-form">
    <legend>
      Claim
      <fa-icon *ngIf="funcs['editClaim'];else noEditClaim" [icon]="faEdit" role="button" (click)="editClaim()">
      </fa-icon>
      <ng-template #noEditClaim>
        <framework-hidden [display]="button"></framework-hidden>
      </ng-template>
      <span class="shop-key d-flex flex-column float-right align-middle">
        <span>Shop Key</span>
        <a class="link" href="https://www.shopkey5.com" target="_blank">https://www.shopkey5.com</a>
      </span>
    </legend>
    <div class="container-fluid">
      <div class="form-row">
        <div class="form-row col-xl-5">
          <div class="col-md-2 col-lg-3">
            <div class="form-group-static">
              <label>Claim ID</label>
              <input formControlName="claimId" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group-static">
              <label>Warranty ID</label>
              <input formControlName="warrantyId" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group-static">
              <label>Assigned Adjuster</label>
              <input formControlName="assignedAdjusterName" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-3 col-lg-2">
            <div class="form-group-static">
              <label>Open Date</label>
              <input formControlName="claimsDateEntered" type="text" readonly class="w-100">
            </div>
          </div>
        </div>
        <div class="form-row col-xl-7">
          <div class="col-md-2">
            <div class="form-group-static">
              <label>Close Date</label>
              <input formControlName="claimsDateClosed" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group-static">
              <label>Claim Status</label>
              <input formControlName="claimsStatus" type="text" readonly class="w-100">
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group-static">
              <label>Repair Center</label>
              <a class="link label-right" (click)="changeRepairCentre()">Change Repair Center</a>
              <input formControlName="repairCenterName" type="text" readonly class="w-100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</form>

<form [formGroup]="complaintForm">
  <fieldset class="fieldset-form">
    <legend>
      Complaint
      <fa-icon *ngIf="funcs['addComplaint'];else noAddComplaint" [icon]="faEdit" (click)="addComplaint()" role="button">
      </fa-icon>
      <ng-template #noAddComplaint>
        <framework-hidden [display]="button"></framework-hidden>
      </ng-template>
    </legend>
    <table-adapter #complaintsTable [tableLabel]="'complaints-table'" [columns]="complaintsTableColumns">
    </table-adapter>
  </fieldset>
</form>

<fieldset class="fieldset-form">
  <legend>
    Parts
    <div *ngIf="funcs['addPart'];else noAddPart">
      <button class="btn custom-button float-right" type="button" (click)="addPart()">
        <fa-icon [icon]="faPlus"></fa-icon> Add New Parts
      </button>
    </div>
    <ng-template #noAddPart>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #partsTable [columns]="partsTableColumns" [tableLabel]="'parts-table'" (update)="updatePart($event)">
  </table-adapter>
</fieldset>
<fieldset class="fieldset-form">
  <legend>
    Documents
    <div *ngIf="funcs['addDocument'];else noAddDocument">
      <button class="btn custom-button float-right" type="button" (click)="addDocument()">
        <fa-icon [icon]="faPlus"></fa-icon> Add Document
      </button>
    </div>
    <ng-template #noAddDocument>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #claimsDocumentsTable [columns]="claimsDocumentsTableColumns" [tableLabel]="'documents-table'"
    (context)="updateDocument($event)">
  </table-adapter>
</fieldset>
<fieldset class="fieldset-form">
  <legend>
    Notes
    <div *ngIf="funcs['addNote'];else noAddNote">
      <button class="btn custom-button float-right" type="button" (click)="addNote()">
        <fa-icon [icon]="faPlus"></fa-icon> Add Note
      </button>
    </div>
    <ng-template #noAddNote>
      <framework-hidden [display]="button"></framework-hidden>
    </ng-template>
  </legend>
  <table-adapter #claimsNotesTable [tableLabel]="'notes-table'" [columns]="claimsNotesTableColumns">
  </table-adapter>
</fieldset>