import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { environment } from '../../../../../../../environments/environment';

/*-- Enums --*/
import { FormState } from '../../../../../../enums/form-state.enum';
import { RoleGroup } from '../../../../../../enums/role-group.enum';
import { TitleGroup } from '../../../../../../enums/title-group.enum';

/*-- Interfaces --*/
import { IRole } from '../../../../interfaces/role.interface';
import { ITitle } from '../../../../interfaces/title.interface';
import { IUser } from '../../../../interfaces/user.interface';

/*-- Services --*/
import { DealersService } from '../../../../services/dealers.service';
import { IdentityService } from '../../../../../../services/identity.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit, AfterViewInit {
  @BlockUI() blockUI: NgBlockUI;

  @Output() addContactResponse: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('firstNameInput') firstNameInput: ElementRef;

  private contact: IUser;
  private dealerId: number;
  private formState: FormState;

  public contactForm: FormGroup;
  public isSubmitted: boolean;
  public roles = [] as IRole[];
  public title: string;
  public titles = [] as ITitle[];
  public faCheck = faCheck;
  public faTimes = faTimes;

  constructor(
    private dealersService: DealersService,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private toastrService: ToastrService
  ) {
    this.contactForm = this.buildForm();
  }

  //#region - Lifecycle
  ngOnInit(): void {
    this.retrieveRoles();
    this.retrieveTitles();

    if (this.formState === FormState.Modify) {
      this.contactForm.patchValue(this.contact);
    }
  }

  ngAfterViewInit(): void {
    // TODO: remove timeout 500ms hack to set focus after 'Submit' button receives focus
    setTimeout(() => this.firstNameInput.nativeElement.focus(), 500);
  }
  //#endregion

  //#region - Getters
  get f() { return this.contactForm.controls; }
  //#endregion

  //#region - Events
  onCancelClick(): void {
    this.addContactResponse.next(false);
  }

  onFormSubmit(): void {
    this.isSubmitted = true;

    if (this.contactForm.valid) {
      this.blockUI.start();

      switch (this.formState) {
        case FormState.Add:
          this.createContact().then(
            () => {
              this.addContactResponse.next(true);

              this.blockUI.stop();
            },

            ()=>{
              this.blockUI.stop();
            });

          break;
        case FormState.Modify:
          this.updateContact().then(
            () => {
              this.addContactResponse.next(true);

              this.blockUI.stop();
            }, 

            ()=>{
              this.blockUI.stop();
            });

          break;
      }
    }
  }
  //#endregion

  //#region - Private Methods
  private buildForm(): FormGroup {
    return this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      roleName: [null, Validators.required],
      title: [null, Validators.required]
    });
  }
  //#endregion

  //#region - API Methods
  private createContact(): Promise<{}> {
    const contact: IUser = this.contactForm.value;
    const title = 'Add Contact';

    contact.isEmployee = false;
    contact.permissions = JSON.stringify([]);
    //contact.roles = JSON.stringify([{ name: this.contactForm.get('roleName').value }]);
    contact.roles = this.contactForm.get('roleName').value !== 'N/A' ?
      JSON.stringify([{ name: this.contactForm.get('roleName').value }]) :
      JSON.stringify([]);
    contact.title = this.contactForm.get('title').value === 'N/A' ?
      null :
      this.contactForm.get('title').value;
    contact.vendorId = this.dealerId;

    const promise = new Promise((resolve, reject) => {
      this.identityService.create(contact).subscribe(
        () => {
          this.toastrService.success('Your contact has been successfully added.', title);

          resolve(true);
        },
        () => {
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private retrieveRoles(): void {
    const roleGroup = RoleGroup.EazeeApp;

    this.blockUI.start();

    this.dealersService.roleRetrieveByRoleGroup(roleGroup, true).subscribe(
      response => {
        const data: IRole[] = response;

        data.splice(0, 0, {
          id: null,
          name: 'N/A',
          label: 'Additional Contact',
          ordinal: null,
          roleGroupId: null
        });

        this.roles = data;

        this.blockUI.stop();
      },
    );
  }

  private retrieveTitles(): void {
    const titleGroup = TitleGroup.EazeeApp;

    this.blockUI.start();

    this.dealersService.titleRetrieve(titleGroup, true).subscribe(
      response => {
        const data: ITitle[] = response;

        data.splice(0, 0, {
          id: null,
          name: 'N/A',
          title: null,
          titleGroupId: null
        });

        this.titles = data;

        this.blockUI.stop();
      }
    );
  }

  private updateContact(): Promise<{}> {
    const contact: IUser = this.contactForm.value;
    const title = 'Edit Contact';

    contact.id = this.contact.id;
    contact.isEmployee = false;
    contact.permissions = JSON.stringify([]);
    contact.roles = this.contactForm.get('roleName').value !== 'N/A' ?
      JSON.stringify([{ name: this.contactForm.get('roleName').value }]) :
      JSON.stringify([]);
    contact.vendorId = this.dealerId;

    const promise = new Promise((resolve, reject) => {
      this.identityService.update(contact).subscribe(
        () => {
          this.toastrService.success('Your contact has been successfully added.', title);

          resolve(true);
        },
        () => {
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }
  //#endregion
}
