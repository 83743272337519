import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RepairCentreEditComponent } from '../../claims/components/repair-centre/repair-centre-edit/repair-centre-edit.component';
import { RepairCentre } from '../../claims/models/repair-centre.model';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss']
})
export class QuickAddComponent {
  @Output() modalResponse: EventEmitter<string> = new EventEmitter<string>();

  faPlus = faPlus

  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) { }

  public openRepairCenterDialog() {
    let repairCenterDialog = this.dialog.open(RepairCentreEditComponent, { width: '50vw', data: new RepairCentre() });
    repairCenterDialog.componentInstance.isEdit =false;
    this.modalResponse.emit();

    repairCenterDialog.afterClosed().subscribe(res => {
      if (res.data) {
        this.router.navigate(['repair-centre', res.data.id]);
      }
    })
  }

  public navigateToDealer() {
    this.modalResponse.emit();
    this.router.navigate(['dealers', 'dealer-info']);
  }
}
