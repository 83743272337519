import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { environment } from '../../../../../environments/environment';

/*-- Enums --*/
import { Sidebar } from '../../../../enums/sidebar.enum';

/*-- Interfaces --*/
import { IEazeeTrakReason, IEazeeTrakResult, IEazeeTrakFileNotification, IEazeeTrakFile } from '../../interfaces/eazeetrakfile.interface';
import { IUser } from '../../../dealers/interfaces/user.interface';

/*-- Services --*/
import { AccountService } from '../../../account/services/account.service';
import { DynamicComponentService } from '../../../../services/dynamic-component.service';
import { EazeeTrakService } from '../../services/eazeetrak.service';

/*-- Third Party --*/
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import {FilesSharedService} from "../../../dealers/components/notes-contacts/services/files-shared.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-file',
  templateUrl: './add-file.component.html',
  styleUrls: ['./add-file.component.scss']
})
export class AddFileComponent implements OnChanges, OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() contact: IUser;
  @Input() dealer: string;
  @Input() dealerId: number;

  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();

  private eazeeTrakReasons: IEazeeTrakReason[];
  private sidebar: Sidebar;

  public contacts: IUser[];
  public eazeeTrakForm: FormGroup;
  public isSubmitted: boolean;
  public notificationForm: FormGroup;
  public reasonGroups: any[];
  public reasons: any[];
  public eazeeTrakFileNotifications: IEazeeTrakFileNotification[];
  public eazeeTrakResults: IEazeeTrakResult[];
  public faCheck = faCheck;
  public faTimes = faTimes;
  public faPlus = faPlus;

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private dynamicComponentService: DynamicComponentService,
    private eazeeTrakService: EazeeTrakService,
    private toastrService: ToastrService,
    private filesSharedService: FilesSharedService
  ) { }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    const contact: SimpleChange = changes.contact;

    if (contact && contact.currentValue) {
      // add contact to contacts object
      this.contacts.push(contact.currentValue);

      // set contact value
      this.eazeeTrakForm.get('contact').setValue(contact.currentValue.id);
    }
  }
  ngOnInit(): void {
    this.retrieveContacts();
    this.retrieveEazeeTrakReasonGroups().then(
      () => {
        this.getReasonGroups();
      }
    );
    this.retrieveEazeeTrakResults();

    this.eazeeTrakForm = new FormGroup({
      comment: new FormControl(null, [Validators.required]),
      contact: new FormControl(null, [Validators.required]),
      dealer: new FormControl(this.dealer),
      pin: new FormControl(null),
      pinDays: new FormControl(30),
      reason: new FormControl(null, [Validators.required]),
      reasonGroup: new FormControl(null),
      result: new FormControl(null, [Validators.required]),
      sendCopyOfMail: new FormControl(null)
    });

    this.notificationForm = new FormGroup({});
  }
  //#endregion

  //#region - Events
  onAddContactClick(event): void {
    event.stopPropagation();
    event.preventDefault();

    this.selectedIndexChange.emit(1);
  }

  onEazeeTrakFileCancelClick(): void {
    this.buttonClick.emit(false);
  }

  onReasonGroupSelectionChange(): void {
    const eazeeTrakReasonGroupId: number = this.eazeeTrakForm.get('reasonGroup').value;

    this.reasons = this.eazeeTrakReasons.filter(item => item.eazeeTrakReasonGroupId === eazeeTrakReasonGroupId);
  }

  onResultSelectionChange(): void {
    this.retrieveEazeeTrakNotifications().then(
      () => {
        this.eazeeTrakFileNotifications.forEach(item => {
          this.notificationForm.addControl(item.userId.toString(), new FormControl(item.isPreSelected));
        });
      }
    );
  }

  onEazeeTrakFileSubmit(): void {
    this.isSubmitted = true;

    if (this.eazeeTrakForm.valid) {
      this.blockUI.start();

      const comment = this.eazeeTrakForm.get('comment').value;
      const contactId = this.eazeeTrakForm.get('contact').value;
      const contact: IUser = this.contacts.filter(item => item.id === contactId)[0];
      const contactName: string = contact.firstName + ' ' + contact.lastName;
      const eazeeTrakReasonId = this.eazeeTrakForm.get('reason').value;
      const eazeeTrakResultId = this.eazeeTrakForm.get('result').value;
      const pin: number = this.eazeeTrakForm.get('pin').value;
      const pinDays: number = pin ? this.eazeeTrakForm.get('pinDays').value : 0;
      const eazeeTrakComment: string = this.eazeeTrakForm.get('comment').value;
      const notificationJson: string = JSON.stringify(this.getSelectedNotifications());
      const sendCopyOfMail: boolean = this.eazeeTrakForm.get('sendCopyOfMail').value;
      const title: string = contact.title;

      const eazeeTrakFile: IEazeeTrakFile = {
        closeFile: null,
        contactId: contactId,
        contactName: title ? contactName + ' (' + title + ')' : contactName,
        dealerAccountManager: null,
        dealerAccountManagerId: null,
        dueDate: null,
        eazeeTrakAssignment: null,
        eazeeTrakComment: comment,
        eazeeTrakFileAction: null,
        eazeeTrakFileId: null,
        eazeeTrakFileNotification: null,
        eazeeTrakReason: null,
        eazeeTrakReasonId: eazeeTrakReasonId,
        eazeeTrakResult: null,
        eazeeTrakResultId: eazeeTrakResultId,
        isClosed: null,
        isCreatedByUser: null,
        notificationJson: notificationJson,
        pinDays: pin ? pinDays : null,
        vendor: null,
        vendorId: this.dealerId
      };

      this.createEazeeTrakFile(eazeeTrakFile, sendCopyOfMail).then(
        () => {
          this.filesSharedService.retrieveEazeeTrakFiles(this.dealerId, true);
          this.buttonClick.emit(true);
          this.blockUI.stop();
        }
      );
    }
  }
  //#endregion

  //#region - Getters
  get f() { return this.eazeeTrakForm.controls; }
  //#endregion

  //#region - Private Methods
  private getReasonGroups(): void {
    this.reasonGroups = _.sortBy(_.uniq(this.eazeeTrakReasons, function (item) { return item.eazeeTrakReasonGroupId; }), 'eazeeTrakReasonGroup');
  }

  private getSelectedNotifications(): IEazeeTrakFileNotification[] {
    let notifications: IEazeeTrakFileNotification[] = [];

    Object.keys(this.notificationForm.controls).forEach((key: string) => {
      const control = this.notificationForm.get(key);

      if (control.value) {
        notifications.push(this.eazeeTrakFileNotifications.filter(item => item.userId === key)[0]);
      }
    });

    return notifications;
  }
  //#endregion

  //#region - API Methods
  private createEazeeTrakFile(eazeeTrakFile: IEazeeTrakFile, sendCopyOfMail: boolean): Promise<{}> {
    const title = 'Create EazeeTrak File';

    const promise = new Promise((resolve, reject) => {
      this.eazeeTrakService.eazeeTrakFileCreate(eazeeTrakFile).subscribe(
        response => {
          const eazeeTrakFileId: number = parseInt(response.toString());

          // send notifications
          this.sendNotifications(eazeeTrakFileId, sendCopyOfMail);

          // show toast
          this.toastrService.success('Your EazeeTrak File has been successfully created.', title);

          resolve(true);
        },
        () => {
          // show error
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private retrieveContacts(): void {
    this.eazeeTrakService.userRetrieveByDealer(this.dealerId).subscribe(
      response => {
        const data: IUser[] = response;

        this.contacts = data;
      }
    );
  }

  private retrieveEazeeTrakNotifications(): Promise<{}> {
    const eazeeTrakResultId: number = this.eazeeTrakForm.get('result').value;
    const promise = new Promise((resolve) => {
      this.eazeeTrakService.eazeeTrakNotificationRetrieveByEazeeTrakResult(this.dealerId, eazeeTrakResultId).subscribe(
        response => {
          const data: IEazeeTrakFileNotification[] = response;

          this.eazeeTrakFileNotifications = data;

          resolve(true);
        }
      );
    });

    return promise;

  }

  private retrieveEazeeTrakReasonGroups(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.eazeeTrakService.eazeeTrakReasonLookup().subscribe(
        response => {
          const data: IEazeeTrakReason[] = response;

          this.eazeeTrakReasons = data;

          resolve(true);
        });
    });

    return promise;
  }

  private retrieveEazeeTrakResults(): void {
    this.eazeeTrakService.eazeeTrakResultRetrieve().subscribe(
      response => {
        const data: IEazeeTrakResult[] = response;

        this.eazeeTrakResults = data;
      }
    );
  }

  private sendNotifications(eazeeTrakFileId: number, sendCopyOfMail: boolean): void {
    const sender: string = this.accountService.getUserName();

    this.eazeeTrakService.eazeeTrakSendByResult(eazeeTrakFileId, sendCopyOfMail, sender).subscribe(
      () => { }
    );
  }
  //#endregion
}
