import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

/*-- Enums --*/
import { Country } from '../../../../../enums/country.enum';
import { FormState } from '../../../../../enums/form-state.enum';
import { Role } from '../../../../../enums/role.enum';

/*-- Interfaces --*/
import { IProvinceState } from '../../../interfaces/province-state.interface';

/*-- Services --*/
import { AccountService } from '../../../../account/services/account.service';
import { BreakpointService } from '../../../../../services/breakpoint.service';
import { DealersService } from '../../../services/dealers.service';
import { DealersSharedService } from '../../../services/dealers-shared.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnChanges, OnInit {
  @Input() contactForm: FormGroup;
  @Input() formState: FormState;
  @Input() submitted: boolean;

  @ViewChild('friendlyName') friendlyName: ElementRef;

  private bp: number;
  private isSubmitted: boolean;
  private provinceStates: IProvinceState[];

  public country: any = Country;
  public isStatic: boolean;

  constructor(
    private accountService: AccountService,
    private breakpointService: BreakpointService,
    private dealersService: DealersService,
    private dealersSharedService: DealersSharedService,
  ) {
    breakpointService.bp.subscribe(x => this.bp = x);
  }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges) {
    const formState: SimpleChange = changes.formState;
    const submitted: SimpleChange = changes.submitted;

    if (formState && formState.currentValue) {
      switch (formState.currentValue) {
        case FormState.Add:
          this.isStatic = false;

          Object.keys(this.contactForm.controls).forEach(key => {
            this.contactForm.get(key).markAsUntouched();
          });

          setTimeout(() => this.friendlyName.nativeElement.focus(), 0);

          break;
        case FormState.Modify:
          this.isStatic = this.accountService.isInRole(Role.DealerAccountManager) ||
            this.accountService.isInRole(Role.RegionalSalesManager);

          break;
        case FormState.Static:
          this.isStatic = true;

          break;
      }
    }

    if (submitted && submitted.currentValue) {
      this.isSubmitted = submitted.currentValue;

      Object.keys(this.contactForm.controls).forEach(key => {
        this.contactForm.get(key).markAsTouched();
      });
    }
  }

  ngOnInit() {
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }

    this.lookupProvinceStates();
  }
  //#endregion

  //#region - Getters
  get f() { return this.contactForm.controls; }

  get countryId() {
    return this.contactForm.get('countryId').value;
  }

  get postalZipCodeMask() {
    return (this.countryId === Country.Canada) ? 'S0S0S0' : '99999';
  }
  //#endregion

  //#region - Events
  onCustomerCountryChange() {
    this.contactForm.get('city').setValue(null);
    this.contactForm.get('postalZipCode').setValue(null);
    this.contactForm.get('provinceStateId').setValue(null);
  }
  //#endregion

  //#region - API Methods
  private lookupProvinceStates() {
    this.dealersService.provinceStateLookup().subscribe(
      response => {
        const data: IProvinceState[] = response;

        this.provinceStates = data;
      }
    );
  }
  //#endregion
}
