<div>
    <div class="sidebar-header">
        <h4 class="sidebar-title">Search</h4>
    </div>
    <div class="sidebar-body">
        <div class="search" id="search">
            <ul>
                <li>
                    <div class="searchbar">
                        <select name="searchFilter" id="searchFilter" [(ngModel)]="FilteredOption">
                            <option value="0">Any</option>
                            <option value="1">Repair Centre</option>
                            <option value="2">Customer</option>
                            <option value="3">Warranty</option>
                            <option value="4">Claim</option>
                            <option value="5">VIN</option>
                        </select>
                        <input type="text" name="searchTerm" placeholder=" Search" [(ngModel)]="searchTerm"
                            (keyup.enter)="search()" (input)="searchTermChange()" />
                        <button (click)="search()">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </button>
                    </div>
                </li>

                <h6>Search Results</h6>
                <li class="listBackground">
                    <div>
                        <div *ngIf="isLoading">
                            <div class="td-loading-spinner text-center" colspan="8">
                                <mat-spinner diameter="25"></mat-spinner>
                            </div>
                        </div>
                        <ul class="listContainer results">        
                            <li *ngFor="let search of searchResults" class="listItem" [ngClass]="{'no-results' : search.type === 0}" 
                            (click)="searchResultClick(search)">
                                {{search.description}}
                            </li>
                        </ul>
                    </div>
                </li>

                <h6>Recent Searches</h6>
                <li class="listBackground">
                    <ul class="listContainer recent">
                        <ng-container *ngFor="let recent of recentList; let i = index">
                            <li class="listItem" (click)="searchRecent(recent)" *ngIf="i < numRecentShown">
                                {{recent.typeName}} - {{recent.description}}
                                <button (click)="removeRecent(recent); $event.stopPropagation()">
                                    <fa-icon [icon]="faTimes" size="lg"></fa-icon>
                                </button>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>