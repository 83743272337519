<mat-dialog-content>
  <form [formGroup]="partForm">
    <div class="container-fluid dialogContent">
      <!-- Row 1 -->
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Repair Centre</mat-label>
            <input matInput type="text" formControlName="repairCentreName" [matAutocomplete]="partRepairCentreAutocomplete" (input)="filterRepairCentres($event.target.value)" [readonly]="isUpdate"/>
            <mat-autocomplete #partRepairCentreAutocomplete="matAutocomplete" panelWidth="auto" autoActiveFirstOption>
              <mat-option *ngFor="let rc of repairCentresFiltered" [value]="rc.name" (onSelectionChange)="selectRepairCentre(rc, $event)">{{rc.name}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="partForm.get('repairCentreName').errors?.required"> Repair Centre is required.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Row 1 -->
      <!-- Row 2 -->
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Part Block</mat-label>
            <input matInput type="text" formControlName="partGroupName" [matAutocomplete]="partGroupAutocomplete" (input)="filterPartGroups($event.target.value)" [readonly]="isUpdate"/>
            <mat-autocomplete #partGroupAutocomplete="matAutocomplete" panelWidth="auto" autoActiveFirstOption >
              <mat-option *ngFor="let pb of partGroupsFiltered" [value]="pb.label" (onSelectionChange)="selectPartsGroup(pb, $event)">{{pb.label}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="partForm.get('partGroupName').errors?.required">Part Block is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="w-100">
            <mat-label>Part Description</mat-label>
            <input matInput type="text" formControlName="description" [matAutocomplete]="partAutocomplete" (input)="filterParts($event.target.value)" [readonly]="isUpdate"/>
              <mat-autocomplete #partAutocomplete="matAutocomplete" panelWidth="auto" autoActiveFirstOption>
                <mat-option *ngFor="let p of partsFiltered" [value]="p.label" >{{p.label}}</mat-option>
              </mat-autocomplete>
            <mat-error *ngIf="partForm.get('description').errors?.required">Part Description is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-2 col-quantity">
          <mat-form-field class="w-100">
            <mat-label>Quantity</mat-label>
            <input matInput type="number" class="input-number" formControlName="quantity" />
            <mat-error *ngIf="partForm.get('quantity').errors?.required">Quantity is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field class="w-100">
            <mat-label>Goodwill</mat-label>
            <input matInput type="number" class="input-number" formControlName="goodwill" />
            <mat-error *ngIf="partForm.get('goodwill').errors?.required">Goodwill is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-1">
          <mat-checkbox class="label-top" formControlName="usedPart" color="primary">Used Part</mat-checkbox>
        </div>
      </div>
      <!-- Row 2 -->
      <!-- Row 3 -->
      <div class="row seven-row">
        <div class="seven-cols">
          <mat-form-field class="w-100">
            <mat-label>Our Cost</mat-label>
            <span matPrefix>$</span>
            <input matInput type="number" class="input-number" formControlName="ourPrice" />
            <mat-error *ngIf="partForm.get('ourPrice').errors?.required">Our Cost is required.</mat-error>
          </mat-form-field>
        </div>
        
        <div class="seven-cols">
          <mat-form-field class="w-100">
            <mat-label>Repair Centre Cost</mat-label>
            <span matPrefix>$</span>
            <input matInput type="number" class="input-number" formControlName="repairCentrePrice" />
            <mat-error *ngIf="partForm.get('repairCentrePrice').errors?.required">Repair Centre Cost is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="seven-cols">
          <mat-form-field class="w-100">
            <mat-label>Paid Price</mat-label>
            <span matPrefix>$</span>
            <input matInput type="number" class="input-number" formControlName="paidPrice" />
            <mat-error *ngIf="partForm.get('paidPrice').errors?.required">Amount Paid is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="seven-cols">
          <mat-form-field class="w-100">
            <mat-label>Shop Key Labour</mat-label>
            <span matPrefix>$</span>
            <input matInput type="number" class="input-number" formControlName="shopKeyLabour" />
            <mat-error *ngIf="partForm.get('shopKeyLabour').errors?.required">Shop Key Labour is required.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row seven-row">
        <div class="seven-cols">
          <mat-form-field class="w-100">
            <mat-label>Repair Centre Labour</mat-label>
            <span matPrefix>$</span>
            <input matInput type="number" class="input-number" formControlName="repairCentreLabour" />
            <mat-error *ngIf="partForm.get('repairCentreLabour').errors?.required">Repair Centre Labour is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="seven-cols">
          <mat-form-field class="w-100">
            <mat-label>Paid Labour</mat-label>
            <span matPrefix>$</span>
            <input matInput type="number" class="input-number" formControlName="paidLabour" />
            <mat-error *ngIf="partForm.get('paidLabour').errors?.required">Paid Labour is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="seven-cols">
          <mat-form-field class="w-100">
            <mat-label>Other</mat-label>
            <span matPrefix>$</span>
            <input matInput type="text" class="input-number" formControlName="formOther" />
            <mat-error *ngIf="partForm.get('formOther').errors?.required">Other is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="seven-cols">
          <mat-form-field class="w-100">
            <mat-label>Deductible</mat-label>
            <span matPrefix>$</span>
            <input matInput type="number" class="input-number" formControlName="deductible" />
          </mat-form-field>
        </div>
      </div>
      <!-- Row 3 -->
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-dialog-close class="btn custom-button-red" type="button">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon>Cancel
  </button>
  <button class="btn custom-button-green" type="button" (click)="checkData('save')">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon>Save
  </button>
</mat-dialog-actions>
