import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/*-- Components --*/
import { TableAdapterComponent } from '../shared/table-adapter/table-adapter.component';

/*-- Directives --*/
import { AvatarDirective } from '../directives/avatar.directive';

/*-- Modules --*/
import { MaterialModule } from './material.module';

/*-- Third Party --*/
import { ToastrModule } from 'ngx-toastr';
import { HttpPipe } from '../http.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomDatePipe } from '../pipes/custom-date.pipe';
import { FrameworkComponent } from '../shared/framework/framework.component';
import { FrameworkHiddenComponent } from '../shared/framework/framework-hidden/framework-hidden.component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    AvatarDirective,
    HttpPipe,
    TableAdapterComponent,
    CustomDatePipe,
    FrameworkComponent,
    FrameworkHiddenComponent,
    ConfirmDialogComponent
  ],
  exports: [
    AvatarDirective,
    HttpPipe,
    TableAdapterComponent,
    CustomDatePipe,
    FrameworkComponent,
    FrameworkHiddenComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ToastrModule.forRoot(),
    FontAwesomeModule,
  ],
  entryComponents: [
    ConfirmDialogComponent
  ]
})
export class SharedModule { }
