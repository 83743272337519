export enum CoverageType {
    Core = 1,
    Custom,
    DaaTireWheel,
    Packages,
    Core_New
}

export enum BonusBucksValueType {
    NoChange = -1,
    Custom = 0,
    CompanyBenchmark = -99,
}

export enum UserType {
    User = 1,
    Dealer,
    HoldingCompany,
    DealerGroup = 0
}

export enum RecipientType {
    HoldingCompany = 1,
    Dealer,
    DealerGroup,
    Individual
}

export enum PaymentMethod {
    Cheque,
    EFT
}

export enum DistributionType {
    Unselected = -1,
    Individual = 0,
    Percentage = 1
}

export enum Country {
    Canada = 1,
    UnitedStates
}

export enum ChangeType {
    Added = 0,
    Changed,
    Removed,
}

export enum BatchFileType {
    DealerVendor = 1,
    WarrantyNew = 2,
    WarrantyCancelled = 3,
    VendorCustomerDetails = 4,
    VendorInvoice = 5,
    VendorCancellation = 6,
    NewPayeeVendor = 7
}
