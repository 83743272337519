import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RepairCentreEditComponent } from './repair-centre-edit/repair-centre-edit.component';
import { RepairCentreService } from '../../services/repair-centre.service'
import { IRepairCentre } from '../../interfaces/repair-centre.interface';
import { ActivatedRoute } from '@angular/router';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';

@Component({
  selector: 'app-repair-centre',
  templateUrl: './repair-centre.component.html',
  styleUrls: ['./repair-centre.component.scss']
})
export class RepairCentreComponent implements OnInit {

  faEdit = faEdit

  public repairCentre: IRepairCentre
  public claimId: number

  postalCodeStateLabel: string
  postalCodeStateMask: string
  provinceStateLabel: string


  constructor(private formBuilder: FormBuilder, private repairCentreService: RepairCentreService, public dialog: MatDialog, private route: ActivatedRoute) { }

  ngOnInit() {
    this.claimId = parseInt(this.route.snapshot.paramMap.get("claimsid"));
    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.repairCentreService.retrieve(id).subscribe(res => {
        this.reload(res)
      })
    }
  }

  reload(data: IRepairCentre): void {
    this.repairCentre = data
    this.updatePostalCodeStateLabel()
    this.updateProvinceStateLabel()
  }

  edit() {
    let dialogRef = this.dialog.open(RepairCentreEditComponent, { width: '50vw', data: this.repairCentre })
    dialogRef.componentInstance.isEdit = true;
    dialogRef.afterClosed().subscribe(res => {
      if (res.data != undefined && res.data.version != undefined) {
        this.reload(res.data)
      }
    })
  }

  updatePostalCodeStateLabel(): void {
    this.postalCodeStateLabel = this.isCanada ? 'Postal Code' : 'Zip Code'
    this.postalCodeStateMask = this.isCanada ? 'S0S 0S0' : '00000'
  }

  updateProvinceStateLabel(): void {
    this.provinceStateLabel = this.isCanada ? 'Province' : 'State'
  }

  get isCanada(): boolean {
    return this.repairCentre.country === Country[Country.Canada]
  }
}
