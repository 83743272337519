import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { PartGroupModel } from '../models/part-group-model';
import { Part } from '../models/part-model';

@Injectable({
  providedIn: 'root'
})

export class PartService {
  constructor(private http: HttpClient) { }

  retrieveById(id: number): Observable<Part[]> {
    return this.http.get<Part[]>(environment.resourceUrl + `api/gwc/parts/${id}`); 
  }

  retrieveAllPartsFromGroup(partGroupId: number):Observable<Part[]>{
    return this.http.get<Part[]>(`${environment.resourceUrl}api/gwc/partgroups/get/${partGroupId}`)
  }

  retrieveAllPartGroups():Observable<PartGroupModel[]>{
    return this.http.get<PartGroupModel[]>(`${environment.resourceUrl}api/gwc/partgroups/get`)
  }
}
