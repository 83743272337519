<div *ngIf="!dealer" style='text-align:center;'>
  Please select a dealer.
</div>
<div *ngIf="loading" class="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!loading && !!dealer">
  <block-ui>
    <mat-horizontal-stepper #stepper linear>
      <ng-template matStepperIcon="edit">
        <mat-icon></mat-icon>
      </ng-template>
      <mat-step #value>
        <ng-template matStepLabel>Select Your Bonus Buck</ng-template>
        <app-front-page></app-front-page>
      </mat-step>

      <mat-step #coverage>
        <ng-template matStepLabel>Choose Your Coverages</ng-template>
        <div content>
          <app-coverages></app-coverages>
        </div>
      </mat-step>

      <mat-step #distribution>
        <ng-template matStepLabel>Distribution &amp; Authorization</ng-template>
        <div content>
          <app-distribution [distributionUsers]="distributionUsers" [dealer]="dealer">
          </app-distribution>
        </div>
      </mat-step>

      <mat-step #summary>
        <ng-template matStepLabel>Review</ng-template>
        <div content>
          <app-summary *ngIf="stepper.selected == summary" (submitClicked)="onSubmit($event)">
          </app-summary>
        </div>
        <div>
          <button mat-button matStepperPrevious>Back</button>
        </div>
      </mat-step>

    </mat-horizontal-stepper>
  </block-ui>
</div>