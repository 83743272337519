import { Component, OnInit, Inject, Output, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { PartService } from '../../services/part-service';
import { faSave, faWindowClose, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { catchError, filter, share } from 'rxjs/operators';
import { PartGroupModel } from '../../models/part-group-model';
import { RepairCentre } from '../../models/repair-centre.model';
import { RepairCentreService } from '../../services/repair-centre.service';
import { IRepairCentre } from '../../interfaces/repair-centre.interface';
import { ClaimTransaction } from '../../models/claim-transaction';
import { Part } from '../../models/part-model';
import { ClaimTransactionService } from '../../services/claim-transaction-service';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { DialogType } from 'src-private/app/enums/dialog-type';

@Component({
  selector: 'part-dialog',
  templateUrl: './part-dialog.component.html',
  styleUrls: ['./part-dialog.component.scss']
})

export class PartDialogComponent implements OnInit {

  // Font Awesome
  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public faTrashAlt = faTrashAlt;

  // Transaction
  public transaction: ClaimTransaction;
  public isUpdate: boolean = false;
  public updateStatus: string

  //  FormGroup
  public partForm: FormGroup;
  public repairCentreName: FormControl;
  public partGroupName: FormControl;
  public description: FormControl;
  public quantity: FormControl;
  public used: FormControl;
  public goodwill: FormControl;
  public ourPrice: FormControl;
  public repairCentrePrice: FormControl;
  public paidPrice: FormControl;
  public shopKeyLabour: FormControl;
  public repairCentreLabour: FormControl;
  public paidLabour: FormControl;
  public formOther: FormControl;

  // Repair Centre
  public repairCentres$: Observable<IRepairCentre[]>
  public repairCentres: RepairCentre[] = [];
  public repairCentresFiltered: RepairCentre[] = []

  // Part Block/Group
  public partGroups$: Observable<PartGroupModel[]>
  public partGroups: PartGroupModel[] = []
  public partGroupsFiltered: PartGroupModel[] = []
  public partGroupId: number
  public partGroupNameSelected: boolean

  // Part (Description)
  public parts$: Observable<Part[]>
  public parts: Part[] = []
  public partsFiltered: Part[] = []

  constructor(
    public partService: PartService,
    private claimTransactionService: ClaimTransactionService,
    public repairCentreService: RepairCentreService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PartDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {
    this.transaction = data.transaction
    if (this.transaction.partId != undefined)
      this.isUpdate = true
  }

  ngOnInit() {
    this.partForm = this.formBuilder.group({
      // Row One
      repairCentreName: ['', Validators.required],
      // Row Two
      partGroupName: ['', Validators.required],
      description: ['', Validators.required],
      quantity: ['', Validators.required],
      usedPart: [false],
      goodwill: ['', Validators.required],
      // Row Three
      ourPrice: ['', Validators.required],
      repairCentrePrice: ['', Validators.required],
      paidPrice: ['', Validators.required],
      shopKeyLabour: ['', Validators.required],
      repairCentreLabour: ['', Validators.required],
      paidLabour: ['', Validators.required],
      formOther: ['', Validators.required],
      deductible:['']
    }); // FormBuilder

    if (!this.isUpdate) {
      this.loadRepairCentres();
      this.loadPartGroups();
    }

    this.partForm.patchValue({
      repairCentreName: this.transaction["repairCentreName"],
      partGroupName: this.transaction["partGroupName"],
      description: this.transaction["partDescription"],
      quantity: this.transaction.quantity,
      usedPart: this.transaction.used,
      goodwill: this.transaction.goodwill,
      ourPrice: this.transaction.ourPrice,
      repairCentrePrice: this.transaction.repairCentrePrice,
      paidPrice: this.transaction.paidPrice,
      shopKeyLabour: this.transaction.shopKeyLabour,
      repairCentreLabour: this.transaction.repairCentreLabour,
      paidLabour: this.transaction.paidLabour,
      formOther: this.transaction.other,
      deductible:this.transaction.deductible
    });
  }

  checkData(status: string) {
    if (AppValidators.validateAll(this.partForm)) {
      this.transaction.partGroupId = this.isUpdate ? this.transaction.partGroupId : this.partGroupId;
      this.transaction.quantity = this.partForm.value.quantity;
      this.transaction.used = this.partForm.value.usedPart;
      this.transaction.goodwill = this.partForm.value.goodwill;
      this.transaction.ourPrice = this.partForm.value.ourPrice;
      this.transaction.repairCentrePrice = this.partForm.value.repairCentrePrice;
      this.transaction.paidPrice = this.partForm.value.paidPrice;
      this.transaction.shopKeyLabour = this.partForm.value.shopKeyLabour;
      this.transaction.repairCentreLabour = this.partForm.value.repairCentreLabour;
      this.transaction.other = this.partForm.value.formOther;
      this.transaction.paidLabour = this.partForm.value.paidLabour;
      this.transaction.deductible = this.partForm.value.deductible;
      if (this.transaction.partId == undefined) {
        this.transaction.repairCentreId = this.repairCentresFiltered.length > 0 ? this.repairCentresFiltered[0].id : 0;
        this.transaction.partId = this.partsFiltered.length > 0 ? this.partsFiltered[0].partId : 0;
		this.transaction.partName = this.partsFiltered.length > 0 ? this.partsFiltered[0].label : null;
      }
      if (this.isUpdate) {
        this.claimTransactionService.retrieveById(this.transaction.claimTransactionId).subscribe(
          data => {
            if (Object.entries(this.transaction).toString() === Object.entries(data[0]).toString()) {
              //If data has not been modified don't save
              this.dialogRef.close({ data: "Cancel" });
            }
            else if (data[0].version == this.transaction.version) {
              this.save(status);
            }
            else {
              let dialogRef = this.dialog.open(ConfirmDialogComponent, {
                panelClass: 'part-dialog',
                width: '400px',
                autoFocus: true,
                data: {
                  message: "Data you wish to save has been expired. Do you wish to reload?",
                  type: DialogType[0].toString()
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.transaction = data[0];
                  this.partForm.patchValue({
                    repairCentreName: this.transaction["repairCentreName"],
                    partGroupName: this.transaction["partGroupName"],
                    description: this.transaction["partDescription"],
                    quantity: this.transaction.quantity,
                    usedPart: this.transaction.used,
                    goodwill: this.transaction.goodwill,
                    ourPrice: this.transaction.ourPrice,
                    repairCentrePrice: this.transaction.repairCentrePrice,
                    paidPrice: this.transaction.paidPrice,
                    shopKeyLabour: this.transaction.shopKeyLabour,
                    repairCentreLabour: this.transaction.repairCentreLabour,
                    paidLabour: this.transaction.paidLabour,
                    formOther: this.transaction.other,
                    deductible: this.transaction.deductible
                  });
                }
                else {
                  this.dialogRef.close({ data: "refresh" });
                }
              });
            }
          })
        catchError((error) => {
          if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
          else console.log(`Error: ${error.message}`)
          return of([]);
        })
      }
      else {
        this.save(status);
      }
    }
  }

  save(status: string) {
    this.updateStatus = status;
    this.transaction.repairCentreName = this.partForm.value.repairCentreName;
    this.transaction.partGroupName = this.partForm.value.partGroupName;
    this.transaction.partDescription = this.partForm.value.description;

    this.dialogRef.close({ data: this.transaction })
  }

  private loadRepairCentres() {
    this.getLocation().then(res => {
      this.repairCentres$ = this.repairCentreService.retrieveRepairCentresInCity(res['lat'], res['lng'], 3).pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
          else console.log(`Error: ${error.message}`)
          return of([]);
        }))
      this.repairCentres$.subscribe(e => {
        e.forEach(rc => {
          this.repairCentres.push(new RepairCentre(rc))
        })
        Object.assign(this.repairCentres, e);
        this.repairCentres = this.repairCentres.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        this.repairCentresFiltered = this.repairCentres
      })
    })
  }

  private getLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
        });
      }
      else {
        const lat = 40.730610;
        const lng = -73.935242;
        resolve({ lat: lat, lng: lng });
      }
    });
  }

  public filterRepairCentres(rcName: string = null) {
    var filterVal = this.partForm.value.repairCentreName
    if (filterVal != undefined || rcName != null) {
      if (rcName != null) {
        filterVal = rcName.toLocaleLowerCase()
      }
      this.repairCentresFiltered = this.repairCentres.filter(e => e.name.toLocaleLowerCase().includes(filterVal))
    }
  }

  public selectRepairCentre(repairCentre: RepairCentre, event) {
    if (event.isUserInput) {
      this.repairCentresFiltered = [repairCentre];
    }
  }

  private loadPartGroups() {
    this.partGroups$ = this.partService.retrieveAllPartGroups().pipe(
      share(),
      catchError((error) => {
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      }))
    this.partGroups$.subscribe(e => {
      e.forEach(pb => {
        this.partGroups.push(pb)
      })
      this.partGroups = this.partGroups.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      })
    })
    this.partGroupsFiltered = this.partGroups
  }

  public filterPartGroups(pbName: string = null) {
    this.partsFiltered = []
    var filterVal = this.partForm.value.partGroupName

    if (filterVal != undefined || pbName != null) {
      if (pbName != null) {
        filterVal = pbName.toLocaleLowerCase()
      }
      this.partGroupsFiltered = this.partGroups.filter(e => e.label.toLocaleLowerCase().includes(filterVal))
    }
    else {
      this.partGroupNameSelected = false;
    }
  }

  public selectPartsGroup(partsGroup: PartGroupModel, event) {
    if (event.isUserInput) {
      this.partGroupNameSelected = false;
      this.partGroupId = partsGroup.partGroupId
      this.partGroupsFiltered = [partsGroup];
      this.partGroupNameSelected = true;
      this.loadParts()
    }
  }

  public loadParts() {
    this.parts = [];
    this.parts$ = this.partService.retrieveAllPartsFromGroup(this.partGroupId).pipe(
      share(),
      catchError((error) => {
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      }))
    this.parts$.subscribe(e => {
      e.forEach(p => {
        this.parts.push(p)
      })
    })
    this.partsFiltered = this.parts

  }

  public filterParts(partName: string = null) {
    var filterVal = this.partForm.value.description
    if (filterVal != undefined || partName != null) {
      if (partName != null) {
        filterVal = partName
      }
      filterVal = filterVal.toLocaleLowerCase()
      this.partsFiltered = this.parts.filter(e => e.label.toLocaleLowerCase().includes(filterVal))
    }
  }
}
