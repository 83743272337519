<div [ngClass]="{content : !claimsId, standalone : !claimsId}">
  <fieldset class="fieldset-form">
    <legend>
      Contact &nbsp;
      <fa-icon [icon]="faEdit" (click)="editCustomer()" role="button"></fa-icon>
    </legend>
    <div class="container-fluid">
      <div class="row row-cols-5">
        <div class="col">
          <div class="form-group-static">
            <label for="Cust_FirstName">First Name</label>
            <input id="Cust_FirstName" type="text" readonly class="w-100" [ngModel]="(customer$ | async)?.firstName"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col">
          <div class="form-group-static">
            <label for="Cust_LastName">Last Name</label>
            <input id="Cust_LastName" type="text" readonly class="w-100" [ngModel]="(customer$ | async)?.lastName"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col">
          <div class="form-group-static">
            <label for="Cust_Phone">Phone</label>
            <input id="Cust_Phone" type="text" readonly class="w-100" [ngModel]="(customer$ | async)?.phone"
              [ngModelOptions]="{standalone: true}" mask="(000) 000-0000">
          </div>
        </div>
        <div class="col">
          <div class="form-group-static">
            <label for="Cust_Email">Email</label>
            <input id="Cust_Email" type="text" readonly class="w-100" [ngModel]="(customer$ | async)?.email"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col">
          <div class="form-group-static">
          </div>
        </div>
      </div>

      <div class="row row-cols-5 ">
        <div class="col">
          <div class="form-group-static">
            <label for="Cust_Address">Address</label>
            <input id="Cust_Address" type="text" readonly class="w-100" [ngModel]="(customer$ | async)?.address"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col">
          <div class="form-group-static">
            <label for="Cust_City">City</label>
            <input id="Cust_City" type="text" readonly class="w-100" [ngModel]="(customer$ | async)?.city"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group-static">
                <label for="Cust_Province">{{provinceStateLabel}}</label>
                <input id="Cust_Province" type="text" readonly class="w-100"
                  [ngModel]="(customer$ | async)?.provinceName" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
        <div class="col">

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group-static">
                <label for="Cust_PostalCode">{{postalCodeStateLabel}}</label>
                <input id="Cust_PostalCode" type="text" readonly class="w-100"
                  [ngModel]="(customer$ | async)?.postalZipCode" [ngModelOptions]="{standalone: true}"
                  [mask]="postalCodeStateMask" oninput="this.value = this.value.toUpperCase()">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mat-form-field mat-form-field-static">
                <label class="mat-label">Country</label>
                <mat-radio-group [ngModel]="(customer$ | async)?.countryName" [ngModelOptions]="{standalone: true}"
                  [disabled]="true">
                  <mat-radio-button color="primary" value="Canada">CA</mat-radio-button>
                  <mat-radio-button color="primary" value="USA">US</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>

        </div>
        <div class="col">
          <div class="form-group-static"></div>
        </div>
      </div>

      <div class="row row-cols-5 ">
        <div class="col">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group-static">
                <label for="Cust_CustNum">Customer Number</label>
                <input id="Cust_CustNum" type="text" readonly class="w-100" [ngModel]="(customer$ | async)?.customerId"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
        <div class="col">
          <div class="form-group-static">
            <label for="Cust_DriversLicense">Drivers License</label>
            <input id="Cust_DriversLicense" type="text" readonly class="w-100"
              [ngModel]="(customer$ | async)?.driversLicense" [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col">
          <div class="form-group-static"></div>
        </div>
        <div class="col">
          <div class="form-group-static"></div>
        </div>
        <div class="col">
          <div class="form-group-static"></div>
        </div>
      </div>

    </div>
  </fieldset>

  <fieldset class="fieldset-form">
    <legend>Warranties</legend>
    <table-adapter #warrantiesTable [tableLabel]="'customer-warranties-table'" [columns]="warrantiesTableColumns">
    </table-adapter>
  </fieldset>

  <div class="row">
    <div class="col-md-7">
      <fieldset class="fieldset-form">
        <legend>
          Notes
          <div *ngIf="funcs['addNote'];else noAddNote">
            <button class="btn custom-button float-right" type="button" (click)="addNote()" [disabled]="!claimsId" [ngClass]="{'disableButton': !claimsId}">
              <fa-icon [icon]="faPlus"></fa-icon> Add Note
            </button>
          </div>
          <ng-template #noAddNote>
            <framework-hidden [display]="button"></framework-hidden>
          </ng-template>
        </legend>
        <table-adapter #notesTable [tableLabel]="'customer-notes-table'" [columns]="notesTableColumns"></table-adapter>
      </fieldset>
    </div>

    <div class="col-md-5">
      <fieldset class="fieldset-form">
        <legend>Claims</legend>
        <table-adapter #claimsTable [tableLabel]="'customer-claims-table'" [columns]="claimsTableColumns">
        </table-adapter>
      </fieldset>
    </div>
  </div>
</div>