import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { IWarranty, IWarrantyOption } from '../../interfaces/warranty.interface';
import { WarrantyService } from '../../services/warranty.service';
import { ClaimsService } from '../../services/claims.service';
import { ClaimsRetrieve } from '../../models/claims-retrieve.model';
import { IClaim } from '../../interfaces/claim';
import { MaintenanceAdd, MaintenanceAddInput } from '../maintenance/maintenance-add/maintenance-add.component';
import { DocumentAddDialogComponent } from '../document-add-dialog/document-add-dialog.component';
import { NoteAddDialogComponent } from '../note-add-dialog/note-add-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../interfaces/customer.interface';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { WarrantyDocumentService } from '../../services/warranty-document.service';
import { WarrantyNotesService } from '../../services/warranty-notes.service';
import { IWarrantyNote } from '../../interfaces/warranty-note.interface';
import { Observable, Subject } from 'rxjs';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { WarrantyStatus } from 'src-private/app/enums/warranty-status';
import { of } from 'rxjs/internal/observable/of';
import { HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { ClaimEditComponent } from '../claims-list/claim-edit/claim-edit.component';
import { takeUntil } from 'rxjs/operators';
import { Claim } from '../../models/claim-model';
import { ClaimStatus } from 'src-private/app/enums/claims.enums';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { IDocument } from '../../interfaces/document';
import { ClaimsLockService } from '../../services/claims-lock.service';
import { IClaimLock } from '../../interfaces/claim-lock.interface';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.scss']
})
export class WarrantyComponent extends FrameworkComponent implements OnInit {
  public faPlus = faPlus;

  public customer$: Observable<ICustomer>;
  public notes: IWarrantyNote[];
  public claims$: Observable<IClaim[]>;
  public warranty$: Observable<IWarranty>;
  public documents: IDocument[];
  public status: string;
  public claimId: number
  public warrantyId: number
  public applicationId: number;
  public vehicleId: number;
  private userFullName: string
  public isAduster: Boolean;

  private ngUnsubscribe: Subject<any> = new Subject();

  public options: Observable<IWarrantyOption[]>;

  @ViewChild("warrantyOptionsTable") warrantyOptionsTable: TableAdapterComponent<IWarrantyOption>
  public warrantyOptionsTableColumns: CellBuilder[] = [
    new CellBuilder("ID", "id", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Price", "amount", CellType.currency)
  ];

  @ViewChild("warrantyClaimsTable") warrantyClaimsTable: TableAdapterComponent<IClaim>
  public warrantyClaimsTableColumns: CellBuilder[] = [
    new CellBuilder("ID", "id", CellType.text),
    new CellBuilder("Created Date", "claimsDateEntered", CellType.date),
    new CellBuilder("Closed Date", "claimsDateClosed", CellType.date),
    new CellBuilder("Assigned Adjuster", "claimsOwner", CellType.text),
    new CellBuilder("Status", "claimsStatus", CellType.text),
  ];

  @ViewChild("warrantyDocumentsTable") warrantyDocumentsTable: TableAdapterComponent<IDocument>
  public warrantyDocumentsTableColumns: CellBuilder[] = [
    new CellBuilder("Filename", "fileName", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Created Date", "createdDate", CellType.date),
    new CellBuilder("Action", "contextmenu", CellType.contextmenu)
  ];

  @ViewChild("warrantyNotesTable") warrantyNotesTable: TableAdapterComponent<IWarrantyNote>
  public warrantyNotesTableColumns: CellBuilder[] = [
    new CellBuilder("Adjuster", "adjusterName", CellType.text),
    new CellBuilder("Note", "note", CellType.text),
    new CellBuilder("Created Date", "createdDate", CellType.text)
  ];

  constructor(
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private warrantyService: WarrantyService,
    private customerService: CustomerService,
    private claimsService: ClaimsService,
    private accountService: AccountService,
    private noteService: WarrantyNotesService,
    private toastr: ToastrService,
    private warrantyDocumentService: WarrantyDocumentService) {
    super()
  }

  ngOnInit() {
    this.claimId = parseInt(this.route.snapshot.paramMap.get("claimsid"));
    this.warrantyId = +this.route.snapshot.paramMap.get('id');
    if (this.warrantyId) {
      this.warranty$ = this.warrantyService.retrieve(this.warrantyId);
      this.warranty$.subscribe(warranty => {
        this.status = WarrantyStatus[warranty.status];
        this.applicationId = warranty.applicationId;
        this.vehicleId = warranty.vehicle.id;
        this.options = of(warranty.options ? warranty.options : []);
        this.refreshOptions();

        this.customer$ = this.customerService.retrieve(warranty.customerId);
      });
      this.userFullName = this.accountService.getUserName();
    }
    this.getClaimData();
  }

  ngAfterViewInit() {
    this.refreshDocuments();
    this.refreshNotes();
    this.refreshClaims();

    super.build(this, 'warranty-component')
  }

  getClaimData() {
    this.claimsService.retrieve(this.claimId).subscribe(
      data => {
        if (data.assignedAdjuster == this.accountService.getUserId()) {
          this.isAduster = true;
        }
        else {
          this.isAduster = false;
        }

      }
    )
  }

  //#region Claim Add
  //Claim Add Dialog
  addClaim() {
    let data = new Claim();
    this.warranty$.subscribe(res => {
      this.customer$.subscribe(customer => {
        data.warrantyId = res.id;
        data.assignedAdjuster = this.accountService.getUserId();
        data.claimsStatus = ClaimStatus[3].toString();
        data.customerId = customer.customerId;
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
          panelClass: 'part-dialog',
          width: '400px',
          autoFocus: true,
          data: {
            message: "You are about to add a new claim for this warranty. Do you want to continue?",
            type: DialogType[0].toString()
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.claimsService.claimsAdd(data)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((res) => {
                if (res) {
                  this.toastr.success("Claim successfully added. ");
                  this.router.navigateByUrl('/').then(()=>this.router.navigate(['claims/' + res.toString() + '/detail']));
                }
              });
          }
          else {
            dialogRef.close();
          }
        });
      });
    });
  }

  navigateClaim(event) {
    this.router.navigateByUrl('/').then(()=>this.router.navigate(['claims/' + event.id + '/detail']))
  }

  navigateCustomer(event){
    this.router.navigate(['claims/'+this.claimId+'/customer/'+event.srcElement.innerText])
  }

  navigateDealer() {
    this.warranty$.subscribe(data=>{
      this.router.navigate(['dealers', {'dealerId':data.dealerId}, 'dealer-info']);
    });

  }
  /**
   * Refresh Documents
   */
  refreshDocuments() {
    this.warrantyDocumentsTable.refresh(this.warrantyDocumentService.retrieveAll(this.warrantyId))
  }

  refreshNotes() {
    this.warrantyNotesTable.refresh(this.noteService.retrieveAll(this.warrantyId));
  }

  refreshClaims() {
    const req = new ClaimsRetrieve()
    req.warrantyId = this.warrantyId;
    this.warrantyClaimsTable.refresh(this.claimsService.retrieveAll(req));
  }

  refreshOptions() {
    this.warrantyOptionsTable.refresh(this.options);
  }

  addNote(): void {
    const addNoteDialogRef = this.dialog.open(NoteAddDialogComponent,
      {
        width: '600px'
      });

    addNoteDialogRef.afterClosed().subscribe(res => {
      if (res.data) {
        res.data.warrantyId = this.warrantyId;
        res.data.claimId = this.claimId;
        this.noteService.add(res.data).subscribe(() => {
          this.toastr.success("Note successfully added. ", 'Note Centre');
          this.refreshNotes();
        });
      }
    });
  }

  addDocument() {
    let dialogRef = this.dialog.open(DocumentAddDialogComponent,
      {
        panelClass: 'warranty-add-dialogs',
        width: '600px',
        data: {
          originId: this.warrantyId
        }
      });

    dialogRef.afterClosed().subscribe(res => {
      if (res.data) {
        res.data.originId = this.warrantyId;
        res.data.type = "Action";
        this.warrantyDocumentService.add(res.data).subscribe(() => {
          this.toastr.success("Document successfully added. ", 'Create Document');
          this.refreshDocuments();
        });
      }
    })
  }

  updateDocument(data) {
    if (data.action == "download") {
      this.downloadDocument(this.warrantyId, data.element.id)
    }
    else if (data.action == "remove") {
      this.removeDocument(data.element.id)
    }
  }

  downloadDocument(warrantyId: number, documentId: number) {
    this.warrantyDocumentService.download(warrantyId, documentId).subscribe((res: HttpResponse<Blob>) => {
      let contentDispositionHeader = res.headers.get('content-disposition');
      // the second part of content-disposition contains the file name
      let fileName = (contentDispositionHeader.split(';')[1].trim().split('=')[1]).replace(/"/g, '');
      FileSaver.saveAs(res.body, fileName);
    })
  }

  removeDocument(documentId: number) {
    //TODO: add alert for delete confirmation
    this.warrantyDocumentService.remove(documentId).subscribe(() => {
      this.toastr.success("Document successfully removed. ", 'Remove Document');
      this.refreshDocuments();
    })
  }

  private downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      this.toastr.error("Please disable your Pop-up blocker and try again.", 'Download Document');
    }
  }

  addMaintenance() {
    if (this.isAduster) {
      var maint = new MaintenanceAddInput();
      maint.warrantyId = this.warrantyId;
      maint.claimId = this.claimId;
      maint.applicationId = this.applicationId;
      maint.vehicleId = this.vehicleId;

      this.dialog.open(MaintenanceAdd, {
        width: '400px',
        data: maint
      });
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  thousandsSeparator(number) {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };
}
