<div>
  <fieldset class="fieldset-form">
    <legend>Maintenance Record</legend>
    <div class="container-fluid">
      <div class="row">

        <div class="col-md-1">
          <div class="form-group-static">
            <label for="Maint_Warr_ID">Warranty ID</label>
            <input id="Maint_Warr_ID" type="text" readonly class="w-100" [ngModel]="(warranty$ | async)?.id"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group-static">
            <label for="Maint_Clm_ID">Claim ID</label>
            <input id="Maint_Clm_ID" type="text" readonly class="w-100" [ngModel]="(claim$ | async)?.id"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group-static">
            <label for="Maint_OG_KMS">Original KMs</label>
            <input id="Maint_OG_KMS" type="text" readonly class="w-100"
              [ngModel]="(warranty$ | async)?.vehicle?.originalKms" [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group-static">
            <label for="Maint_Sold_Date">Sold Date</label>
            <input id="Maint_Sold_Date" type="text" readonly class="w-100"
              [ngModel]="(warranty$ | async)?.effectiveDate  | GWDateFormat" [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group-static">
            <label for="Maint_Curent_KMS">Current KMs</label>
            <input id="Maint_Curent_KMS" type="text" readonly class="w-100"
              [ngModel]="(warranty$ | async)?.vehicle?.currentKms" [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group-static">
            <label for="Maint_Current_Date">Current Date</label>
            <input id="Maint_Current_Date" type="text" readonly class="w-100"
              [ngModel]="(warranty$ | async)?.vehicle?.currentDate | GWDateFormat"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>

      </div>
    </div>
  </fieldset>

  <fieldset class="fieldset-form">
    <legend>Complete Maintenance Schedule</legend>
    <table-adapter #maintScheduleTable [tableLabel]="'maintenance-schedule-table'"
      [columns]="maintScheduleTableColumns"> </table-adapter>
  </fieldset>
</div>