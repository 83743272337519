export class ClaimTransaction {
    claimTransactionId: number;
    claimId: number;
    partId: number;
    partName: string;
    warrantyId: number;
    repairCentreId: number;
    indicator: string;
    quantity: number;
    ourPrice: number;
    repairCentrePrice: number;
    paidPrice: number;
    shopKeyLabour: number;
    repairCentreLabour: number;
    paidLabour: number;
    other: number;
    process: boolean;
    used: boolean;
    goodwill: number;
    partGroupId: number;
    shipping: number;
    towing: number;
    carRental: number;
    roadAssistance: number;
    accomodation: number;
    machineShop: number;
    inspection: number;
    invoiceId: string;
    invoiceApproved: boolean;
    partDescription: string;
    partGroupName: string;
    repairCentreName: string;
    deductible: number;
    description: string;
    transactionKey: number;
    version: number;

}
