<mat-dialog-content (scroll)="closeAutoComplete()">
  <form [formGroup]="repairCenterForm">
    <fieldset class="fieldset-form">
      <legend>{{headerText}}</legend>
      <div class="container-fluid dialogContent">
        <div class="row">
          <div class="col-12">
            <mat-slide-toggle formControlName="preferred" class="float-right">Preferred</mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Repair Centre Name</mat-label>
              <input formControlName="name" required matInput >
              <mat-error *ngIf="repairCenterForm.get('name').errors?.required">
                Repair Centre Name is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Contact</mat-label>
              <input formControlName="contact" matInput>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field class="w-100">
              <mat-label>Contact Ext</mat-label>
              <input formControlName="contactExt" matInput mask="000">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <mat-form-field class="w-100">
              <mat-label>Address</mat-label>
              <input formControlName="address" matInput>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>City</mat-label>
              <input formControlName="city" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>{{ provinceStateLabel }}</mat-label>
              <input formControlName="province" matInput>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>{{ postalCodeStateLabel }}</mat-label>
              <input formControlName="postalCode" matInput
                     [mask]="postalCodeStateMask" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="col-4">
            <div class="mat-form-field mat-form-field-static">
              <mat-label>Country</mat-label>
              <mat-radio-group formControlName="country" (change)="onCountryChange()">
                <mat-radio-button color="primary" value="Canada">CA</mat-radio-button>
                <mat-radio-button color="primary" value="USA">US</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Phone</mat-label>
              <input formControlName="phone" trim matInput mask="(000) 000-0000"
                     autocomplete="off">
              <mat-error *ngIf="repairCenterForm.get('phone').hasError('minlength')">
                Please input a valid phone number
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Fax</mat-label>
              <input formControlName="fax" matInput trim mask="(000) 000-0000"
                     autocomplete="off">
              <mat-error *ngIf="repairCenterForm.get('fax').hasError('minlength')">
                Please input a valid phone number
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Website</mat-label>
              <input formControlName="website" trim class="link" matInput
                     autocomplete="off">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Email</mat-label>
              <input formControlName="email" class="link" trim matInput
                     autocomplete="off">
              <mat-error *ngIf="repairCenterForm.get('email').hasError('email')">
                Please enter a valid email
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="fieldset-form">
      <legend>Service Information</legend>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Status</mat-label>
              <mat-select #select formControlName="status">
                <mat-option *ngFor="let status of repairCentreStatuses" [value]="status.description">
                  {{status.description}} 
              </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <input type="text" matInput formControlName="dealerName" placeholder="Affiliated Dealer" [matAutocomplete]="dealer"
                (input)="filterDealers($event.target.value)" #repairCentreAutoCompleteRef>
              <mat-autocomplete  autoActiveFirstOption #dealer="matAutocomplete">
                <mat-option *ngIf="isLoading" class="spinner">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let dealer of filteredDealers" [value]="dealer.alias"
                    (onSelectionChange)="onDealerSelected(dealer, $event)">
                    {{dealer.alias}}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
              <mat-error *ngIf="isSubmitted && repairCenterForm.get('dealerName').errors?.required">
                Affiliated Dealer is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Type</mat-label>
              <mat-select #select formControlName="type">
                <mat-option *ngFor="let type of repairCentreTypes" [value]="type.description">
                  {{type.description}} 
              </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Grade</mat-label>
              <input formControlName="grade" matInput>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Hourly Rate</mat-label>
              <input id="hourlyRate" formControlName="hourlyRate" type="text" matInput required
                     prefix="$" mask="comma_separator">
              <mat-error *ngIf="repairCenterForm.get('hourlyRate').hasError('minlength')">
                Please enter a valid hourly rate
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" type="button" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" type="button" (click)="checkData()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
  
</mat-dialog-actions>
