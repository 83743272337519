import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { IMaintenance } from '../../../interfaces/maintenance.interface';
import { MaintenanceService } from '../../../services/maintenance.service';
import { IOdometer } from '../../../interfaces/odometer.interface';
import { OdometerService } from '../../../services/odometer.service';
import { IMaintenanceTerm } from '../../../interfaces/maintenance-term.interface';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { DialogType } from "../../../../../enums/dialog-type";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src-private/environments/environment';

export class MaintenanceAddInput {
  applicationId: number;
  vehicleId: number;
  claimId: number;
  warrantyId: number;
}

@Component({
  selector: 'app-maintenance-add',
  templateUrl: './maintenance-add.component.html',
  styleUrls: ['./maintenance-add.component.scss']
})
export class MaintenanceAdd implements OnInit {

  public odometerReadingForm: FormGroup;
  public maintenanceRecordForm: FormGroup;
  public maintenanceRecord: IMaintenance;
  public maintenanceTerms: IMaintenanceTerm[];
  public odometerRecord: IOdometer;

  public faSave = faSave;
  public faWindowClose = faWindowClose;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) private input: MaintenanceAddInput,
    private dialogRef: MatDialogRef<MaintenanceAdd>,
    private odometerService: OdometerService,
    private dialog: MatDialog,
    private maintenanceService: MaintenanceService,
    private toastr: ToastrService) {

    console.log(input);

    this.maintenanceRecord = {
      warrantyId: input.warrantyId,
      claimId: input.claimId,
      date: null,
      kms: null,
      term: null,
      termId: null,
      maxKms: null,
      userId: null
    }

    this.odometerRecord = {
      id: null,
      applicationId: this.input.applicationId,
      vehicleId: this.input.vehicleId,
      unitType: null,
      date: null,
      reading: null,
    };
  }

  ngOnInit() {
    this.odometerReadingForm = this.formBuilder.group({
      odometerKms: [null, Validators.required],
      odometerDate: [null, Validators.required]
    })

    this.maintenanceRecordForm = this.formBuilder.group({
      scheduledKms: [null, Validators.required],
      scheduledPeriod: [null, Validators.required],
      performedKms: [null, Validators.required],
      performedDate: [null, Validators.required]
    });

    this.populateTerms();
  }

  save() {
    let odometerPromise: Promise<any> = null;
    odometerPromise = this.odometerService.add(this.odometerRecord).toPromise();

    let maintenancePromise: Promise<any> = null;
    maintenancePromise = this.maintenanceService.add(this.maintenanceRecord).toPromise();

    Promise.all([odometerPromise, maintenancePromise])
    .then((results: any[]) => {
      if(results[0] != null && results[1] != null) {
        this.dialogRef.close()
        this.toastr.success("Maintenance successfully added.", 'Create Maintenance')
      }
    })
    .catch( (err) =>{
      this.toastr.error(environment.messages.apiError, 'Create Maintenance')

    });
  }

  confirm() {
    this.odometerReadingForm.markAllAsTouched();
    this.maintenanceRecordForm.markAllAsTouched();
    let now = new Date();

    if (this.odometerReadingForm.dirty) {
      if (AppValidators.validateAll(this.odometerReadingForm)) {

        this.odometerRecord.reading = this.odometerReadingForm.controls["odometerKms"].value;
        this.odometerRecord.date = this.odometerReadingForm.controls["odometerDate"].value;
        this.odometerRecord.unitType = 1; // 1 = KMS 2 = Miles. Since we have no way of switching between miles and KMs, its defaulted to KMs.
      }
    }

    if (this.maintenanceRecordForm.dirty) {
      if (AppValidators.validateAll(this.maintenanceRecordForm)) {
        let date = new Date(this.maintenanceRecordForm.controls["performedDate"].value);
        date.setHours(now.getHours());
        date.setMinutes(now.getMinutes());
        this.maintenanceRecord.maxKms = this.maintenanceRecordForm.controls["scheduledKms"].value;
        this.maintenanceRecord.termId = this.maintenanceRecordForm.controls["scheduledPeriod"].value;
        this.maintenanceRecord.date = date;
        this.maintenanceRecord.kms = this.maintenanceRecordForm.controls["performedKms"].value;

        this.maintenanceService.retrieveByDate(this.maintenanceRecord.claimId, this.maintenanceRecord.warrantyId, this.datePipe.transform(this.maintenanceRecord.date, 'MM-dd-yyyy')).subscribe(
          data => {
            if(data.length > 0){
              this.dialog.open(ConfirmDialogComponent, {
                width: '300px',
                autoFocus: true,
                data: {
                  message: "Maintenance record has already been added for this date.",
                  type: DialogType[1].toString()
                }
              });
            }
            else {
              this.save();
            }
        });
      }
    }
  }

  cancel() {
    this.dialogRef.close()
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  populateTerms() {
    this.maintenanceService.retrieveTerms().subscribe(terms => {
      this.maintenanceTerms = terms;
      this.maintenanceTerms = this.maintenanceTerms.filter(x=>x.notInUse == false);
    })
  }
}
