import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { IDocument } from '../../interfaces/document';

@Component({
  selector: 'app-document-add-dialog',
  templateUrl: './document-add-dialog.component.html',
  styleUrls: ['./document-add-dialog.component.scss']
})
export class DocumentAddDialogComponent implements OnInit {

  public documentAddForm: FormGroup;
  public selectedFile: File;
  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public document: IDocument;

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public originId: number,
    private dialogRef: MatDialogRef<DocumentAddDialogComponent>) { }

  ngOnInit() {
    this.documentAddForm = this.formBuilder.group({
      originId: [0],
      fileName: [''],
      fileType: [''],
      description: ['', Validators.required],
      createdDate: [''],
      fileData: [Validators.required],
    });

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  addDocument() {
    this.document = {
      originId: this.originId,
      fileName: this.selectedFile.name.split('.')[0],
      fileType: this.selectedFile.name.split('.')[1],
      description: this.documentAddForm.value.description,
      createdDate: (new Date()).toISOString(),
      fileData: this.selectedFile
    };

    if (AppValidators.validateAll(this.documentAddForm)) {
      this.documentAddForm.patchValue({ originId: this.document.originId });
      this.documentAddForm.patchValue({ fileName: this.document.fileName });
      this.documentAddForm.patchValue({ fileType: this.document.fileType });
      this.documentAddForm.patchValue({ description: this.document.description });
      this.documentAddForm.patchValue({ createdDate: this.document.createdDate });
      this.documentAddForm.patchValue({ fileData: this.document.fileData });

      this.dialogRef.close({ data: this.documentAddForm.value });
    }
  }

  selectFile(event) {
    this.selectedFile = event.target.files[0];
  }
}
