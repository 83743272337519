import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[avatar]'
})
export class AvatarDirective {
  constructor(private el: ElementRef) { }

  @HostListener('error') onError() {
    this.setGenericAvatarImg();
  }

  private setGenericAvatarImg() {
    this.el.nativeElement.src = '/assets/images/avatars/generic-avatar.jpg';
  }
}
