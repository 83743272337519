<mat-dialog-content>
    <form [formGroup]="claimEditForm">
        <div class="container-fluid dialogContent" *ngIf="isEdit">
            <div class="row" *ngIf="isManager">
                <div class="col-md-12">
                  <mat-form-field class="w-100">
                    <input type="text" matInput formControlName="adjuster" placeholder="Adjuster" [matAutocomplete]="adjuster"
                      (input)="filterAdjusters($event.target.value)">
                    <mat-autocomplete autoActiveFirstOption #adjuster="matAutocomplete">
                      <mat-option *ngIf="isLoading" class="spinner">
                        <mat-spinner diameter="25"></mat-spinner>
                      </mat-option>
                      <ng-container *ngIf="!isLoading">
                        <mat-option *ngFor="let adjuster of filteredAdjusters" [value]="adjuster.loginUser"
                          (onSelectionChange)="onAdjusterSelected(adjuster, $event)">
                          {{adjuster.loginUser}}
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                      <mat-error *ngIf="claimEditForm.get('adjuster').errors?.required">
                        Adjuster is required
                    </mat-error>
                  </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="w-100">
                  <mat-label>Claim Status</mat-label>
                  <mat-select formControlName="claimStatus">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let claimStatus of claimStatuses" [value]="claimStatus">
                      {{claimStatus}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="claimEditForm.get('claimStatus').errors?.required">
                    Claim Status is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
        </div>
        <div class="container-fluid dialogContent" *ngIf="!isEdit">
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Warranty Id</mat-label>
                <input formControlName="warrantyId" readonly class="w-100" required matInput>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Warranty VIN</mat-label>
                <input formControlName="warrantyVIN" placeholder="Warranty VIN" required matInput readonly>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Adjuster</mat-label>
                <mat-select formControlName="adjuster" disabled>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let adjuster of adjusters" [value]="adjuster.loginUser">
                      {{adjuster.firstname}} {{adjuster.lastname}}
                  </mat-option>
              </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Claim Status</mat-label>
                <mat-select formControlName="claimStatus" disabled>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let claimStatus of claimStatuses" [value]="claimStatus">
                    {{claimStatus}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Claim Date</mat-label>
                <input formControlName="claimsDateEntered" required matInput readonly>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Dealer Name</mat-label>
                <input formControlName="vendorName" required matInput readonly>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>First Name</mat-label>
                <input formControlName="firstName" required matInput readonly>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Last Name</mat-label>
                <input formControlName="lastName" required matInput readonly>
              </mat-form-field>
            </div>
          </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close class="btn" type="button">
      <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
    </button>
    <button mat-button class="btn" type="button" (click)="checkData()">
      <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
    </button>
</mat-dialog-actions>
