import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterOutlet } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { slideInAnimation } from '../animations/router-slide-in';

/*-- Components - Static --*/
import { AppComponent } from '../../../app.component';

/*-- Components - Dynamic --*/
import { FileFormComponent } from '../../eazeetrak/components/file-form/file-form.component';

/*-- Enums --*/
import { FormState } from '../../../enums/form-state.enum';
import { Sidebar } from '../../../enums/sidebar.enum';

/*-- Interfaces --*/
import { IAutoCompleteItem } from '../../../interfaces/autocomplete-item.interface';
import { IDealer } from '../interfaces/dealer.interface';

/*-- Services --*/
import { BreakpointService } from '../../../services/breakpoint.service';
import { ComParentChildService } from '../services/com-parent-child.service';
import { DealersService } from '../services/dealers.service';
import { DealersSharedService } from '../services/dealers-shared.service';
import { DynamicComponentService } from '../../../services/dynamic-component.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { faPlus, faSearch, faComment } from '@fortawesome/free-solid-svg-icons';
import { debounceTime } from 'rxjs/operators';
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class DealersComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;

  private comParentChildSubscription: Subscription;
  private dealer: IDealer;
  private dealerForm: FormGroup;
  private dealers: IDealer[];
  private keyword = 'text';
  private placeholder = 'Search';

  public autoCompleteItems: IAutoCompleteItem[] = [];
  public bp: number;
  public dealerId: number;
  public headerForm: FormGroup;
  public isAddingPotentialDealer: boolean;
  public faPlus = faPlus;
  public faComment = faComment;
  public faSearch = faSearch;

  public filteredDealers: IDealer[];
  public dealerAutoCompleteText: string;
  public isLoading: boolean = false;

  @ViewChildren(MatAutocompleteTrigger) autoCompletes: QueryList<MatAutocompleteTrigger>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appComponent: AppComponent,
    private breakpointService: BreakpointService,
    private comParentChildService: ComParentChildService,
    private dealersService: DealersService,
    private dealersSharedService: DealersSharedService,
    private dynamicComponentService: DynamicComponentService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    breakpointService.bp.subscribe(x => this.bp = x);

    activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams['f']) {
        const eazeeTrakFileId = queryParams['f'];

        this.openEazeeTrakFile(eazeeTrakFileId);
      }
    });

    this.dealerForm = this.formBuilder.group({});

    // subscribe to change on the dealer
    this.dealersSharedService.dealer.subscribe(
      dealer => {
        this.dealer = dealer;

        if (!dealer || !dealer.vendorId) {
          this.dealerId = null;
        }

        this.isAddingPotentialDealer = dealer && !dealer.vendorId;
      }
    );
  }

  //#region - Lifecycle
  ngOnDestroy() {
    this.dealersSharedService.clearDealer();

    this.comParentChildSubscription.unsubscribe();
  }

  ngOnInit() {

    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }

    this.headerForm = this.formBuilder.group({
      search: ['']
    });

    this.loadDealerList();

    this.comParentChildSubscription = this.comParentChildService.on('dealer-added').subscribe(() => {
      this.loadDealerList();
    });

    var self = this

    window.addEventListener('scroll', () => {
      this.closeAutoComplete(self);
    }, true);

    const dealerId = +this.activatedRoute.snapshot.paramMap.get('dealerId');

    if (dealerId) {
      this.dealersSharedService.setDealer(dealerId);
      this.dealerId = dealerId;

      if (this.dealerId) {
        this.dealersService.dealerRetrieve(this.dealerId).subscribe(dealer => {
          (<HTMLInputElement>document.getElementById("dealerAutoCompleteInput")).value = `[${dealer.vendorId}] ${dealer.alias}`
        })
      }
    }
  }

  //#endregion

  //#region - Events
  onAddPotentialDealerClick() {
    // clear autocomplete value
    this.dealerId = null;

    // set dealer
    this.dealersSharedService.setDealer(null);
  }

  onOpenEazeeTrakFileClick() {
    const sidebar = Sidebar.Right;

    let instance: any;

    this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

    instance = this.dynamicComponentService.addDynamicComponent(FileFormComponent);

    instance.dealer = this.dealer.alias;
    instance.dealerId = this.dealer.vendorId;
    instance.formState = FormState.Add;
    instance.sidebar = sidebar;

    instance.modalResponse.subscribe(
      response => {
        if (response) {

          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);

        } else {
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        }
      });

    this.appComponent.sidebarRightOpened = true;
  }

  // onDealerSelected(item) {
  //   if (!item || item.id === this.dealerId) { return; }

  //   // track the currently selected 'dealerId'
  //   this.dealerId = item.id;
  //   this.dealersSharedService.setDealer(item.id);
  // }

  onSelectedTabChange(selectedTab) {
    switch (selectedTab.index) {
      case 0:
        this.nav('dealer-info', 'contact');

        break;
      case 1:
        this.nav('dealer-info', 'settings');

        break;
      case 2:
        this.nav('dealer-info', 'competitors');

        break;
    }
  }
  //#endregion

  //#region - Private Methods
  private loadDealerList(): void {
    this.blockUI.start();

    this.lookupDealers().then(
      () => {
        for (let dealer of this.dealers) {
          this.autoCompleteItems.push({
            id: dealer.vendorId,
            text: '[' + dealer.vendorId + '] ' + dealer.alias
          });
        }

        if (this.dealer) {
          this.dealerId = this.dealer.vendorId;
        }

        this.blockUI.stop();
      }
    );
  }

  private openEazeeTrakFile(eazeeTrakFileId: number) {
    const sidebar = Sidebar.Right;

    let instance: any;

    this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

    instance = this.dynamicComponentService.addDynamicComponent(FileFormComponent);

    instance.eazeeTrakFileId = eazeeTrakFileId;
    instance.formState = FormState.Modify;
    instance.sidebar = sidebar;

    instance.modalResponse.subscribe(
      response => {
        if (response) {

          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);

        } else {
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        }
      });

    this.appComponent.sidebarRightOpened = true;
  }
  //#endregion

  //#region - API Methods
  private lookupDealers() {
    const promise = new Promise((resolve) => {
      this.dealersService.dealerLookup(true).subscribe(
        response => {
          const data: IDealer[] = response;

          this.dealers = data;

          resolve(true);
        });
    });

    return promise;
  }
  //#endregion

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  nav(route: string, component: string) {
    const param = {};
    const queryParams: Params = { c: component };

    if (this.dealerId) {
      param['dealerId'] = this.dealerId;
    }

    this.router.navigate(['/dealers', param, route], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  isShow() {
    document.getElementById("mobileMenu").classList.toggle("show");
  }

  filterDealers(repaireCentreText: string) {

    debounceTime(750);

    var loadingThis = this;
    let loadingTimer = setTimeout(() => {
      loadingThis.isLoading = true;
    }, 250);

    this.dealerAutoCompleteText = repaireCentreText;

    if (repaireCentreText.length > 0) {
      this.dealersService.dealerLookupByAlias(repaireCentreText)
        .subscribe(dealers => {
          if (dealers) {
            this.loadDealers(loadingTimer, dealers);
          }
        });
    }
    else {
      this.loadDealers(loadingTimer);
    }
  }

  loadDealers(loadingTimer: any, dealers = []) {
    //if you filter when more than 0 char than a visual bug can occur if the user deletes all char.
    //The lookup will send request on the last char, then on 0 char the list will clear, then the service
    //responds and populates the list even when the input is empty
    //therfore only populate list if adjuster text is still grater than 0
    if (this.dealerAutoCompleteText.length > 0) {
      this.filteredDealers = dealers;
    }
    else {
      this.filteredDealers = [];
    }

    this.isLoading = false;
    clearTimeout(loadingTimer)
  }

  onDealerSelected(selected, event) {
    //ignores deselect trigger of previous selected
    if (event.isUserInput) {
      if (!selected || selected.vendorId === this.dealerId) { return; }

      // track the currently selected 'dealerId'
      this.dealerId = selected.vendorId;

      this.dealersSharedService.setDealer(selected.vendorId);
    }
  }

  dealerAutoCompleteDisplay(value: IDealer) {
    if (value && value.vendorId) {
      return `[${value.vendorId}] ${value.alias}`
    }
  }

  closeAutoComplete(self) {
    self.autoCompletes.forEach((autoComplete) => {
      autoComplete.closePanel();
    })
  }
}
