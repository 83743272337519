<form [formGroup]="form">
  <div class="form-row">
    <div class="col-sm-4 col-md-4 col-lg-1">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0" id="claimid-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="claimid-addon"
                   aria-label="ClaimId"
                   class="form-control disabled-transparent-background"
                   placeholder="Claim ID"
                   type="number"
                   formControlName="claimId"
                   (keydown.esc)="onClaimIdTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-1">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0" id="warrantyid-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="warrantyid-addon"
                   aria-label="WarrantyId"
                   class="form-control disabled-transparent-background"
                   placeholder="Warranty ID"
                   type="number"
                   formControlName="warrantyId"
                   (keydown.esc)="onWarrantyIdTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0" id="firstname-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="firstname-addon"
                   aria-label="FirstName"
                   class="form-control disabled-transparent-background"
                   placeholder="First Name"
                   type="text"
                   formControlName="customerFirstname"
                   (keydown.esc)="onFirstNameTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0" id="lastname-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="lastname-addon"
                   aria-label="LastName"
                   class="form-control disabled-transparent-background"
                   placeholder="Last Name"
                   type="text"
                   formControlName="customerLastname"
                   (keydown.esc)="onLastNameTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0" id="vin-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="vin-addon"
                   aria-label="VIN"
                   class="form-control disabled-transparent-background"
                   placeholder="VIN"
                   type="text"
                   formControlName="vin"
                   (keydown.esc)="onVinTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0" id="dealer-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="dealer-addon"
                   aria-label="Dealer"
                   class="form-control disabled-transparent-background"
                   placeholder="Dealer"
                   type="text"
                   formControlName="dealer"
                   (keydown.esc)="onDealerTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0" id="assignedAdjuster-addon"><fa-icon
                [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="assignedAdjuster-addon"
                   aria-label="AssignedAdjuster"
                   class="form-control disabled-transparent-background"
                   placeholder="Assigned Adjuster"
                   type="text"
                   formControlName="assignedAdjuster"
                   (keydown.esc)="onAssignedAdjusterTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table claim-list-table" >
  <thead class="th-white-text">
  <tr>
    <th>Claim ID</th>
    <th>Warranty ID</th>
    <th>First Name</th>
    <th>Last Name</th>
    <th>VIN</th>
    <th>Dealer</th>
    <th>Assigned Adjuster</th>
    <th>Status</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngIf="isLoading">
    <td align="center" class="td-loading-spinner text-center" colspan="8">
      <mat-spinner diameter="25"></mat-spinner>
    </td>
  </tr>
  <ng-container *ngIf="!isLoading">
    <tr *ngFor="let item of claims" (click)="onClaimClick($event, item)" [ngClass]="checkDate(item.claimsDateEntered)">
      <td>{{ item.id }}</td>
      <td class="text-center">{{ item.warrantyId }}</td>
      <td>{{ item.customerFirstname }}</td>
      <td>{{ item.customerLastname }}</td>
      <td>{{ item.warrantyVIN }}</td>
      <td>{{ item.vendorName }}</td>
      <td>{{ item.claimsOwner }}</td>
      <td>{{ item.claimsStatus }}</td>
    </tr>
  </ng-container>
  </tbody>
</table>
<div class="container text-center mb-1" *ngIf="claims?.length">
  <a class="font-weight-bold" (click)="generateCSV()">Download as CSV</a>
</div>
