<mat-dialog-content>
  <form [formGroup]="complaintAddForm">
    <div class="container-fluid dialogContent">
      <div class="row">
        <div class="col-md-5">
          <mat-form-field class="w-100">
            <mat-label>Complainee</mat-label>
            <mat-select formControlName="complainee">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let complaineeType of complaineeTypes | keyvalue" [value]="complaineeType.value">
                {{complaineeType.key}}
              </mat-option>
            </mat-select>
              <mat-error *ngIf="complaintAddForm.get('complainee').errors?.required">
              Complainee is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="w-100">
            <mat-label>Part</mat-label>
            <mat-select formControlName="part">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let part of parts" [value]="part">
                {{part.partName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="complaintAddForm.get('part').errors?.required">
              Part is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4 add-complaint-pad">
         <div class="w-100 float-right">
           <mat-dialog-actions class="float-right mat-dialog-action-margin">
            <button mat-button class="btn complaint-btn" type="button" (click)="fillTextarea()" >
             Add to Complaint
            </button>
            </mat-dialog-actions> 
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Complaint</mat-label>
            <textarea matInput formControlName="complaint" rows="6"></textarea>
            <mat-error *ngIf="complaintAddForm.get('complaint').errors?.required">
              Complaint is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" type="button" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" type="button" (click)="addComplaint()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
</mat-dialog-actions>