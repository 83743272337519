import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { IClaim } from '../interfaces/claim';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ClaimsService } from '../services/claims.service';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  public headerForm: FormGroup;
  public statusForm: FormGroup;
  public selectedClaim: IClaim;
  private claimsId: number;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private claimsService: ClaimsService
  ) { }

  ngOnInit() {
    this.getClaim();

    this.statusForm = this.formBuilder.group({
      status: ['Open'],
      openedBy: ['All']
    });
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges()
  }

  getClaim() {
    if (this.activatedRoute.firstChild) {
      this.claimsId = parseInt(this.activatedRoute.firstChild.snapshot.params["claimsid"]);
      if (!isNaN(this.claimsId)) {
        this.claimsService.retrieve(this.claimsId).subscribe(
          claim => {
            if (claim) {
              this.setSelectedClaim(claim);
            }
          },
          err => {
            this.setSelectedClaim(null);
            console.log(err)
          }
        );
      }
      else {
        this.setSelectedClaim(null);
      }
    }
  }

  setSelectedClaim(claim: IClaim) {
    this.selectedClaim = claim;
  }
}
