<div class="container-fluid noPadding">
  <div class="row">
    <div *ngIf="funcs['refresh'];else noRefresh" class="col-lg-12">
      <div *ngIf="isLoading;else tableAdapterComponent" class="td-loading-spinner" colspan="1">
        <mat-spinner diameter="25"></mat-spinner>
      </div>
      <ng-template #tableAdapterComponent>
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <div *ngFor="let col of columnNames; let i = index">
            <ng-container matColumnDef="{{columns[i].cellName}}">
              <th mat-header-cell *matHeaderCellDef="let element" mat-sort-header>
                <div [ngSwitch]="columns[i].cellType">
                  <div *ngSwitchCase="'currency'" class="th-left">
                    {{columns[i].cellName}}
                  </div>
                  <div *ngSwitchCase="'number'" class="th-center">
                    {{columns[i].cellName}}
                  </div>
                  <div *ngSwitchCase="'checkbox'" class="th-center">
                    {{columns[i].cellName}}
                  </div>
                  <div *ngSwitchDefault class="th-left">
                    {{columns[i].cellName}}
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-cell td-{{element.display[i].displayType}}">
                <div [ngSwitch]="element.display[i].displayType">
                  <div *ngSwitchCase="'currency'" class="td-left">
                    {{element.display[i].displayData | currency:'USD':'symbol':'1.2-2' }}
                  </div>
                  <div *ngSwitchCase="'number'" class="td-center">
                    {{element.display[i].displayData}}
                  </div>
                  <div *ngSwitchCase="'checkbox'" class="td-center">
                    <mat-checkbox disabled [checked]=element.display[i].displayData></mat-checkbox>
                  </div>
                  <div *ngSwitchCase="'date'" class="td-left">
                    {{element.display[i].displayData | GWDateFormat}}
                  </div>
                  <div *ngSwitchCase="'contextmenu'" class="td-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                      <fa-icon [icon]="faEllipsisV"></fa-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="contextMenuUpdate(element.data, 'download')">
                        <fa-icon [icon]="faDownload"></fa-icon>
                        <span> Download</span>
                      </button>
                      <button mat-menu-item (click)="contextMenuUpdate(element.data, 'remove')">
                        <fa-icon [icon]="faTrash"></fa-icon>
                        <span> Remove</span>
                      </button>
                    </mat-menu>
                  </div>
                  <div *ngSwitchDefault class="td-left">
                    {{element.display[i].displayData}}
                  </div>
                </div>
              </td>
            </ng-container>
          </div>
          <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
          <tr mat-row *matRowDef="let rows; columns: columnNames;" (click)="onRowClick(rows)" [ngClass]="{'rowStyle': this.update.observers.length > 0}"></tr>
        </table>
      </ng-template>
      <mat-paginator [length]=length [pageSize]=pageSize></mat-paginator>
    </div>
    <ng-template #noRefresh>
      <div class="col-lg-12">
        <framework-hidden [display]="'table'">
        </framework-hidden>
      </div>
    </ng-template>
  </div>
</div>