import { InjectionToken } from "@angular/core";

export class AppFramework {
  modules: FrameworkItem[];
}

export class FrameworkItem {
  constructor(
    public componentType: string,
    public displayType: string,
    public componentLabel: string,
    public functions: string[]
  ) { }
}

export const APP_DI_FRAMEWORK: AppFramework = {
  modules: [
    //#region Claims

    //#region Detail Component
    // Buttons
    new FrameworkItem("DetailComponent", "button", "detail-component", [
      "addPart",
      "addComplaint",
      "addDocument",
      "addNote",
      "editClaim",
    ]),
    // Tables
    new FrameworkItem("TableAdapterComponent", "table", "complaints-table", [
      "refresh",
    ]),
    new FrameworkItem("TableAdapterComponent", "table", "parts-table", [
      "refresh",
    ]),
    new FrameworkItem("TableAdapterComponent", "table", "documents-table", [
      "refresh",
    ]),
    new FrameworkItem("TableAdapterComponent", "table", "notes-table", [
      "refresh",
    ]),
    //#endregion

    //#region Customer
    // Buttons
    new FrameworkItem("CustomerComponent", "button", "customer-component", [
      "addNote",
    ]),
    // Tables
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "customer-warranties-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "customer-notes-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "customer-claims-table",
      ["refresh"]
    ),
    //#endregion

    //#region Warranty Component
    // Buttons
    new FrameworkItem("WarrantyComponent", "button", "warranty-component", [
      "addClaim",
      "addDocument",
      "addNote",
    ]),
    // Tables
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-options-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-details-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-claims-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-documents-table",
      ["refresh"]
    ),
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "warranty-notes-table",
      ["refresh"]
    ),
    //#endregion

    //#region Maintenance
    // Tables
    new FrameworkItem(
      "TableAdapterComponent",
      "table",
      "maintenance-schedule-table",
      ["refresh"]
    ),
    //#endregion

    //#endregion Claims
  ],
};

export const APP_FRAMEWORK = new InjectionToken<AppFramework>("app.framework");
