import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { DealersService } from 'src-private/app/areas/dealers/services/dealers.service';
import { RepairCentreService } from 'src-private/app/areas/claims/services/repair-centre.service'
import { Country } from 'src-private/app/enums/bonus-bucks.enums';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { IRepairCentre } from '../../../interfaces/repair-centre.interface';
import { RepairCentre } from '../../../models/repair-centre.model';
import { catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { IRepairCentreStatuses } from '../../../interfaces/repair-centre-statuses.interface';
import { IRepairCentreTypes } from '../../../interfaces/repair-centre-type.interface';
import { IDealer } from 'src-private/app/areas/dealers/interfaces/dealer.interface';
import { D } from '@angular/cdk/keycodes';
import { DialogType } from 'src-private/app/enums/dialog-type';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src-private/environments/environment';

@Component({
  selector: 'app-repair-centre-edit',
  templateUrl: './repair-centre-edit.component.html',
  styleUrls: ['./repair-centre-edit.component.scss']
})
export class RepairCentreEditComponent implements OnInit {

  @Input() isEdit: boolean;
  public repairCenterForm: FormGroup
  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public repairCentreStatuses: IRepairCentreStatuses[];
  public repairCentreTypes: IRepairCentreTypes[];
  public filteredDealers: IDealer[];
  public dealerAutoCompleteText: string;
  public isLoading: boolean = false;
  public isSubmitted: boolean = false;

  postalCodeStateLabel: string;
  postalCodeStateMask: string;
  provinceStateLabel: string;
  headerText: string = "Repair Centre – Add";

  originalRepairCenter: IRepairCentre;

  dealers: any[];
  updateRepairCenter: boolean = true;
  geocoder: google.maps.Geocoder;

  @ViewChild('repairCentreAutoCompleteRef', { static: true, read: MatAutocompleteTrigger })
  repairCentreAutoCompleteRef: MatAutocompleteTrigger;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: IRepairCentre,
    private dialogRef: MatDialogRef<RepairCentreEditComponent>,
    private dealerService: DealersService,
    private dialog: MatDialog,
    private repairCentreService: RepairCentreService,
    private toastr:ToastrService) { }

  ngOnInit() {

    if (this.isEdit) this.headerText = "Repair Centre – Edit";

    this.originalRepairCenter = Object.assign(new RepairCentre, this.data);

    this.repairCenterForm = this.fb.group({
      name: [this.data.name, Validators.required],
      contact: [this.data.contact],
      contactExt: [this.data.contactExt],
      address: [this.data.address],
      city: [this.data.city],
      province: [this.data.province],
      postalCode: [this.data.postalCode],
      phone: [this.data.phone, AppValidators.minlength(10)],
      fax: [this.data.fax, AppValidators.minlength(10)],
      website: [this.data.website],
      email: [this.data.email, AppValidators.email(false)],
      status: [this.data.status],
      type: [this.data.type],
      grade: [this.data.grade],
      hourlyRate: [this.data.hourlyRate, Validators.minLength(0)],
      dealerId: [this.data.dealerId],
      dealerName: [this.data.dealerName],
      country: [this.data.country],
      preferred: [this.data.preferred]
    });

    this.updatePostalCodeStateLabelMask()
    this.updateProvinceStateLabel()

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
    this.retrieveRepairCentreStatuses();
    this.retrieveRepairCentreTypes();
  }

  onCountryChange(): void {
    this.repairCenterForm.get('postalCode').setValue('');
    this.updatePostalCodeStateLabelMask()
    this.updateProvinceStateLabel()
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  checkData() {
    if(AppValidators.validateAll(this.repairCenterForm)){
      if (this.isEdit) {
        this.repairCentreService.retrieve(this.originalRepairCenter.id).subscribe(data => {
          const obj = {
            name: data.name,
           contact: data.contact,
           contactExt: data.contactExt,
            address: data.address,
           city: data.city,
           province: data.province,
           postalCode: data.postalCode,
           phone: data.phone,
            fax: data.fax,
           website: data.website,
            email: data.email,
           status: data.status,
           type: data.type,
           grade: data.grade,
           hourlyRate: data.hourlyRate,
           dealerId: data.dealerId,
           dealerName: data.dealerName,
           country: data.country,
            preferred: data.preferred
          };
          if (Object.entries(this.repairCenterForm.value).toString() === Object.entries(obj).toString()) {
           //If data has not been modified don't save
           this.dialogRef.close({ data: "Cancel" });
          }
          else if (data.version == this.data.version) {         
            this.data.version = ++data.version;
            this.save();
          }
          else {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, {
             panelClass: 'part-dialog',
             width: '400px',
             autoFocus: true,
               data: {
                message: "Data you wish to save has been expired. Do you wish to reload?",
                type: DialogType[0].toString()
              }
           });
            dialogRef.afterClosed().subscribe(result => {
             if (result) {
               Object.assign(this.repairCenterForm.value, obj);
             }
             else {
               this.data = data;
               this.dialogRef.close({ data: this.data });
              }
            });
          }
        
         })
        catchError((error) => {
         if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
         else console.log(`Error: ${error.message}`)
         return of([]);
        })
      }
      else{
        this.save() 
      }
    }
  }

  save() {
    this.isSubmitted = true;
    if (AppValidators.validateAll(this.repairCenterForm)) {    
      this.data = Object.assign(this.data, this.repairCenterForm.value);
    
      
      if (this.originalRepairCenter.address !== this.data.address ||
        this.originalRepairCenter.city !== this.data.city ||
        this.originalRepairCenter.province !== this.data.province ||
        this.originalRepairCenter.country !== this.data.country) {

        this.geocodeAddress(this.data).then(result => { this.addOrEditRepairCentre(result) });
      }
      else {
        this.addOrEditRepairCentre(this.data)
      }
    }
  }

  filterDealers(repaireCentreText: string) {

    debounceTime(750);

    var loadingThis = this;
    let loadingTimer = setTimeout(() => {
      loadingThis.isLoading = true;
    }, 250);

    this.dealerAutoCompleteText = repaireCentreText;

    if (repaireCentreText.length > 0) {
      this.dealerService.dealerLookupByAlias(repaireCentreText)
        .subscribe(dealers => {
          if (dealers) {
            this.loadDealers(loadingTimer, dealers);
          }
        });
    }
    else {
      this.loadDealers(loadingTimer);
    }
  }

  loadDealers(loadingTimer: any, dealers = []) {
    //if you filter when more than 0 char than a visual bug can occur if the user deletes all char.
    //The lookup will send request on the last char, then on 0 char the list will clear, then the service
    //responds and populates the list even when the input is empty
    //therfore only populate list if adjuster text is still grater than 0
    if (this.dealerAutoCompleteText.length > 0) {
      this.filteredDealers = dealers;
    }
    else {
      this.filteredDealers = [];
    }

    this.isLoading = false;
    clearTimeout(loadingTimer)
  }

  onDealerSelected(selected, event) {
    //ignores deselect trigger of previous selected
    if (event.isUserInput) {
      this.repairCenterForm.get('dealerId').setValue(selected ? selected.vendorId : null)
      this.repairCenterForm.get('dealerName').setValue(selected ? selected.alias : null)
    }
  }

  private updatePostalCodeStateLabelMask(): void {
    this.postalCodeStateLabel = this.isCanada ? 'Postal Code' : 'Zip Code'
    this.postalCodeStateMask = this.isCanada ? 'S0S 0S0' : '00000'
  }

  private updateProvinceStateLabel(): void {
    this.provinceStateLabel = this.isCanada ? 'Province' : 'State'
  }

  get isCanada(): boolean {
    return this.repairCenterForm.get('country').value === Country[Country.Canada]
  }

  geocodeAddress(repairCentre) {
    return new Promise(resolve => {
      this.geocoder = new google.maps.Geocoder();
      this.geocoder.geocode({
        'address': `${repairCentre.address}, ${repairCentre.city}, ${repairCentre.province}. ${repairCentre.country}`
      }, (results, status) => {
        if (status === 'OK') {
          repairCentre.latitude = results[0].geometry.location.lat();
          repairCentre.longitude = results[0].geometry.location.lng();

          resolve(repairCentre);
        };
      });

    });
  }

  addOrEditRepairCentre(repairCentre) {
    if (repairCentre.id) {
      this.repairCentreService.update(repairCentre).subscribe(res => {
          this.dialogRef.close({ data: repairCentre });
          this.toastr.success("Repair Centre successfully updated. ", 'Create Repair Centre')
        },

        error=>{
          this.dialogRef.close({event:'Cancel'});
          this.toastr.error(environment.messages.apiError, 'Create Repair Centre')
        }
      )
    }
    else {
      this.repairCentreService.add(repairCentre).subscribe(res => {
          this.dialogRef.close({ data: repairCentre });
          this.toastr.success("Repair Centre successfully added.", 'Create Repair Centre');
        },
     
        error=>{
          this.dialogRef.close({event:'Cancel'});
          this.toastr.error(environment.messages.apiError, 'Create Repair Centre')
        }
      )
    }
  }

  retrieveRepairCentreStatuses() {
    this.repairCentreService.retrieveStatuses().subscribe(data => {
      this.repairCentreStatuses = data;
      this.repairCentreStatuses = this.repairCentreStatuses.filter(x => x.notInUse == false);
    })
  }

  retrieveRepairCentreTypes() {
    this.repairCentreService.retrieveTypes().subscribe(data => {
      this.repairCentreTypes = data;
      this.repairCentreTypes = this.repairCentreTypes.filter(x => x.notInUse == false);
    })
  }

  closeAutoComplete() {
    this.repairCentreAutoCompleteRef.closePanel();
  }
}
