import { Component, OnInit } from '@angular/core';
import { BbFormManagementService } from '../../../services/bb-form-management.service';
import { CoverageType } from 'src-private/app/enums/bonus-bucks.enums';
import { CoveragePackage } from '../../../models/bonus-bucks.Models';

@Component({
  selector: 'app-coverages',
  templateUrl: './coverages.component.html',
  styleUrls: ['./coverages.component.scss']
})
export class CoveragesComponent implements OnInit {

  automotiveCoverages: CoveragePackage[];
  tireAndRimCoverages: CoveragePackage[];

  constructor(private formManager: BbFormManagementService) { }

  ngOnInit(): void {
    this.automotiveCoverages = this.coverages.filter((cov) => (cov.CoverageTypeId === CoverageType.Core ||
      cov.CoverageTypeId === CoverageType.Custom ||
      cov.CoverageTypeId === CoverageType.Core_New));
    this.tireAndRimCoverages = this.coverages.filter((cov) => (cov.CoverageTypeId === CoverageType.Packages));
  }

  get coverages(): CoveragePackage[] {
    return this.formManager.currentCoveragePackages;
  }

  get isCoverageSelected(): boolean {
    return this.coverages.some(cp => cp.Coverages.some(cov => cov.IsChecked));
  }

}
