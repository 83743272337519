import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { ClaimTransaction } from '../models/claim-transaction';


@Injectable({
    providedIn: 'root'
})

export class ClaimTransactionService {

    constructor(private http: HttpClient) { }

    retrieve(claimId: number): Observable<ClaimTransaction[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append("id", `${claimId}`)
        return this.http.get<ClaimTransaction[]>(`${environment.resourceUrl}api/gwc/claim/transactions`,
            { params: httpParams }
        );
    }

    retrieveById(ClaimTransactionId: number): Observable<ClaimTransaction> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append("id", `${ClaimTransactionId}`)
        return this.http.get<ClaimTransaction>(`${environment.resourceUrl}api/gwc/claim/transaction`,
            { params: httpParams }
        );
    }

    update(ct: ClaimTransaction) {
        let httpParams = new HttpParams();
        Object.keys(ct).forEach(function (key) {
            httpParams = httpParams.append(key, ct[key]);
        });
        return this.http.put<ClaimTransaction>(`${environment.resourceUrl}api/gwc/claim/transaction`, httpParams)
    }

    add(ct: ClaimTransaction) {
        let httpParams = new HttpParams();
        Object.keys(ct).forEach(function (key) {
            httpParams = httpParams.append(key, ct[key]);
        });
        return this.http.post<ClaimTransaction>(`${environment.resourceUrl}api/gwc/claim/transaction`, httpParams)
    }
}
