import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { environment } from '../../../../../../../environments/environment';

/*--  Interfaces --*/
import { INote, INoteGroup } from '../../../../interfaces/note.interface';

/*-- Services --*/
import { DealersService } from '../../../../services/dealers.service';
import { ToastrService } from 'ngx-toastr';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Output() addNoteResponse: EventEmitter<boolean> = new EventEmitter<boolean>();

  public editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
    ],
  };

  private dealerId: number;

  public isSubmitted: boolean;
  public noteForm: FormGroup;
  public noteGroups: INoteGroup[];
  public title: string;
  public faCheck = faCheck;
  public faTimes = faTimes;

  constructor(
    private dealersService: DealersService,
    private toastrService: ToastrService) {
  }

  //#region - Lifecycle
  ngOnInit(): void {
    this.noteForm = new FormGroup({
      noteGroup: new FormControl(null, [Validators.required]),
      noteText: new FormControl(),
      pin: new FormControl()
    });
  }
  //#endregion

  //#region - Getters
  get f() { return this.noteForm.controls; }
  //#endregion

  //#region - Events
  onCancelClick(): void {
    this.addNoteResponse.next(false);
  }

  onFormSubmit(): void {
    this.isSubmitted = true;

    if (this.noteForm.valid) {
      this.blockUI.start();

      this.createNote().then(
        () => {
          this.addNoteResponse.next(true);

          this.blockUI.stop();
        }
      );
    }
  }
  //#endregion

  //#region - API Methods
  private createNote(): Promise<{}> {
    const note = {} as INote;
    const title = 'Add Note';

    note.comment = this.noteForm.controls['noteText'].value;
    note.isPinned = !!this.noteForm.controls['pin'].value;
    note.vendorId = this.dealerId;
    note.noteGroupId = this.noteForm.controls['noteGroup'].value;

    const promise = new Promise((resolve, reject) => {
      this.dealersService.noteCreate(note).subscribe(
        () => {
          this.toastrService.success('Your note has been successfully added.', title);

          resolve(true);
        },
        () => {
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }
  //#endregion
}
