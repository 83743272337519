<form [formGroup]="headerForm">

  <header class="header">

    <div class="container-fluid">

      <div class="form-row">
        <div [ngClass]="{ 'col-md-6': bp < 5, 'col-md-5': bp > 4 }">

          <div class="form-row" *ngIf="bp > 2">
            <div class="col-12">

              <div class="btn-group sub-menu" role="group">

                <button class="btn" type="button" routerLink="dealer-info" routerLinkActive="active">
                  Dealer Info
                </button>
                <button class="btn" type="button" routerLink="deals-stats" routerLinkActive="active">
                  Deals & Stats
                </button>
                <button class="btn" type="button" routerLink="other" routerLinkActive="active">
                  Notes & Contacts
                </button>
                <button class="btn" type="button" routerLink="bonus-bucks" routerLinkActive="active">
                  Bonus&nbsp;Bucks
                </button>
              </div>

            </div>
          </div>

        </div>
        <div [ngClass]="{ 'col-md-6': bp < 5, 'col-md-7': bp > 4 }">

          <div class="form-row">
            <div class="col-2">

              <div class="header-button-outer" *ngIf="dealerId" (click)="onOpenEazeeTrakFileClick()">
                <div class="header-button-inner">

                  <fa-icon [icon]="faComment"></fa-icon>
                  <span class="header-button-label" *ngIf="bp > 4">Open File</span>

                </div>
              </div>

            </div>
            <div [ngClass]="{ 'col-2': bp < 5, 'col-3': bp > 4 }">

              <div class="header-button-outer" (click)="onAddPotentialDealerClick()">
                <div class="header-button-inner">

                  <fa-icon [icon]="faPlus"></fa-icon>
                  <span class="header-button-label" *ngIf="bp > 4">Add Potential Dealer</span>

                </div>
              </div>

            </div>
            <div [ngClass]="{ 'col-8': bp < 5, 'col-7': bp > 4 }">

              <div class="header-field-outer">
                <div class="header-field-inner">

                  <ul class="list-unstyled ng-autocomplete-wrapper">
                    <li class="dealer-search-icon">

                        <fa-icon [icon]="faSearch"></fa-icon>

                    </li>
                    <li class="col-12 w-100">
                      <input type="text" class="dealer-search-input" id="dealerAutoCompleteInput" placeholder="Dealer Search" [matAutocomplete]="dealer"
                        (input)="filterDealers($event.target.value)">
                      <mat-autocomplete autoActiveFirstOption #dealer="matAutocomplete" [displayWith]="dealerAutoCompleteDisplay">
                        <mat-option *ngIf="isLoading" class="spinner">
                          <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!isLoading">
                          <mat-option *ngFor="let dealer of filteredDealers" [value]="dealer"
                            (onSelectionChange)="onDealerSelected(dealer, $event)">
                            [{{dealer.vendorId}}] {{dealer.alias}}
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </li>
                  </ul>

                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <mat-tab-group class="add-potential-dealer-tabs" *ngIf="bp < 3 && isAddingPotentialDealer" mat-stretch-tabs
      (selectedTabChange)="onSelectedTabChange($event)">
      <mat-tab label="Contact"></mat-tab>
      <mat-tab label="Settings"></mat-tab>
      <mat-tab label="Competitors"></mat-tab>
    </mat-tab-group>

    <div class="mobile-menu-container" *ngIf="bp < 3">
      <div class="mobile-menu-header collapsed" data-toggle="collapse" href="#mobileMenu" role="button"
        aria-expanded="false" aria-controls="mobileMenu">
        Menu

        <i class="float-right fa" (click) = "isShow()"></i>
      </div>
      <div class="mobile-menu-body collapse" id="mobileMenu">
        <ul class="list-unstyled">
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('dealer-info', 'contact')">Contact</li>
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('dealer-info', 'settings')">Settings</li>
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('dealer-info', 'competitors')">Competitors</li>
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('deals-stats', 'latest-application')">Latest Application</li>
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('deals-stats', 'deals-rebates')">Deals &amp; Rebates</li>
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('deals-stats', 'statistics')">Statistics</li>
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('other', 'affiliates')">Affiliates</li>
          <!--<li class="mobile-menu-item"
              data-toggle="collapse"
              href="#mobileMenu"
              role="button"
              aria-expanded="false"
              aria-controls="mobileMenu"
              (click)="nav('other', 'repair-centers')">Repair Centers</li>-->
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('other', 'contacts')">Contacts</li>
          <li class="mobile-menu-item" data-toggle="collapse" href="#mobileMenu" role="button" aria-expanded="false"
            aria-controls="mobileMenu" (click)="nav('other', 'notes')">Notes</li>
        </ul>
      </div>
    </div>

  </header>

</form>

<section class="content">
  <router-outlet #outlet="outlet"></router-outlet>
</section>

<!--<section class="content"
         [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</section>-->