import { Component, OnInit, Input } from '@angular/core';
import { Coverage, Band } from 'src-private/app/areas/dealers/models/bonus-bucks.Models';
import { faArrowLeft, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-coverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./coverage.component.scss']
})
export class CoverageComponent implements OnInit {

  @Input() coverage: Coverage;
  @Input() disabled: boolean = false;

  constructor() { }

  allColumns = [
    { name: 'Age Band', prop: 'AgeLabel' }, 
    { name: 'Distance Band', prop: 'DistanceLabel' }, 
    { name: 'Year', prop: 'Year' }, 
    { name: 'Make', prop: 'Make' }, 
    { name: 'Model', prop: 'Model' }, 
    { name: 'Trim', prop: 'Trim' }, 
    { name: 'Base', prop: 'Base' }, 
    { name: 'Rebate', prop: 'Rebate' }, 
    { name: 'Cash Back', prop: 'CashBack' }, 
    { name: 'Bonus Bucks', prop: 'BonusBucksAmount' }, 
    { name: 'Dealer Cost', prop: 'DealerCost' }
  ];

  filteredColumns: Array<any>;

  ngOnInit() {
    this.filteredColumns = this.allColumns.filter(col => this.columnNeeded(col));
    // this.filterBands();
    this.calculateBase();
  }

  get arrow(): IconDefinition {
    return faArrowLeft;
  }

  getTotal(b: Band): number {
    return this.coverage.BonusBucksAmount + b.Rebate + b.CashBack + b.Base;
  }

  calculateBase() {
    this.coverage.Bands.forEach(b => {
      b.Base = b.Base + b.Surcharge;
    });
  }

  // filterBands() {
  //   if (this.coverage.Bands.length < 2) {
  //     return this.coverage.Bands;
  //   }
  //   let lowestBase = this.coverage.Bands[0];
  //   for (let i = 0; i < this.coverage.Bands.length; i++) {
  //     if (this.getTotal(this.coverage.Bands[i]) < this.getTotal(lowestBase)) {
  //       lowestBase = this.coverage.Bands[i];
  //     }
  //   }
  //   this.coverage.Bands.splice( this.coverage.Bands.indexOf(lowestBase), 1 );
  // }

  columnNeeded(column: any): boolean {
    return this.coverage.Bands.some(b => b[column.prop]);
  }
}
