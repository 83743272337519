import {
  Component,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { CellBuilder } from "./cell-builder/cell-builder";
import { CellAdapter } from "./table-cell-adapter/cell-adapter";
import { TableDatasourceAdapter } from "./table-datasource-adapter";
import { catchError, share } from "rxjs/operators";
import {
  faEllipsisV,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FrameworkComponent } from "../framework/framework.component";

@Component({
  selector: "table-adapter",
  templateUrl: "./table-adapter.component.html",
  styleUrls: ["./table-adapter.component.scss"],
})
export class TableAdapterComponent<T>
  extends FrameworkComponent
  implements AfterViewInit {
  public faEllipsisV = faEllipsisV;
  public faDownload = faDownload;
  public faTrash = faTrash;
  public isLoading = false;

  @Input() columns: CellBuilder[];
  @Input() tableLabel: string;
  @Output() update = new EventEmitter();
  @Output() context = new EventEmitter();

  private dataSource: MatTableDataSource<T> = new MatTableDataSource();
  private data$: Observable<[]>;

  public columnNames = [];
  public columnModelNames = [];

  public length = 5;
  public pageSize = 5;

  @ViewChild(MatTable) table: MatTable<T>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //  TODO: Implement sort
  @ViewChild(MatSort) sort: MatSort;

  /**
   * Set Columns
   */
  private setColumns() {
    this.columnNames = [];
    this.columns.forEach((element) => {
      this.columnNames.push(element["cellName"]);
      this.columnModelNames.push(element["modelAttrName"]);
    });
  }

  /**
   * Row Clicked, Emitter
   * @param row
   */
  onRowClick(row) {
    this.update.emit(row.data);
  }

  /**
   * Update the datasource with a subscription to trigger the change.
   */
  private mapDataSource() {
    this.isLoading = true;
    this.data$.subscribe((data) => {
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.setDataSource(data));
      this.dataSource.sort = this.sort;
      this.paginator.pageSize = this.pageSize;
      this.paginator.length = this.length;
      this.dataSource.paginator = this.paginator;
    });
  }

  private setDataSource(data) {
    var mappedData = [];
    data.forEach((datum) => {
      var mappedDatum = [];
      this.columns.forEach((cb) => {
        if (cb.modelAttrName.split(".").length > 1) {
          var tdata = datum[cb.modelAttrName.split(".")[0]];
          var tvalue = tdata[cb.modelAttrName.split(".")[1]];
          var cellAdapter = new CellAdapter(tvalue, cb.cellType);
          mappedDatum.push(cellAdapter);
        } else {
          var cellAdapter = new CellAdapter(
            datum[cb.modelAttrName],
            cb.cellType
          );
          mappedDatum.push(cellAdapter);
        }
      });
      mappedData.push(new TableDatasourceAdapter(mappedDatum, datum));
    });
    if (data.length < 5 && data.length > 0) {
      this.length = data.length;
      this.pageSize = data.length;
    } else if (data.length == 0) {
      this.length = 0;
      this.pageSize = 1;
    } else if(data.length >= 5) {
      this.length = data.length;
      this.pageSize = 5;
    }
    return mappedData;
  }

  sortData(sort: Sort) {
    this.data$.subscribe((data) => {
      data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        return this.compare(
          a[this.columnModelNames[this.columnNames.indexOf(sort.active)]],
          b[this.columnModelNames[this.columnNames.indexOf(sort.active)]],
          isAsc
        );
      });
      this.dataSource = new MatTableDataSource(this.setDataSource(data));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Set columns. Undefined check accounts for async datasource.
   * Map the datasource and update the table.
   */
  private refreshDataSource() {
    if (this.columns != undefined) {
      this.setColumns();
    }
    this.mapDataSource();
  }

  /**
   * Create the observable - required for the datasource to see a change and trigger a re-render.
   * @param source Service method results
   */
  refresh(source) {
    this.data$ = source.pipe(
      share(),
      catchError((error) => {
        if (error.error instanceof ErrorEvent)
          console.log(`Error: ${error.error.message}`);
        else console.log(`Error: ${error.message}`);
        return of([]);
      })
    );
    this.refreshDataSource();
  }

  /**
   * Take an action as specified by the switch.
   * @param element Element data of the row
   * @param action
   */
  contextMenuUpdate(element, action): void {
    this.context.emit({ element: element, action: action });
  }

  ngAfterViewInit() {
    var elems = document.getElementsByTagName("table-adapter");
    for (let i = 0; i < elems.length; i++) {
      elems[i].parentElement.classList.add("noPadding");
    }
    super.build(this, this.tableLabel);
  }
}
