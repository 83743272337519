import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, takeUntil, tap } from 'rxjs/operators';
import { ILoginUser } from '../../../interfaces/loginuser.interface';
import { LoginUserService } from '../../../services/loginuser.service';
import { faSave, faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { Claim } from '../../../models/claim-model';
import { ClaimStatus } from 'src-private/app/enums/claims.enums';
import { AccountService } from 'src-private/app/areas/account/services/account.service';
import { Role } from 'src-private/app/enums/role.enum';
import { DatePipe } from '@angular/common';
import { ClaimsService } from '../../../services/claims.service';
import { ConfirmDialogComponent } from 'src-private/app/shared/confirm-dialog/confirm-dialog.component';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { DialogType } from 'src-private/app/enums/dialog-type';

@Component({
  selector: 'app-claim-edit',
  templateUrl: './claim-edit.component.html',
  styleUrls: ['./claim-edit.component.scss']
})
export class ClaimEditComponent implements OnInit {
  public claimEditForm: FormGroup;
  public claimStatuses: string[] = Object.keys(ClaimStatus).filter(value => isNaN(Number(value)) === false).map(key => ClaimStatus[key]);
  public filteredAdjusters: ILoginUser[];
  public selectedClaim: Claim;
  public adjusterAutoCompleteText: string;

  public faSave = faSave;
  public faWindowClose = faWindowClose;

  public isManager: boolean;
  public isEdit: boolean;
  public isLoading: boolean = false;

  public selectedAdjuster: ILoginUser[];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private formBuilder: FormBuilder,
    private accountService: AccountService,
    private datePipe: DatePipe,
    private loginUserService: LoginUserService,
    private claimsService: ClaimsService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ClaimEditComponent>, @Inject(MAT_DIALOG_DATA) data) { this.selectedClaim = data }

  ngOnInit() {
    this.isManager = this.accountService.isInRole(Role.ClaimsManager);
    this.isEdit = this.selectedClaim.id != undefined && this.selectedClaim.id != 0;

    if (this.isEdit) {
      this.claimEditForm = this.formBuilder.group({
        adjuster: [this.selectedClaim.assignedAdjuster, Validators.required],
        claimStatus: [this.selectedClaim.claimsStatus, Validators.required]
      });
    }
    else {
      this.claimEditForm = this.formBuilder.group({
        adjuster: [{ value: this.selectedClaim.assignedAdjuster, disabled: true }],
        claimStatus: [{ value: this.selectedClaim.claimsStatus, disabled: true }],
        warrantyId: [{ value: this.selectedClaim.warrantyId, disabled: true }],
        warrantyVIN: [{ value: this.selectedClaim.warrantyVIN, disabled: true }],
        claimsDateEntered: [{ value: this.datePipe.transform(this.selectedClaim.claimsDateEntered, 'yyyy-MM-dd'), disabled: true }],
        firstName: [{ value: this.selectedClaim.customerFirstname, disabled: true }],
        lastName: [{ value: this.selectedClaim.customerLastname, disabled: true }],
        customerId: [{ value: this.selectedClaim.customerId, disabled: true }],
        vendorId: [{ value: this.selectedClaim.vendorId, disabled: true }],
        vendorName: [{ value: this.selectedClaim.vendorName, disabled: true }]
      });
    }
    this.filterClaimStatus(this.claimStatuses);

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  checkData() {
    if (this.isEdit) {
      this.claimsService.retrieve(this.selectedClaim.id).subscribe(data => {
        const obj = {
          adjuster: data.assignedAdjuster,
          claimStatus: data.claimsStatus
        };
        if (Object.entries(this.claimEditForm.value).toString() === Object.entries(obj).toString()) {
          //If data has not been modified don't save
          this.dialogRef.close({ data: "Cancel" });
        }
        else if (data.version == this.selectedClaim.version) {
          this.save();
        }
        else {
          let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'part-dialog',
            width: '400px',
            autoFocus: true,
            data: {
              message: "Data you wish to save has been expired. Do you wish to reload?",
              type: DialogType[0].toString()
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              Object.assign(this.claimEditForm.value, obj);
              this.selectedClaim.version = data.version;
              this.selectedClaim.assignedAdjuster = data.assignedAdjuster;
              this.selectedClaim.claimsStatus = data.claimsStatus;
            }
            else {
              this.dialogRef.close({ data: "Refresh" });
            }
          });
        }
      })
      catchError((error) => {
        if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`)
        else console.log(`Error: ${error.message}`)
        return of([]);
      })
    }
  }

  save() {
    if (AppValidators.validateAll(this.claimEditForm)) {
      if (this.isEdit) {
        if (this.isManager) {
          this.selectedClaim.assignedAdjuster = this.claimEditForm.value.adjuster;
        }
        this.selectedClaim.claimsStatus = this.claimEditForm.value.claimStatus;
      }
      this.dialogRef.close({ data: this.selectedClaim })
    }
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  filterClaimStatus(claimStatus: string[]) {
    this.claimStatuses = claimStatus.filter(element => {
      if (element == "Open" && (!this.isManager && this.selectedClaim.claimsStatus == "Closed")) {
        return false;
      }
      else {
        return true;
      }
    });
  }

  filterAdjusters(adjusterText: string) {

    debounceTime(750);

    var loadingThis = this;
    let loadingTimer = setTimeout(() => {
      loadingThis.isLoading = true;
    }, 250);

    this.adjusterAutoCompleteText = adjusterText;

    if (adjusterText.length > 0) {
      this.loginUserService.lookup(adjusterText)
        .subscribe(users => {
          if (users) {
            this.loadAdjuster(loadingTimer, users);
          }
        });
    }
    else {
      this.loadAdjuster(loadingTimer);
    }
  }

  loadAdjuster(loadingTimer: any, adjusters = []) {
    //if you filter when more than 0 char than a visual bug can occur if the user deletes all char.
    //The lookup will send request on the last char, then on 0 char the list will clear, then the service
    //responds and populates the list even when the input is empty
    //therfore only populate list if adjuster text is still grater than 0
    if (this.adjusterAutoCompleteText.length > 0) {
      this.filteredAdjusters = adjusters;
    }
    else {
      this.filteredAdjusters = [];
    }

    this.isLoading = false;
    clearTimeout(loadingTimer)
  }

  onAdjusterSelected(selected, event) {
    //ignores deselect trigger of previous selected
    if (event.isUserInput) {
      this.claimEditForm.get('adjuster').setValue(selected ? selected.loginUser : null)
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
