<mat-dialog-content>
  <form [formGroup]="documentAddForm">
    <div class="container-fluid dialogContent">
      <div class="row">
        <mat-form-field class="col-md-6">
          <mat-label *ngIf="selectedFile; else chooseFile">{{selectedFile.name}}</mat-label>
          <ng-template #chooseFile>
            <mat-label>Choose file</mat-label>
          </ng-template>
          <input matInput disabled>
          <button mat-raised-button type="button" matSuffix (click)="fileInput.click()">..</button>
          <input hidden (change)="selectFile($event)" #fileInput type="file" id="file">
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" rows="6"></textarea>
            <mat-error *ngIf="documentAddForm.get('description').errors?.required">
              Description is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close class="btn" type="button" (click)="cancel()">
    <fa-icon [icon]="faWindowClose" class="pr-2 text-danger"></fa-icon> Cancel
  </button>
  <button mat-button class="btn" (click)="addDocument()">
    <fa-icon [icon]="faSave" class="pr-2 text-success"></fa-icon> Save
  </button>
</mat-dialog-actions>
