<nav class="demo-mode bg-warning pl-3">
  DEMO MODE
</nav>

<block-ui>
  <ng-sidebar-container class="sideNavHeight100">
    <ng-sidebar [closeOnClickBackdrop]="true"
                [closeOnClickOutside]="false"
                [keyClose]="true"
                [position]="'top'"
                [showBackdrop]="true"
                [(opened)]="sidebarTopOpened"
                #sidebarTop>
      <ng-template #dynamicContainerTop></ng-template>
    </ng-sidebar>

    <ng-sidebar [closeOnClickBackdrop]="true"
                [closeOnClickOutside]="false"
                [keyClose]="true"
                [position]="'right'"
                [showBackdrop]="true"
                [(opened)]="sidebarRightOpened"
                (onClosed)="onNotificationPanelClose()"
                #sidebarRight>
      <ng-template #dynamicContainerRight></ng-template>
    </ng-sidebar>

    <ng-sidebar [closeOnClickBackdrop]="true"
                [closeOnClickOutside]="false"
                [keyClose]="true"
                [position]="'bottom'"
                [showBackdrop]="true"
                [(opened)]="sidebarBottomOpened"
                #sidebarBottom>
      <ng-template #dynamicContainerBottom></ng-template>
    </ng-sidebar>

    <!--<ng-sidebar [closeOnClickBackdrop]="false"
                [closeOnClickOutside]="false"
                [keyClose]="false"
                [mode]="sideBarNavigationMode"
                [position]="'left'"
                [showBackdrop]="false"
                [sidebarClass]="'navigation'"
                [(opened)]="sidebarNavigationOpened"
                #sidebarMenu>
      <ng-template #dynamicContainerLeft></ng-template>
      <app-navigation></app-navigation>
    </ng-sidebar>-->
    <!-- page content -->
    <div ng-sidebar-content>
      <header *ngIf="isAuthenticated">
        <img id="logo" src="assets/images/navbar-logo@2x.png" />
        <div class="visual-indicator" id="visual-indicator-fullscreen" *ngIf="visualIndicatorText">
          {{visualIndicatorText}}
        </div>
        <ul id="header-outer">
          <li>
            <div class="nav">
              <a routerLink="eazeetrak"
                 routerLinkActive="active-route">EazeeTrak</a>
              <a routerLink="claims"
                 routerLinkActive="active-route">Claims</a>
              <a routerLink="dealers"
                 routerLinkActive="active-route">Dealers</a>
              <a routerLink="reports"
                 routerLinkActive="active-route">Reports</a>
              <a routerLink="migration"
                 routerLinkActive="active-route">Migration</a>
            </div>
          </li>

          <li>
            <button mat-icon-button class="nav-menu" id="quickAdd" (click)="openQuickAdd()">
              <fa-icon [icon]="faPlus" size="lg" class="nav-icon"></fa-icon>
            </button>
          </li>

          <li>
            <button mat-icon-button class="nav-menu" id="omnisearch" (click)="openOmniSearch()">
              <fa-icon [icon]="faSearch" size="lg" class="nav-icon"></fa-icon>
            </button>
          </li>

          <li>
            <button mat-icon-button class="nav-menu" id="notification" (click)="openNotification()">
              <fa-icon [icon]="faBell" size="lg" class="nav-icon" [ngClass]="{'yellow-bell': notificationsExist}"></fa-icon>
            </button>
          </li>

          <li class="avatar-outer">
            <div class="avatar-inner">
              <img class="avatar" [src]="avatarImageUrl" avatar />
              <span class="user">{{userFullName}}</span>
            </div>
            <ul class="sub-menu"
                (click)="logout()">
              <li>Logout</li>
            </ul>
          </li>
        </ul>
      </header>
      <div class="visual-indicator" id="visual-indicator-small" *ngIf="visualIndicatorText">
        {{visualIndicatorText}}
      </div>
      <router-outlet></router-outlet>
    </div>
  </ng-sidebar-container>
</block-ui>
