import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { Dealer } from '../../models/dealer.model';
import { BonusBucksValue, DistributionUser } from '../../models/bonus-bucks.Models';
import { BonusBucksService } from '../../services/bonus-bucks.service';
import { DealersSharedService } from '../../services/dealers-shared.service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BbFormManagementService } from '../../services/bb-form-management.service';
import { BBAmountService } from '../../services/bb-selected-amount.service';
import { BonusBucksValueType } from 'src-private/app/enums/bonus-bucks.enums';


@Component({
  selector: 'app-bonus-bucks',
  templateUrl: './bonus-bucks.component.html',
  styleUrls: ['./bonus-bucks.component.scss']
})
export class BonusBucksComponent implements OnInit, OnDestroy {
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @BlockUI() blockUI: NgBlockUI;

  private dealerSubscription: Subscription;
  private responseCounter: number;

  dealer: Dealer;

  constructor(
    private bonusBucksService: BonusBucksService,
    private dealersSharedService: DealersSharedService,
    private bbValService: BBAmountService,
    private router: Router,
    private toastr: ToastrService,
    private formManager: BbFormManagementService,
  ) { }

  ngOnInit() {
    this.dealerSubscription = this.dealersSharedService.dealer.subscribe(dealer => {
      if (!dealer || !dealer.vendorId) return;
      if (this.dealer && dealer.vendorId === this.dealer.vendorId) return;

      this.dealer = dealer;
      this.formManager.setDealer(dealer);
      this.retrieveAll();
    });
  }

  ngOnDestroy() {
    this.dealerSubscription.unsubscribe();
  }

  private retrieveAll(): void {
      this.responseCounter = 0;
      this.retrieveBonusBucksValues();
      this.retrieveCoverageRebatesByVendor();
      this.retrieveDistributionUsers();
      this.loadPreviousSubmission();
  }

  private retrieveBonusBucksValues() {
    this.bonusBucksService.RetrieveBonusBucksValues(this.dealer.vendorId).subscribe(response => {
      this.bbValService.setAllBonusBucksValues([new BonusBucksValue(BonusBucksValueType.NoChange, false), new BonusBucksValue(BonusBucksValueType.Custom, false), new BonusBucksValue(BonusBucksValueType.CompanyBenchmark, false)].concat(response));
      this.responseCounter++;
    },
    error => {
      this.toastr.error('Error Retrieving Bonus Bucks Values', 'Error');
    });
  }

  private retrieveCoverageRebatesByVendor() {
    this.bonusBucksService.CoverageRebateRetrieveByVendor(this.dealer.vendorId).subscribe(response => {
      this.formManager.addCoverages(response);
      this.responseCounter++;
    },
    error => {
      this.toastr.error('Error Retrieving Coverages', 'Error');
    });
  }

  private retrieveDistributionUsers() {
    this.bonusBucksService.RetrieveDistributionUsers(this.dealer.vendorId).subscribe(response => {
      this.formManager.setDistributionUsers(response);
      this.responseCounter++;
    },
    error => {
      this.toastr.error('Error Retrieving Distribution Users', 'Error');
    });
  }

  private loadPreviousSubmission() {
    this.bonusBucksService.LoadSubmissions(this.dealer.vendorId).subscribe(response => {
      this.formManager.setPreviousBonusBuck(response);
      this.responseCounter++;
    },
    error => {
      this.toastr.error('Error Retrieving Previous Submission', 'Error');
    });
  }

  onSubmit(submitClicked: boolean) {
    if (submitClicked) {
      this.blockUI.start('Submitting Bonus Bucks...');
      const bba = this.formManager.BonusBucksAuthorizationModel;
      this.bonusBucksService.submitBonusBuck(bba, this.dealer).subscribe(response => {
        if (response.type === 'application/pdf') {
          const blob = new Blob([response], { type: 'mediaType' });
          saveAs(blob, new Date().toISOString() + '.pdf');
          this.blockUI.stop();
          this.toastr.success('Submitted Successfully', 'Success');
          setTimeout(() => {
            this.router.navigate(['/dealers/dealer-info']);
          }, 200);
        } else {
          this.blockUI.stop();
          this.toastr.error('Error Submitting', 'Error');
        }
      },
      error => {
        this.blockUI.stop();
        this.toastr.error('Error submitting', 'Error');
      });
    }
  }

  public get loading() { return this.responseCounter < 4; }

}
