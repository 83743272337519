import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { environment } from '../../../../environments/environment';

/*-- Components - Dynamic --*/
import { ReportFilterComponent } from './report-filter/report-filter.component';

/*-- Components - Static --*/
import { AppComponent } from '../../../app.component';

/*-- Enums --*/
import { ReportStatus } from '../enums/reports.enum';
import { Sidebar } from '../../../enums/sidebar.enum';

/*-- Interfaces --*/
import { IReportAvailability, ICoverageSales } from '../interfaces/reports.interface';

/*-- Services --*/
import { BreakpointService } from '../../../services/breakpoint.service';
import { DynamicComponentService } from '../../../services/dynamic-component.service';
import { ReportsService } from '../services/reports.service';

/*-- Third Party --*/
import * as _ from 'underscore';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BonusBucksService } from '../../dealers/services/bonus-bucks.service';
import { DealersSharedService } from '../../dealers/services/dealers-shared.service';
import { BonusBucksAuthorization } from '../../dealers/models/bonus-bucks.Models';
import { DealersService } from '../../dealers/services/dealers.service';
import { BatchFileType } from 'src-private/app/enums/bonus-bucks.enums';
import { faFilter, faFlag } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnDestroy, OnInit {
  @BlockUI() blockUI: NgBlockUI;

  private reportAvailability: IReportAvailability[];

  public bp: number;
  public categories: any[];
  public category = new FormControl();
  public filterDisabled: boolean;
  public report = new FormControl();
  public reports: IReportAvailability[];
  public reportStatus = ReportStatus;
  public reportViewerUrl: string;
  bbData: any;
  MissRecipInfoData: any;
  distributionForm: FormGroup;
  principalAuth: any;
  coverageSales: ICoverageSales[];
  date1 = new FormControl();
  date2 = new FormControl();
  dealers: any;
  selectedDealer: any;
  selectedUser: any;
  selectedCompany: any;

  public isBonusBucksReport: boolean;
  public is_BB_MissingRecipInfoReport: boolean;
  public isBatchReport: boolean;
  public isNewPayeeVendor: boolean;

  public faFlag = faFlag;
  public faFilter = faFilter;

  constructor(
    private appComponent: AppComponent,
    private breakpointService: BreakpointService,
    private dynamicComponentService: DynamicComponentService,
    private ngZone: NgZone,
    private reportService: ReportsService,
    private bonusBucksService: BonusBucksService,
    private dealersSharedService: DealersSharedService,
    private dealersService: DealersService
  ) {
    breakpointService.bp.subscribe(x => this.bp = x);

    window['angularComponentRef'] = {
      component: this,
      componentFn: () => this.stopBlockUI(),
      zone: ngZone
    };
  }

  //#region - Lifecycle
  ngOnDestroy() {
    window['angularComponent'] = null;
  }

  ngOnInit(): void {
    if (!this.bp) {
      this.bp = this.breakpointService.getBreakpoint();
    }

    this.isBonusBucksReport = false;

    this.filterDisabled = true;

    this.blockUI.start();

    this.retrieveReportAvailability().then(
      () => {
        this.categories = _.sortBy(_.uniq(this.reportAvailability, function (item) { return item.reportGroupId; }), 'reportGroup');

        const categoryId = this.categories[0].reportGroupId;

        this.category.setValue(categoryId);

        this.reports = this.reportAvailability.filter(item => item.reportGroupId === categoryId);

        this.report.setValue(null);

        this.blockUI.stop();
      }
    );
  }
  //#endregion

  //#region - Events
  onFilterClick() {
    const reportId = this.report.value;

    if (this.report.value && reportId !== 'null') {
      const sidebar = Sidebar.Right;

      this.dynamicComponentService.open(this.appComponent, sidebar);
    }
  }

  onReportCategoryChange(): void {
    const categoryId = this.category.value;

    this.reports = this.reportAvailability.filter(item => item.reportGroupId === parseInt(categoryId));

    this.report.setValue(null);
  }

  onDateChange(): void {
    if (this.date1.value && this.date2.value) {
      this.blockUI.start();
      this.retrieveDistributionUsers();
    }
  }

  getMissingRecipientInfo(): void {
    if (this.date1.value && this.date2.value) {
      this.blockUI.start();
      this.retrieveMissingInfoUsers();
    }
  }

  private retrieveMissingInfoUsers() {
    this.bonusBucksService.RetrieveBonusBucks_MissingRecipInfoReport(this.date1.value, this.date2.value).subscribe(response => {
      this.blockUI.stop();
      this.MissRecipInfoData = response;
    });
  }


  authClick(authValue: number) {
    // const auth4 = document.getElementById('t4');
    // const auth3 = document.getElementById('t3');
    // const auth2 = document.getElementById('t2');
    const auth1 = document.getElementById('t1');

    // auth4.style.display = 'table';
    // auth3.style.display = 'table';
    // auth2.style.display = 'table';
    auth1.style.display = 'table';
    return;

    // switch (authValue) {
    //   case 4:
    //     auth4.style.display = 'table';
    //     auth3.style.display = 'none';
    //     auth2.style.display = 'none';
    //     auth1.style.display = 'none';
    //     break;
    //   case 3:
    //     auth4.style.display = 'table';
    //     auth3.style.display = 'table';
    //     auth2.style.display = 'none';
    //     auth1.style.display = 'none';
    //     break;
    //   case 2:
    //     auth4.style.display = 'table';
    //     auth3.style.display = 'table';
    //     auth2.style.display = 'table';
    //     auth1.style.display = 'none';
    //     break;
    //   case 1:
    //     auth4.style.display = 'table';
    //     auth3.style.display = 'table';
    //     auth2.style.display = 'table';
    //     auth1.style.display = 'table';
    //     break;
    //   default:
    //     // auth4.style.display = 'none';
    //     // auth3.style.display = 'none';
    //     // auth2.style.display = 'none';
    //     // auth1.style.display = 'none';
    // }
  }


  private retrieveDistributionUsers() {
    this.bonusBucksService.RetrieveBonusBucksReports(this.date1.value, this.date2.value, this.selectedDealer.vendorId).subscribe(response => {
      this.blockUI.stop();
      this.bbData = response;
      if (response) {
        // remove dupes
        this.bbData = this.bbData.filter((elem, index) => {
          const _elem = JSON.stringify(elem);
          return index === this.bbData.findIndex(obj => {
            return JSON.stringify(obj) === _elem;
          });
        });

        this.principalAuth = ({
          region: this.bbData.Region,
          agent: this.bbData.PrincipalAuth,
          amount: this.bbData.BonusBucksAmount
        });
      }
    });
  }

  onReportChange(): void {
    const reportId = this.report.value;
    this.reportViewerUrl = "about:blank";
    document.getElementById('ifrmReport').style.height = "0px";
    document.getElementById('ifrmReport').style.minWidth = "0px";
    const report: IReportAvailability = this.reportAvailability.filter(item => item.reportId === parseInt(reportId))[0]; // change to reports list?

    if (report.name === 'BonusBucks') {
      this.blockUI.start();
      this.isBonusBucksReport = true;
      this.is_BB_MissingRecipInfoReport = false;
      this.isBatchReport = false;
      const reportName = report.name;
      const directory = report.reportGroup;
      const sidebar = Sidebar.Right;

      let instance: any;

      this.filterDisabled = false;

      this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

      instance = this.dynamicComponentService.addDynamicComponent(ReportFilterComponent);

      instance.directory = directory;
      instance.reportId = reportId;
      instance.reportName = reportName;

      this.dealersService.dealerLookup(true).subscribe(response => {
        this.blockUI.stop();
        this.dealers = response.sort((a, b) => a.alias.localeCompare(b.alias));
      });
    } else if (report.name === 'CWN' || report.name === 'VI' || report.name === 'VC') {
      this.isBonusBucksReport = false;
      this.isBatchReport = true;
      this.isNewPayeeVendor = false;
      this.is_BB_MissingRecipInfoReport = false;
      const reportName = report.name;
      const directory = report.reportGroup;
      const sidebar = Sidebar.Right;

      let instance: any;

      this.filterDisabled = false;

      this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

      instance = this.dynamicComponentService.addDynamicComponent(ReportFilterComponent);

      instance.directory = directory;
      instance.reportId = reportId;
      instance.reportName = reportName;

    } else if (report.name === 'NPV') {
      this.isBonusBucksReport = false;
      this.isBatchReport = true;
      this.isNewPayeeVendor = true;
      this.is_BB_MissingRecipInfoReport = false;
      this.selectedCompany = null;

      const reportName = report.name;
      const directory = report.reportGroup;
      const sidebar = Sidebar.Right;

      let instance: any;

      this.filterDisabled = false;

      this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

      instance = this.dynamicComponentService.addDynamicComponent(ReportFilterComponent);

      instance.directory = directory;
      instance.reportId = reportId;
      instance.reportName = reportName;
    } else if (report.name === 'BB_Payee_Missing_Recipient_Information') {

      this.is_BB_MissingRecipInfoReport = true;
      this.isBonusBucksReport = false;
      this.isBatchReport = false;
    } else if (report.name !== 'null') {  // TODO

      this.is_BB_MissingRecipInfoReport = false;
      const reportName = report.name;
      const directory = report.reportGroup;
      const sidebar = Sidebar.Right;

      let instance: any;

      this.filterDisabled = false;

      this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

      instance = this.dynamicComponentService.addDynamicComponent(ReportFilterComponent);

      instance.directory = directory;
      instance.reportId = reportId;
      instance.reportName = reportName;

      instance.modalResponse.subscribe(
        response => {
          if (response) {
            this.blockUI.start();

            this.reportViewerUrl = environment.serverUrl + response;

            this.dynamicComponentService.closeAndClear(this.appComponent, sidebar, false);
          } else {
            this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
          }
        }
      );

      this.appComponent.sidebarRightOpened = true;
    } else {
      this.filterDisabled = true;
    }
  }
  //#endregion

  //#region - Public Methods
  public stopBlockUI() {
    this.blockUI.stop();
  }

  public updateCompany(e) {
    this.selectedCompany = e;
  }

  public updateDealer(e) {
    this.selectedDealer = this.dealers.find(d => d.alias === e);
  }

  public downloadCSV() {
    this.blockUI.start();
    let batchFileType;
    let fileName = '';

    const report: IReportAvailability = this.reportAvailability.filter(item => item.reportId === parseInt(this.report.value))[0]; // change to reports list?

    if (report.name === 'CWN') {
      batchFileType = BatchFileType.WarrantyNew;
      fileName = 'CreateWarrantyNew-';
    } else if (report.name === 'VI') {
      batchFileType = BatchFileType.VendorInvoice;
      fileName = 'VendorInvoice-';
    } else if (report.name === 'VC') {
      batchFileType = BatchFileType.VendorCancellation;
      fileName = 'VendorCancellation-';
    } else if (report.name === 'NPV') {
      batchFileType = BatchFileType.NewPayeeVendor;
      this.selectedCompany = '';
      this.selectedUser = '';
      fileName = 'NewPayeeVendor-';
    } else {
      batchFileType = 0;
    }

    this.bonusBucksService.DownloadBatchFile(batchFileType, this.selectedCompany).subscribe(response => {
      this.blockUI.stop();
      const a = document.createElement('a');
      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = fileName + new Date() + '.csv';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
  //#endregion

  //#region - API Methods
  private retrieveReportAvailability(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.reportService.reportAvailabilityRetrieve().subscribe(
        response => {
          const data: IReportAvailability[] = response;

          this.reportAvailability = data;

          resolve(true);
        }
      );
    });

    return promise;
  }

  private retrieveCoverageSales() {
    this.coverageSales = this.reportService.retrieveCoverageSales(1);
  }
  //#endregion
}
