import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/*-- Components - Dynamic --*/
import { AddNoteComponent } from './add-note/add-note.component';
import { FileFormComponent } from '../../../../eazeetrak/components/file-form/file-form.component';

/*-- Components - Static --*/
import { AppComponent } from '../../../../../app.component';

/*-- Enums --*/
import { FormState } from '../../../../../enums/form-state.enum';
import { Sidebar } from '../../../../../enums/sidebar.enum';

/*-- Interfaces --*/
import { IDealer } from '../../../interfaces/dealer.interface';
import { IEazeeTrakFile, IEazeeTrakReasonGroup } from '../../../interfaces/eazeetrak.interface';
import { INote, INoteGroup } from '../.././../interfaces/note.interface';

/*-- Services --*/
import { DealersService } from '../../../services/dealers.service';
import { DealersSharedService } from '../../../services/dealers-shared.service';
import { DynamicComponentService } from '../../../../../services/dynamic-component.service';

/*-- Third Party --*/
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {FilesSharedService} from "../services/files-shared.service";
import { DatePipe } from '@angular/common';
import { faFilter, faPlus, faThumbtack } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class NotesComponent implements OnChanges, OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() dealer: IDealer;

  private currentDealer: IDealer;
  private files: IEazeeTrakFile[] = [];
  private filesEndDate = new FormControl();
  private filesFilter = new FormControl();
  private filesStartDate = new FormControl();
  private filteredPinnedFiles: IEazeeTrakFile[];
  private filteredStandardFiles: IEazeeTrakFile[];
  private filteredPinnedNotes: INote[] = [];
  private filteredStandardNotes: INote[] = [];
  private notes: INote[];
  private notesEndDate = new FormControl();
  private notesFilter = new FormControl();
  private noteGroupId = new FormControl();
  private noteGroups: INoteGroup[];
  private notesStartDate = new FormControl();
  private reasonGroups: IEazeeTrakReasonGroup[];
  private reasonGroupId = new FormControl();
  public IsDealerLoaded:boolean = false;
  public notesSection: string;
  public faFilter = faFilter;
  public faPlus = faPlus;
  public faThumbtack = faThumbtack;

  constructor(
    private dealersSharedService: DealersSharedService,
    private dealerService: DealersService,
    private dynamicComponentService: DynamicComponentService,
    private appComponent: AppComponent,
    private filesSharedService: FilesSharedService, private datePipe : DatePipe
  ) {
    // this.dealersSharedService.params.subscribe(params => {
    //   this.notesSection = params['notesSection'];
    // });

    if (!this.notesSection) {
      // this.dealersSharedService.updateUrlParam('notesSection', 'notes');

      this.notesSection = 'notes';
    }

    this.filesSharedService.files.subscribe(files => {
      if (!files)
      {
        return;
      }

      this.files = files;
      this.filterFiles();
    });
  }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    const dealer: SimpleChange = changes.dealer;

    if (dealer && dealer.currentValue) {
      this.currentDealer = dealer.currentValue;
      this.initializeDatepickers();

      this.blockUI.start();

      this.filesSharedService.retrieveEazeeTrakFiles(this.dealer.vendorId);

      this.retrieveNotes().then(
        () => {
          this.filterNotes();

          this.blockUI.stop();
        }
      );
    }

    this.IsDealerLoaded = this.currentDealer === undefined ? false : true;
  }

  ngOnInit(): void {
    this.filesFilter.setValue('');
    this.noteGroupId.setValue(0);
    this.notesFilter.setValue('');
    this.notesSection = 'notes';
    this.reasonGroupId.setValue(0);

    this.retrieveNoteGroups();
    this.retrieveReasonGroups();
  }
  //#endregion

  //#region - Events
  onAddNoteClick(): void {
    
    const sidebar = Sidebar.Right;

    let instance: any;

    this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

    instance = this.dynamicComponentService.addDynamicComponent(AddNoteComponent);

    instance.dealerId = this.currentDealer.vendorId;
    instance.noteGroups = this.noteGroups;
    instance.title = 'Add Note';

    instance.addNoteResponse.subscribe(
      response => {
        if (response) {
          this.blockUI.start();

          this.retrieveNotes(true).then(
            () => {
              this.filterNotes();

              this.blockUI.stop();
            }
          );

          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        } else {
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        }
      }
    );

    this.appComponent.sidebarRightOpened = true;
    
  }

  onButtonGroupClick(button: string): void {
    // this.dealersSharedService.updateUrlParam('notesSection', button);

    this.notesSection = button;
  }

  onEazeeTrakFileClick(eazeeTrakFileId: number): void {
    const sidebar = Sidebar.Right;

    let instance: any;

    this.dynamicComponentService.setRootViewContainerRef(this.appComponent.dynamicComponentRight);

    instance = this.dynamicComponentService.addDynamicComponent(FileFormComponent);

    instance.eazeeTrakFileId = eazeeTrakFileId;
    instance.formState = FormState.Modify;
    instance.sidebar = sidebar;

    instance.modalResponse.subscribe(
      response => {
        if (response) {

          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);

        } else {
          this.dynamicComponentService.closeAndClear(this.appComponent, sidebar);
        }
      });

    this.appComponent.sidebarRightOpened = true;
  }

  onFilesEndDateChange(): void {
    this.filterFiles();
  }

  onFilesFilterChange(): void {
    this.filterFiles();
  }

  onFilesFilterKeydown(): void {
    this.filesFilter.setValue('');
    this.filterFiles();
  }

  onFilesStartDateChange(): void {
    this.filterFiles();
  }

  onNotesEndDateChange(): void {
    this.filterNotes();
  }

  onNoteGroupChange(): void {
    this.filterNotes();
  }

  onNotesFilterChange(): void {
    this.filterNotes();
  }

  onNotesFilterKeydown(): void {
    this.notesFilter.setValue('');
    this.filterNotes();
  }

  onNotesStartDateChange(): void {
    this.filterNotes();
  }

  onReasonGroupChange(): void {
    this.filterFiles();
  }
  //#endregion

  //#region Private Methods
  private filterFiles(): void {
    const reasonGroupId: number = this.reasonGroupId.value;
    const searchText: string = this.filesFilter.value;

    let filteredPinnedFiles: IEazeeTrakFile[] = this.files.filter(item => item.pinDays &&
      (moment().add(item.pinDays, 'days').toDate() >= moment().toDate()));
    let filteredStandardFiles: IEazeeTrakFile[] = this.files.filter(item => !item.pinDays ||
      (moment().add(item.pinDays, 'days') <= moment().endOf('day')));

    // filter by dates
    filteredPinnedFiles = filteredPinnedFiles.filter(item => (moment(item.createdDate) >= this.filesStartDate.value &&
      moment(item.createdDate) <= this.filesEndDate.value));
    filteredStandardFiles = filteredStandardFiles.filter(item => (moment(item.createdDate) >= this.filesStartDate.value &&
      moment(item.createdDate) <= moment(this.filesEndDate.value).endOf('day')));

    // filter by file group
    filteredPinnedFiles = reasonGroupId === 0 ?
      filteredPinnedFiles :
      filteredPinnedFiles.filter(item => item.eazeeTrakReasonGroupId === this.reasonGroupId.value);
    filteredStandardFiles = reasonGroupId === 0 ?
      filteredStandardFiles :
      filteredStandardFiles.filter(item => item.eazeeTrakReasonGroupId === this.reasonGroupId.value);

    // filter by text
    filteredPinnedFiles = searchText === '' ?
      filteredPinnedFiles :
      filteredPinnedFiles.filter(item => item.reason.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1);
    filteredStandardFiles = searchText === '' ?
      filteredStandardFiles :
      filteredStandardFiles.filter(item => item.reason.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1);

    this.filteredPinnedFiles = filteredPinnedFiles;
    this.filteredStandardFiles = filteredStandardFiles;
  }

  private filterNotes(): void {
    const noteGroupId: number = this.noteGroupId.value;
    const searchText: string = this.notesFilter.value;

    let filteredPinnedNotes: INote[] = this.notes.filter(item => item.isPinned);
    let filteredStandardNotes: INote[] = this.notes.filter(item => !item.isPinned);

    // filter by dates
    filteredPinnedNotes = filteredPinnedNotes.filter(item => (moment(item.createdDate).startOf('day') >= moment(this.notesStartDate.value).startOf('day') &&
      moment(item.createdDate).startOf('day') <= moment(this.notesEndDate.value).startOf('day')));
    filteredStandardNotes = filteredStandardNotes.filter(item => (moment(item.createdDate).startOf('day') >= moment(this.notesStartDate.value).startOf('day') &&
      moment(item.createdDate).startOf('day') <= moment(this.notesEndDate.value).startOf('day')));

    // filter by note group
    filteredPinnedNotes = noteGroupId === 0 ?
      filteredPinnedNotes :
      filteredPinnedNotes.filter(item => item.noteGroupId === this.noteGroupId.value);
    filteredStandardNotes = noteGroupId === 0 ?
      filteredStandardNotes :
      filteredStandardNotes.filter(item => item.noteGroupId === this.noteGroupId.value);

    // filter by text
    filteredPinnedNotes = searchText === '' ?
      filteredPinnedNotes :
      filteredPinnedNotes.filter(item => item.comment.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1);
    filteredStandardNotes = searchText === '' ?
      filteredStandardNotes :
      filteredStandardNotes.filter(item => item.comment.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1);

    this.filteredPinnedNotes = filteredPinnedNotes;
    this.filteredStandardNotes = filteredStandardNotes;
  }

  private initializeDatepickers(): void {
    // Start Date - dealer's registration date
    this.filesStartDate.setValue(moment(this.currentDealer.createdDate));
    this.notesStartDate.setValue(moment(this.currentDealer.createdDate));

    // End Date - current date
    this.filesEndDate.setValue(moment());
    this.notesEndDate.setValue(moment());
  }
  //#endregion

  //#region - API Methods

  private retrieveNotes(force: boolean = false): Promise<{}> {
     const promise = new Promise((resolve) => {
      this.dealerService.noteRetrieve(this.dealer.vendorId,this.datePipe.transform(this.notesStartDate.value, 'yyyy-MM-dd') ,this.datePipe.transform(this.notesEndDate.value, 'yyyy-MM-dd'), force).subscribe(
        response => {
          const data: INote[] = response;

          this.notes = data;

          resolve(true);
        }
      );
    });

     return promise;
  }

  private retrieveNoteGroups(): void {
    this.blockUI.start();

    this.dealerService.noteGroupLookup().subscribe(
      response => {
        const data: INoteGroup[] = response;

        this.noteGroups = data;

        this.blockUI.stop();
      }
    );
  }

  private retrieveReasonGroups(): void {
    this.blockUI.start();

    this.dealerService.eazeeTrakReasonGroupLookup().subscribe(
      response => {
        const data: IEazeeTrakReasonGroup[] = response;

        this.reasonGroups = data;

        this.blockUI.stop();
      });
  }
  //#endregion
}
