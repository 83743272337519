import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';

/*-- Componenents - Static --*/
import { EazeetrakComponent } from './components/eazeetrak.component';

/*-- Modules --*/
import { MaterialModule } from '../../modules/material.module';

/*-- Pipes --*/
import { ArrayFilterPipe } from '../../pipes/array-filter.pipe';

/*-- Third Party --*/
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {DataTablesModule} from "angular-datatables";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
    LayoutModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DataTablesModule
  ],
  declarations: [
    EazeetrakComponent,
    /*-- Pipes --*/
    ArrayFilterPipe
  ],
  providers: [ArrayFilterPipe]
})
export class EazeetrakModule { }
