import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { forkJoin } from 'rxjs';

/*-- Enums --*/
import { Role } from '../../../../enums/role.enum';

/*-- Interfaces --*/
import { IEazeeTrakReasonGroup } from '../../interfaces/eazeetrak.interface';
import { IReport, IReportParameter } from '../../interfaces/reports.interface';
import { IUser } from '../../interfaces/user.interface';
import { IDealer } from '../../../dealers/interfaces/dealer.interface';
import { IAutoCompleteItem } from '../../../../interfaces/autocomplete-item.interface';

/*-- Services --*/
import { AccountService } from '../../../account/services/account.service';
import { ReportsService } from '../../services/reports.service';
import { DealersService } from '../../../dealers/services/dealers.service';
import { BonusBucksService } from '../../../dealers/services/bonus-bucks.service';

/*-- Third Party --*/
import * as _ from 'underscore';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { StringifyOptions } from 'querystring';
import { resolve } from 'url';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { debounceTime } from 'rxjs/operators';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @Output() modalResponse: EventEmitter<string> = new EventEmitter<string>();

  public allAgentsSelected = new FormControl();
  public allFileStatusSelected = new FormControl();
  public allReasonGroupsSelected = new FormControl();
  public allRegionsSelected = new FormControl();

  private dealerAccountManagers: IUser[];
  private directory: string;
  private regionalSalesManagers: IUser[];
  private report: IReport;
  private reportParameters: IReportParameter[];
  private reportId: number;
  private reportName: string;
  private selectedAgents: any[];
  private eazeeTrakReasonGroups: IEazeeTrakReasonGroup[];

  public agents: any[] = [];
  public agentsLabel: string;
  public fileStatus: any[] = [];
  public fileStatusLabel: string;
  public firstDate2Label: string;
  public firstDateLabel: string;
  public firstDateLabel2: string;
  public form: FormGroup;
  public isSubmitted: boolean;
  public lastDate2Label: string;
  public lastDateLabel: string;
  public lastDateLabel2: string;
  public loggedInUserId: string;
  public reasonGroups: any[] = [];
  public reasonsLabel: string;
  public regions: any[] = [];
  public regionsLabel: string;
  public salesClaims: any[];
  public salesClaimsLabel: string;
  public showAgents: boolean;
  public showFileStatus: boolean;
  public showFirstDate: boolean;
  public showFirstDate2: boolean;
  public showLastDate: boolean;
  public showLastDate2: boolean;
  public showReasons: boolean;
  public showRegions: boolean;
  public showSalesClaims: boolean;
  public showUser: boolean;
  public showVehicleCategories: boolean;
  public title: string;
  public userId: string;
  public userIdLabel: string;
  public users: any[] = [];
  public vehicleCategories: any[];
  public vehicleCategoriesLabel: string;

  public isLoading: boolean = false;
  public dealerAutoCompleteText: string;
  public filteredDealers: IDealer[] = [];
  public selectedDealer: IDealer;
  public selectedDealerId: number;
  public dealerLabel: string;
  public showDealer: boolean;


  public selectedUser: any;
  public userLabel: string;
  public showUsers: boolean;
  public companies: any[] = [];
  public selectedCompany: any;
  public companyLabel: string;
  public showCompany: boolean;
  public ShowInfoComplete: boolean;
  public infoCompleteLabel: string;
  public infoComplete: any[];
  public faCheck = faCheck;
  public faTimes = faTimes;

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private reportsService: ReportsService,
    private bonusBucksService: BonusBucksService,
    private dealersService: DealersService
  ) { }

  //#region - Lifecycle
  ngOnInit(): void {
    this.form = this.formBuilder.group({});

    forkJoin(
      this.retrieveReport(),
      this.retrieveReportParameters().then(
        () => {
          this.getParameterOptions();
        }
      )
    )
      .subscribe(
        () => {
          this.initializeParameterFields();
        }
      );
  }
  //#endregion

  //#region - Getters
  get f() { return this.form.controls; }
  //#endregion

  //#region - Events
  onCloseClick(): void {
    this.modalResponse.emit();
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.form.valid) {
      this.createMetrics().then(
        () => {
          const baseUrl = 'WebForms/ReportViewer.aspx?';
          const dateFormat = 'YYYY-MM-DD';

          let params = '';
          let url = '';
          let selectedOptions: any[];

          url += baseUrl;
          url += 'd=' + this.directory.replace(/ /, '');
          url += '&r=' + this.reportName;

          if (this.reportParameters.length) {
            params += '&p=';

            this.reportParameters.forEach((parameter, index) => {
              if (index > 0) {
                params += '^';
              }

              switch (parameter.name) {
                case 'AgentIds':
                  selectedOptions = this.form.get('agent').value;

                  params += parameter.name + '|' + this.getSelectedValues(this.agents, selectedOptions, 'id', false);

                  break;
                case 'AgentList':
                  selectedOptions = this.form.get('agent').value;

                  params += parameter.name + '|' + this.getSelectedValues(this.agents, selectedOptions, 'label', false);

                  break;
                case 'FileStatus':
                  selectedOptions = this.form.get('fileStatus').value;

                  params += parameter.name + '|' + this.getSelectedValues(this.fileStatus, selectedOptions, 'id', false);

                  break;
                case 'FirstDate':
                  params += parameter.name + '|' + moment.utc(this.form.get('firstDate').value).format(dateFormat);

                  break;
                case 'LastDate':
                  params += parameter.name + '|' + moment.utc(this.form.get('lastDate').value).format(dateFormat);

                  break;
                case 'FirstDate2':
                  params += parameter.name + '|' + moment.utc(this.form.get('firstDate2').value).format(dateFormat);

                  break;
                case 'LastDate2':
                  params += parameter.name + '|' + moment.utc(this.form.get('lastDate2').value).format(dateFormat);

                  break;
                case 'LoggedInUserId':
                  params += parameter.name + '|' + this.loggedInUserId;

                  break;
                case 'ReasonIds':
                  selectedOptions = this.form.get('reasonGroup').value;

                  params += parameter.name + '|' + this.getSelectedValues(this.reasonGroups, selectedOptions, 'id', false);

                  break;
                case 'ReasonList':
                  selectedOptions = this.form.get('reasonGroup').value;

                  params += parameter.name + '|' + this.getSelectedValues(this.reasonGroups, selectedOptions, 'label', true);

                  break;
                case 'RegionIds':
                  selectedOptions = this.form.get('region').value;

                  params += parameter.name + '|' + this.getSelectedValues(this.regions, selectedOptions, 'value', false);

                  break;
                case 'RegionList':
                  selectedOptions = this.form.get('region').value;

                  params += parameter.name + '|' + this.getSelectedValues(this.regions, selectedOptions, 'label', true);

                  break;
                case 'SalesClaimsId':
                  params += parameter.name + '|' + this.form.get('salesClaims').value;

                  break;
                case 'UserId':
                  params += parameter.name + '|' + this.form.get('user').value;

                  break;
                case 'VehicleCategory':
                  params += parameter.name + '|' + this.form.get('vehicleCategory').value;

                  break;
                case 'InfoComplete':
                  if (this.form.get('infoComplete').value === null) {
                    params += parameter.name + '|';
                  } else {
                    params += parameter.name + '|' + this.form.get('infoComplete').value;
                  }

                  break;
                case 'VendorId':
                case 'Dealer':
                  
                  params += (parameter.name + '|' + (this.selectedDealer == undefined || this.selectedDealer == null ? 0 : this.selectedDealer.vendorId));

                  break;
              }
            });
          }

          url += params;

          this.modalResponse.emit(url);
        }
      );
    }
  }

  onToggleAllAgents(): void {
    if (this.allAgentsSelected.value) {
      this.form.controls.agent.patchValue([...this.agents.map(item => item.id), 0]);
    } else {
      this.form.controls.agent.patchValue([]);
    }
  }

  onToggleAllFileStatus(): void {
    if (this.allFileStatusSelected.value) {
      this.form.controls.fileStatus.patchValue([...this.fileStatus.map(item => item.id), 0]);
    } else {
      this.form.controls.fileStatus.patchValue([]);
    }
  }

  onToggleAllReasonGroups(): void {
    if (this.allReasonGroupsSelected.value) {
      this.form.controls.reasonGroup.patchValue([...this.reasonGroups.map(item => item.id), 0]);
    } else {
      this.form.controls.reasonGroup.patchValue([]);
    }
  }

  onToggleAllRegions(): void {
    if (this.allRegionsSelected.value) {
      this.form.controls.region.patchValue([...this.regions.map(item => item.id), 0]);
    } else {
      this.form.controls.region.patchValue([]);
    }
  }
  //#endregion

  //#region - Private Methods
  private getFileStatus(): void {
    this.fileStatus = [
      { id: 0, label: 'Open' },
      { id: 1, label: 'Closed' }
    ];
  }

  private getLoggedInUser() {
    this.loggedInUserId = '[LoggedInUserId]';
  }

  private getParameterOptions(): void {
    this.reportParameters.forEach(
      parameter => {
        switch (parameter.name) {
          case 'AgentIds':
          case 'AgentList':
            this.retrieveDealerAccountManagers().then(
              () => {
                this.agents = []; // TODO

                this.dealerAccountManagers.forEach(
                  item => {
                    this.agents.push({
                      id: item.id,
                      label: item.name
                    });
                  }
                );
              }
            );

            break;
          case 'FileStatus':
            this.getFileStatus();

            break;
          case 'LoggedInUserId':
            this.getLoggedInUser();

            break;
          case 'ReasonIds':
            this.retrieveReasonGroups().then(
              () => {
                this.eazeeTrakReasonGroups.forEach(
                  item => {
                    this.reasonGroups.push(
                      {
                        id: item.eazeeTrakReasonGroupId,
                        label: item.label
                      }
                    );
                  }
                );
              }
            );

            break;
          case 'RegionIds':
            this.getRegions();

            break;
          case 'SalesClaimsId':
            this.getSalesClaims();

            break;
          case 'UserId':
            this.getUsers();

            break;
          case 'VehicleCategory':
            this.getVehicleCategories();

            break;
          case 'InfoComplete':
            this.getInfoComplete();

            break;
          case 'VendorId':
          // case 'Company':
          //   this.getCompanies();
          //   break;
        }
      }
    );
  }

  filterDealers(dealerText: string) {

    debounceTime(750);

    var loadingThis = this;
    let loadingTimer = setTimeout(() => {
      loadingThis.isLoading = true;
    }, 250);

    this.dealerAutoCompleteText = dealerText;

    if (dealerText.length > 0) {

      this.dealersService.dealerLookupByAlias(dealerText)
        .subscribe(users => {
          if (users) {
            this.loadDealer(loadingTimer, users);
          }
        });
    }
    else {
      this.loadDealer(loadingTimer);
    }
  }

  loadDealer(loadingTimer: any, dealer = []) {
    //if you filter when more than 0 char than a visual bug can occur if the user deletes all char.
    //The lookup will send request on the last char, then on 0 char the list will clear, then the service
    //responds and populates the list even when the input is empty
    //therfore only populate list if adjuster text is still grater than 0
    if (this.dealerAutoCompleteText.length > 0) {
      this.filteredDealers = dealer;
    }
    else {
      this.filteredDealers = [];
    }

    this.isLoading = false;
    clearTimeout(loadingTimer)
  }

  onDealerSelected(selected, event) {
    //ignores deselect trigger of previous selected
    if (event.isUserInput) {
      if (selected != null) {

        this.dealersService.dealerRetrieve(selected.vendorId).subscribe(data => {
          this.selectedDealer = data;
          this.selectedDealerId = data.vendorId;
        })
      } else {
        this.selectedDealer = null;
        this.selectedDealerId = null;

        this.form.get('selectedDealer').setErrors({ required: true });
      }
    }
  }

  dealerAutoCompleteDisplay(value: IDealer) {
    if (value && value.vendorId) {
      return `[${value.vendorId}] ${value.alias}`
    }
  }


  public updateDealer(e) {
    this.bonusBucksService.RetrieveDistributionUsers(this.selectedDealer.vendorId).subscribe(response => {
      this.users = response;
    });
  }

  private getCompanies() {
    this.companies = [
      { id: 1, label: 'Global Warranty Corp.' },
      { id: 2, label: 'Global Warranty (Ontario) Corp.' },
      { id: 3, label: 'Global Warranty (Mid-West) Corp.' },
      { id: 4, label: 'Global Warranty (MI) Corp.' },
      { id: 5, label: 'Global Warranty (West Cost) Corp.' },
      { id: 6, label: 'Glboal Warranty Management' },
      { id: 7, label: 'Global Warranty Tire and Rim Corp.' },
      { id: 8, label: 'Global Warranty Gap Corp.' }
    ];
  }

  private getRegions(): void {
    this.regions = [
      { id: 1, label: 'Atlantic Canada Region', value: '14080,14085,14089,14096' },
      { id: 2, label: 'Central Canada Region', value: '14093' },
      { id: 3, label: 'Western Canada Region', value: '14051,14054,14070,14099' }
    ];
  }

  private getSalesClaims(): void {
    this.salesClaims = [
      { id: 1, label: 'All' },
      { id: 2, label: 'Sales' },
      { id: 3, label: 'Claims' }
    ];
  }

  private getSelectedValues(originalObj, obj, key, sendAll) {
    const arr = [];

    if (originalObj) {
      if ((originalObj.length === (obj.length - 1)) && sendAll) {
        return 'All';
      } else {
        originalObj.forEach(value => {
          if (obj.indexOf(value['id']) > -1) {
            arr.push(value[key]);
          }
        });

        return arr.toString().replace("'", "''");
      }
    } else {
      obj.forEach(value => {
        arr.push(value[key]);
      });

      return arr.toString().replace("'", "''");
    }
  }

  private getUsers(): void {
    if (this.accountService.isInRole(Role.RegionalSalesManager)) {
      this.retrieveDealerAccountManagers().then(
        () => {
          this.loadUsers();
        }
      );
    } else if (this.accountService.isInRole(Role.DealerAccountManager)) {
      this.userId = '[UserId]';
    } else {
      forkJoin(
        this.retrieveDealerAccountManagers(),
        this.retrieveRegionalSalesManagers()
      )
        .subscribe(
          () => {
            this.loadUsers();
          }
        );
    }
  }

  private getVehicleCategories() {
    this.vehicleCategories = [
      { id: 'All', label: 'All' },
      { id: 'Automotive', label: 'Automotive' },
      { id: 'Tire & Rim', label: 'Tire & Rim' },
      { id: 'RV', label: 'RV' }
    ];
  }

  private getInfoComplete() {
    this.infoComplete = [
      { id: null, label: 'All' },
      { id: false, label: 'Incomplete' },
      { id: true, label: 'Complete' }
    ];
  }

  private initializeParameterFields(): void {
    this.reportParameters.forEach(
      parameter => {
        switch (parameter.name) {
          case 'AgentIds':
            this.form.addControl('agent', new FormControl(null, Validators.required));

            this.agentsLabel = parameter.label;
            this.showAgents = true;

            break;
          case 'AgentList':
            this.form.addControl('agent', new FormControl(null, Validators.required));

            this.agentsLabel = parameter.label ? parameter.label : this.agentsLabel;
            this.showAgents = true;

            break;
          case 'FileStatus':
            this.form.addControl('fileStatus', new FormControl(null, Validators.required));

            this.fileStatusLabel = parameter.label;
            this.showFileStatus = true;

            break;
          case 'FirstDate':
            this.form.addControl('firstDate', new FormControl(null, Validators.required));

            this.firstDateLabel = parameter.label;
            this.showFirstDate = true;

            break;
          case 'LastDate':
            this.form.addControl('lastDate', new FormControl(null, Validators.required));

            this.lastDateLabel = parameter.label;
            this.showLastDate = true;

            break;
          case 'FirstDate2':
            this.form.addControl('firstDate2', new FormControl(null, Validators.required));

            this.firstDate2Label = parameter.label;
            this.showFirstDate2 = true;

            break;
          case 'LastDate2':
            this.form.addControl('lastDate2', new FormControl(null, Validators.required));

            this.lastDate2Label = parameter.label;
            this.showLastDate2 = true;

            break;
          case 'ReasonIds':
            this.form.addControl('reasonGroup', new FormControl(null, Validators.required));

            this.reasonsLabel = parameter.label;
            this.showReasons = true;

            break;
          case 'RegionIds':
            this.form.addControl('region', new FormControl(null, Validators.required));

            this.regionsLabel = parameter.label;
            this.showRegions = true;

            break;
          case 'SalesClaimsId':
            this.form.addControl('salesClaims', new FormControl(null, Validators.required));

            this.salesClaimsLabel = parameter.label;
            this.showSalesClaims = true;

            break;
          case 'UserId':
            this.form.addControl('user', new FormControl(null, Validators.required));

            this.userIdLabel = parameter.label;
            this.showUsers = true;

            break;
          case 'VehicleCategory':
            this.form.addControl('vehicleCategory', new FormControl(null, Validators.required));

            this.vehicleCategoriesLabel = parameter.label;
            this.showVehicleCategories = true;

            break;
          case 'InfoComplete':
            this.form.addControl('infoComplete', new FormControl(null, Validators.nullValidator));

            this.infoCompleteLabel = parameter.label;
            this.ShowInfoComplete = true;

            break;
          case 'VendorId':
          case 'Dealer':
            this.form.addControl('selectedDealer', new FormControl(null, Validators.nullValidator));

            this.dealerLabel = parameter.label;
            this.showDealer = true;
            break;
            // case 'User':
            //   this.form.addControl('users', new FormControl(null, Validators.required));


            this.dealerLabel = parameter.label;
            this.showDealer = true;
            break;
          // case 'Company':
          //   this.form.addControl('company', new FormControl(null, Validators.required));

          //   this.companyLabel = parameter.label;
          //   this.showCompany = true;

          //   break;
        }
      }
    );
  }

  private loadUsers() {
    // merge agents and regionals, then remove duplicates and sort
    const users = _.sortBy(_.uniq(_.union(this.dealerAccountManagers, this.regionalSalesManagers),
      function (user) { return user.name; }), 'name');

    users.splice(0, 0, {
      id: '[UserId]',
      name: 'View All'
    });

    this.users = users;

    // set initial value
    if (!this.userId) {
      this.userId = '[UserId]';
    }
  }
  //#endregion

  //#region - API Methods
  private createMetrics(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.reportsService.metricsCreate(this.reportId).subscribe(
        () => {
          resolve(true);
        }
      );
    });

    return promise;
  }

  private retrieveDealerAccountManagers(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.reportsService.userRetrieveByRole(Role.DealerAccountManager, true).subscribe(
        response => {
          const data: IUser[] = response;

          this.dealerAccountManagers = data;

          resolve(true);
        }
      );
    });

    return promise;
  }

  private retrieveReasonGroups(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.reportsService.eazeeTrakReasonGroupLookup().subscribe(
        response => {
          const data: IEazeeTrakReasonGroup[] = response;

          this.eazeeTrakReasonGroups = data;

          resolve(true);
        });
    });

    return promise;
  }

  private retrieveRegionalSalesManagers(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.reportsService.userRetrieveByRole(Role.RegionalSalesManager, true).subscribe(
        response => {
          const data: IUser[] = response;

          this.regionalSalesManagers = data;

          resolve(true);
        }
      );
    });

    return promise;
  }

  private retrieveReport(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.reportsService.reportRetrieve(this.reportId).subscribe(
        response => {
          const data: IReport = response;

          this.report = data;

          resolve(true);
        }
      );
    });

    return promise;
  }

  private retrieveReportParameters(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.reportsService.reportParametersRetrieve(this.reportId).subscribe(
        response => {
          const data: IReportParameter[] = response;

          this.reportParameters = data;

          resolve(true);
        }
      );
    });

    return promise;
  }
  //#endregion
}
