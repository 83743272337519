import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ClaimsService } from "../../services/claims.service";
import { ComplaintAddDialogComponent } from "../complaint-add-dialog/complaint-add-dialog.component";
import { DocumentAddDialogComponent } from "../document-add-dialog/document-add-dialog.component";
import { NoteAddDialogComponent } from "../note-add-dialog/note-add-dialog.component";
import { IClaim } from "../../interfaces/claim";
import { DatePipe } from "@angular/common";
import { ILoginUser } from "../../interfaces/loginuser.interface";
import { IClaimsNotes } from "../../interfaces/claims-notes.interface";
import { ClaimsDocumentService } from "../../services/claims-document.service";
import { IDocument } from "../../interfaces/document";
import { RepairCentreSearchDialogComponent } from "../repair-centre/repair-centre-search-dialog/repair-centre-search-dialog.component";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { LoginUserService } from "../../services/loginuser.service";
import { ClaimsNotesService } from "../../services/claims-notes.service";
import { ActivatedRoute } from "@angular/router";
import { PartDialogComponent } from "../part-dialog/part-dialog.component";
import { Part } from "../../models/part-model";
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { CellType } from "src-private/app/shared/table-adapter/cell-builder/cell-type";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import * as FileSaver from "file-saver";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Subject } from "rxjs";
import { ComplaintsService } from "../../services/complaints.service";
import { IComplaint } from "../../interfaces/complaint.interface";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { NoteGroupService } from "../../services/notegroup.service";
import { takeUntil } from "rxjs/operators";
import { ClaimEditComponent } from "../claims-list/claim-edit/claim-edit.component";
import { NotificationService } from "src-private/app/services/notification.service";
import { Notification } from 'src-private/app/interfaces/notification.interface';
import { PartService } from "../../services/part-service";
import { ClaimTransaction } from "../../models/claim-transaction";
import { ClaimTransactionService } from "../../services/claim-transaction-service";
import { FrameworkComponent } from "src-private/app/shared/framework/framework.component";
import { ClaimsLockService } from "../../services/claims-lock.service";
import { IClaimLock } from "../../interfaces/claim-lock.interface";
import { ClaimLockComponent } from "../claims-list/claim-lock/claim-lock.component";
import { NotificationType } from "src-private/app/enums/notification-type.enum";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"],
})
export class DetailComponent
  extends FrameworkComponent
  implements OnInit, AfterViewInit {
  private claim$: BehaviorSubject<IClaim>;
  private claimsId: number;
  private complaint = {} as IComplaint;
  private updatedClaim = {} as IClaim;
  private userId: string;
  private NoteGroupId: number;
  public claimForm: FormGroup;
  public complaintForm: FormGroup;
  public faEdit = faEdit;
  public faPlus = faPlus;
  private claimsNotes: IClaimsNotes[];
  public users: ILoginUser[];
  public partData: ClaimTransaction[];

  private ngUnsubscribe: Subject<any> = new Subject();

  @ViewChild("partsTable")
  partsTable: TableAdapterComponent<Part>;
  public partsTableColumns: CellBuilder[] = [
    new CellBuilder("RC Name", "repairCentreName", CellType.text),
    new CellBuilder("Part Group", "partGroupName", CellType.number),
    new CellBuilder("Part Description", "partDescription", CellType.text),
    new CellBuilder("Quantity", "quantity", CellType.number),
    new CellBuilder("Our $", "ourPrice", CellType.currency),
    new CellBuilder("RC $", "repairCentrePrice", CellType.currency),
    new CellBuilder("Paid $", "paidPrice", CellType.currency),
    new CellBuilder("SK Labour", "shopKeyLabour", CellType.currency),
    new CellBuilder("RC Labour", "repairCentreLabour", CellType.currency),
    new CellBuilder("Paid Labour", "paidLabour", CellType.currency),
    new CellBuilder("Other", "other", CellType.currency),
    new CellBuilder("Deductible", "deductible", CellType.currency),
    new CellBuilder("Process", "process", CellType.text),
    new CellBuilder("GoodWill", "goodwill", CellType.text),
    new CellBuilder("Used Part", "used", CellType.checkbox),
  ];

  @ViewChild("claimsDocumentsTable")
  claimsDocumentsTable: TableAdapterComponent<IDocument>;
  public claimsDocumentsTableColumns: CellBuilder[] = [
    new CellBuilder("Filename", "fileName", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Created Date", "createdDate", CellType.date),
    new CellBuilder("Action", "contextmenu", CellType.contextmenu),
  ];

  @ViewChild("claimsNotesTable")
  claimsNotesTable: TableAdapterComponent<IClaimsNotes>;
  public claimsNotesTableColumns: CellBuilder[] = [
    new CellBuilder("Adjustor", "adjusterName", CellType.text),
    new CellBuilder("Created Date", "createdDate", CellType.date),
    new CellBuilder("Notes", "note", CellType.text),
  ];

  @ViewChild("complaintsTable")
  complaintsTable: TableAdapterComponent<IComplaint>;
  public complaintsTableColumns: CellBuilder[] = [
    new CellBuilder("Adjustor", "adjusterName", CellType.text),
    new CellBuilder("Created Date", "createdDate", CellType.date),
    new CellBuilder("Complaint", "comment", CellType.text),
  ];

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private claimsService: ClaimsService,
    private datePipe: DatePipe,
    private loginUserService: LoginUserService,
    private claimsNotesService: ClaimsNotesService,
    private complaintsService: ComplaintsService,
    private claimsDocumentService: ClaimsDocumentService,
    private accountService: AccountService,
    private noteGroupService: NoteGroupService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private claimTransactionService: ClaimTransactionService,
    private notificationService: NotificationService,
    private claimsLockService: ClaimsLockService
  ) {
    super();
  }

  ngOnInit() {
    this.claim$ = new BehaviorSubject<IClaim>({} as IClaim);

    this.userId = this.accountService.getUserId();

    this.claimForm = this.formBuilder.group({
      claimId: [null],
      warrantyId: [null],
      claimsDateEntered: [""],
      claimsDateClosed: [""],
      claimsStatus: [""],
      claimsOwner: [{ value: "", disabled: true }],
      repairCenterName: [""],
      assignedAdjusterName: [""]
    });

    this.complaintForm = this.formBuilder.group({
      comments: [""],
    });

    this.noteGroupService
      .retrieveByName("Complaint")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.NoteGroupId = response;
      });

    this.claimsId = parseInt(
      this.activatedRoute.snapshot.paramMap.get("claimsid")
    );

    this.claimsService
      .retrieve(this.claimsId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((claim) => {
        this.claim$.next(claim);
        this.populateClaimForm(this.claim$.value);
      });
  }

  ngAfterViewInit() {
    //  Load Table Data
    this.refreshClaims();
    this.refreshParts();
    this.refreshNotes();
    this.refreshComplaints();
    this.refreshDocuments();

    super.build(this, "detail-component");
  }

  refreshClaims() {
    this.claimsService
      .retrieve(this.claimsId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((claim) => {
        this.claim$.next(claim);
        this.populateClaimForm(this.claim$.value);
      });
  }

  lockAndEditClaim(unlockAfter) {
    let claimLock = {} as IClaimLock;
    claimLock.claimId = this.claimsId;
    claimLock.lock = true;

    this.claimsLockService
      .lockUnlock(claimLock)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        //claim has been locked

        let dialogRef = this.dialog.open(ClaimEditComponent, {
          width: "300px",
          data: this.claim$.value,
        });
        var oldStatus = this.claim$.value.claimsStatus;
        var oldAssignedAdjuster = this.claim$.value.assignedAdjuster;
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res) => {
            if (res.data == "Refresh") {
              this.refreshClaims();
            } else if (res.data !== "Cancel" && res.data !== undefined) {
              this.updatedClaim = this.claim$.value;
              this.updatedClaim.assignedAdjuster = res.data.assignedAdjuster;
              this.updatedClaim.claimsStatus = res.data.claimsStatus;
              this.updatedClaim.previousClaimsStatus = oldStatus;
              this.updatedClaim.previousAssignedAdjuster = oldAssignedAdjuster;

              this.claimsService
                .claimsUpdate(this.updatedClaim)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                  this.toastr.success("Claim successfully updated. ", 'Update Claim');
                  this.refreshClaims();
                });
            }

            let claimLock = {} as IClaimLock;
            claimLock.claimId = this.claimsId;
            claimLock.lock = false;

            if (unlockAfter) {
              this.claimsLockService.lockUnlock(claimLock).subscribe();
            }
          });
      });
  }

  //#region Claim edit
  //Claim Update Dialog
  editClaim() {
    this.claimsLockService
      .retrieve(this.claimsId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        //if lock does exist create lock
        if (!res) {
          this.claimsLockService
            .add(this.claimsId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.lockAndEditClaim(true);
            });
        }

        //if currently locked
        else if (res.lock) {
          //can't check expiry time before lock result is determined to be not null
          let expireInterval = 15;
          let expiryTime = new Date(res.lockTime);
          expiryTime.setMinutes(expiryTime.getMinutes() + expireInterval);
          let currentTime = new Date();

          if (expiryTime > currentTime) {
            //claim is currently locked
            this.dialog
              .open(ClaimLockComponent, {
                panelClass: "claim-add-dialogs",
                width: "600px",
                data: res,
              })
              .afterClosed()
              .subscribe((data) => {
                if (data.manuallyUnlock) {
                  this.lockAndEditClaim(true);

                  let userInfo = {} as ILoginUser;
                  userInfo.id = this.accountService.getUserId();

                  this.loginUserService.search(userInfo).subscribe(res => {
                    if (res) {
                      let alertCurrentEditor = {} as Notification;

                      alertCurrentEditor.data = JSON.stringify({ "claim": { "id": this.claimsId } })
                      alertCurrentEditor.title = "MANUALLY UNLOCKED - Claims :" + this.claimsId;
                      alertCurrentEditor.description = "Claim ID: " + this.claimsId + " - lock manually overridden by " + this.accountService.getUserName();
                      alertCurrentEditor.typeID = NotificationType.InApp;
                      alertCurrentEditor.userID = res.id;
                      alertCurrentEditor.action = "claim";

                      this.notificationService.createWithNotify(alertCurrentEditor).subscribe(() => { console.log("test") });
                    }
                  });
                }
              });
          }
        } else {
          //claim is currently unlocked
          this.lockAndEditClaim(true);
        }
      });
  }
  //#region Parts

  /**
   * Part Update Dialog
   * Also deletes.
   */
  updatePart(transaction) {
    let dialogRef = this.dialog.open(PartDialogComponent, {
      panelClass: "part-dialog",
      width: "900px",
      autoFocus: false,
      data: {
        transaction: transaction,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      if (dialogRef.componentInstance.updateStatus == "save") {
        this.claimTransactionService
          .update(dialogRef.componentInstance.transaction)
          .subscribe(() => {
            this.toastr.success("Parts successfully updated. ", 'Update Parts');
            this.refreshParts();
          });
      }
      // else if(data == 'refresh') {
      //   this.refreshParts();
      // }
    });
  }

  addPart() {
    var transaction = new ClaimTransaction();
    transaction.claimId = this.claimsId;
    transaction.warrantyId = this.claimForm.controls["warrantyId"].value;
    let dialogRef = this.dialog.open(PartDialogComponent, {
      panelClass: "part-dialog",
      width: "900px",
      autoFocus: false,
      data: {
        transaction: transaction,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      var transaction: ClaimTransaction =
        dialogRef.componentInstance.transaction;
      if (dialogRef.componentInstance.updateStatus == "save") {
        this.claimTransactionService.add(transaction).subscribe((d) => {
          this.toastr.success("Parts successfully added. ", 'Create Parts');
          this.refreshParts();
        });
      }
    });
  }

  /**
   * Refresh Parts
   */
  refreshParts() {
    this.partsTable.refresh(
      this.claimTransactionService.retrieve(this.claimsId)
    );

    this.claimTransactionService
      .retrieve(this.claimsId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.partData = data;
      });
  }

  //#endregion

  //#region documents

  addDocument() {
    this.dialog
      .open(DocumentAddDialogComponent, {
        panelClass: "claim-add-dialogs",
        width: "600px",
        data: {
          originId: this.claimsId,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.data) {
          res.data.originId = this.claimsId;
          res.data.type = "Action";
          this.claimsDocumentService
            .add(res.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.toastr.success("Document successfully added. ", 'Create Document');
              this.refreshDocuments();
            });
        }
      });
  }

  /**
   * Update Document
   * @param data Passes an element, and an action to be taken
   */
  updateDocument(data) {
    if (data.action == "download") {
      this.downloadDocument(this.claimsId, data.element.id);
    } else if (data.action == "remove") {
      this.removeDocument(data.element.id);
    }
  }

  downloadDocument(claimsId: number, documentId: number) {
    this.claimsDocumentService
      .download(claimsId, documentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: HttpResponse<Blob>) => {
        let contentDispositionHeader = res.headers.get("content-disposition");
        // the second part of content-disposition contains the file name
        let fileName = contentDispositionHeader
          .split(";")[1]
          .trim()
          .split("=")[1]
          .replace(/"/g, "");
        FileSaver.saveAs(res.body, fileName);
      });
  }

  removeDocument(documentId: number) {
    //TODO: add alert for delete confirmation
    this.claimsDocumentService
      .remove(documentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toastr.success("Document successfully removed. ", 'Remove Document');
        this.refreshDocuments();
      });
  }

  /**
   * Refresh Documents
   */
  refreshDocuments() {
    this.claimsDocumentsTable.refresh(
      this.claimsDocumentService.retrieveAll(this.claimsId)
    );
  }

  //#endregion

  //#region claimNotes

  addNote() {
    this.dialog
      .open(NoteAddDialogComponent, {
        panelClass: "claim-add-dialogs",
        width: "600px",
      })
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.data) {
          res.data.claimId = this.claimsId;

          this.claimsNotesService
            .add(res.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.toastr.success("Note successfully added.", 'Create Note');
              this.refreshNotes();
            });
        }
      });
  }

  /**
   * Refresh Notes
   */
  refreshNotes() {
    this.claimsNotesTable.refresh(
      this.claimsNotesService.retrieveAll(this.claimsId)
    );
  }

  refreshComplaints() {
    this.complaintsTable.refresh(
      this.complaintsService.retrieve(this.claimsId)
    );
  }

  //#endregion

  //#region Claim Form
  populateClaimForm(claim: IClaim) {
    if (claim) {
      this.claimForm.patchValue({
        claimId: claim.id,
        warrantyId: claim.warrantyId,
        claimsDateEntered: this.datePipe.transform(
          claim.claimsDateEntered,
          "yyyy-MM-dd"
        ),
        claimsDateClosed: this.datePipe.transform(
          claim.claimsDateClosed,
          "yyyy-MM-dd"
        ),
        claimsStatus: claim.claimsStatus,
        claimsOwner: claim.claimsOwner,
        repairCenterName: claim.repairCenterName,
        assignedAdjusterName: claim.assignedAdjusterName
      });
    }
  }

  populateComplaintsForm(notes: IClaimsNotes[]) {
    let result = notes.filter((n) => n.type == "Complaint").join("\r\n\r\n");
    this.complaintForm.patchValue({
      comments: result,
    });
  }

  changeRepairCentre() {
    this.dialog
      .open(RepairCentreSearchDialogComponent, {
        width: "75vw",
        data: this.claim$.value.claimsRepairCenterId,
      })
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.data) {
          this.claim$.value.repairCenterName = res.data.name;
          this.claim$.value.claimsRepairCenterId = res.data.id;

          this.claimsService
            .claimsUpdate(this.claim$.value)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.toastr.success("Repair Centre successfully changed. ", 'Change Repair Centre');
              this.populateClaimForm(this.claim$.value)
            });
        }
      });
  }

  addComplaint() {
    let complaintAddDialogRef = this.dialog.open(ComplaintAddDialogComponent, {
      panelClass: "claim-add-dialogs",
      width: "630px",
      data: this.partData,
    });

    complaintAddDialogRef
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res !== undefined) {
          if (res.data) {
            this.complaint.ClaimId = this.claimsId;
            this.complaint.Comment = res.data.complaint;
            this.complaint.AdjusterId = res.data.assignedAdjuster;
            this.complaint.PartId = res.data.part.partId;
            this.complaint.CustomerId = this.claim$.value.customerId;
            this.complaint.ComplaineeType = res.data.complainee

            this.complaintsService
              .add(this.complaint)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(() => {
                this.toastr.success("Complaint successfully added. ", 'Create Complaint');
                this.refreshComplaints();
              });
          }
        }
      });
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener("window:beforeunload")
  unlockClaim() {
    let claimLock = {} as IClaimLock;
    claimLock.claimId = this.claimsId;
    claimLock.lock = false;
    this.claimsLockService.lockUnlock(claimLock).subscribe(() => {
      return false;
    });
  }

  //#endregion
}
