import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IClaim } from '../interfaces/claim';
import { ClaimsRetrieve } from '../models/claims-retrieve.model';


@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  constructor(private http: HttpClient) { }

  retrieveAll(params: ClaimsRetrieve): Observable<IClaim[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        httpParams = httpParams.append(key, params[key]);
      }
    });
    return this.http.get<IClaim[]>(`${environment.resourceUrl}api/gwc/claims`, { params: httpParams });
  }

  retrieve(claimId: number): Observable<IClaim> {
    return this.http.get<IClaim>(`${environment.resourceUrl}api/gwc/claim`, {
      params: new HttpParams().set('claimId', claimId.toString())
    });
  }

  claimsAdd(claim: IClaim): Observable<number> {
    return this.http.post<number>(environment.resourceUrl + 'api/gwc/claim', claim);
  }

  claimsUpdate(claim: IClaim): Observable<IClaim> {
    return this.http.put<IClaim>(environment.resourceUrl + 'api/gwc/claim', claim);
  }
}
