<form [formGroup]="noteForm" (ngSubmit)="onFormSubmit()" novalidate>

  <div class="sidebar-header">

    <h4 class="sidebar-title">{{title}}</h4>

  </div>
  <div class="sidebar-actions">

    <button class="btn btn-block btn-icon btn-save"
            type="submit">
      <fa-icon [icon]="faCheck" class="text-success icon-left"></fa-icon> 
      Submit
    </button>

    <button class="btn btn-block btn-icon btn-cancel"
            type="button"
            (click)="onCancelClick()">
        <fa-icon [icon]="faTimes" class="text-danger icon-left"></fa-icon> 
      Cancel
    </button>

  </div>
  <div class="sidebar-body">

    <ul class="list-unstyled note-group-pin w-100">
      <li class="note-group">

        <mat-form-field class="w-100">
          <mat-select placeholder="Note Group"
                      required
                      formControlName="noteGroup">
            <mat-option *ngFor="let noteGroup of noteGroups"
                        [value]="noteGroup.noteGroupId">
              {{noteGroup.label}}
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-error *ngIf="isSubmitted && f.noteGroup.errors?.required">Note Group is required</mat-error>
          </mat-error>
        </mat-form-field>

      </li>
      <li class="pin">

        <mat-checkbox color="primary"
                      formControlName="pin">
          Pin
        </mat-checkbox>

      </li>
    </ul>

    <div class="quill-editor-wrapper">
      <quill-editor required
                    #noteCommentEditor
                    formControlName="noteText"
                    [modules]="editorConfig">
      </quill-editor>
    </div>

  </div>
</form>
