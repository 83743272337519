import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MaintenanceService } from '../../services/maintenance.service';
import { MaintenanceRetrieve } from '../../models/maintenance-retrieve.model';
import { IMaintenance } from '../../interfaces/maintenance.interface';
import { IWarranty } from '../../interfaces/warranty.interface';
import { WarrantyService } from '../../services/warranty.service';
import { ActivatedRoute } from '@angular/router';
import { IClaim } from '../../interfaces/claim';
import { ClaimsService } from '../../services/claims.service';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../interfaces/customer.interface';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { TableAdapterComponent } from 'src-private/app/shared/table-adapter/table-adapter.component';
import { IClaimsNotes } from '../../interfaces/claims-notes.interface';
import { CellBuilder } from 'src-private/app/shared/table-adapter/cell-builder/cell-builder';
import { CellType } from 'src-private/app/shared/table-adapter/cell-builder/cell-type';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';
import { FrameworkComponent } from 'src-private/app/shared/framework/framework.component';

class MaintenanceRecord {
  date: Date;
  kms: number;
  term: string;
  maxTermKms: number;
  user: string;
  dueDate: Date;
  maxKms: number;
  numDays: number;
  numKms: number;
}

const UnknownTerm = "unknown";
const ThreeMonthTerm = "3 months";
type MaintenanceTerms = typeof ThreeMonthTerm | typeof UnknownTerm

interface IMaintenanceTerm {
  days: number
  kms: number
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

const CountryTerms: EnumDictionary<Country, EnumDictionary<MaintenanceTerms, IMaintenanceTerm>> = {
  [Country.Canada]: {

    [ThreeMonthTerm]: {
      days: 120,
      kms: 7000
    } as IMaintenanceTerm,

    [UnknownTerm]: {
      days: 210,
      kms: 11000
    } as IMaintenanceTerm

  },
  [Country.UnitedStates]: { 

      [ThreeMonthTerm]: {
        days: 120,
        kms: 3600 
      } as IMaintenanceTerm, 

      [UnknownTerm]: {
        days: 210,
        kms: 6600 
      } as IMaintenanceTerm

    } 
  };

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent extends FrameworkComponent implements OnInit, AfterViewInit {

  public claim$: Observable<IClaim>;
  public warranty$: Observable<IWarranty>;
  public customer$: Observable<ICustomer>;
  public records$: Observable<MaintenanceRecord[]>;

  @ViewChild("maintScheduleTable") maintScheduleTable: TableAdapterComponent<IClaimsNotes>
  public maintScheduleTableColumns: CellBuilder[]

  constructor(
    private route: ActivatedRoute,
    private maintenanceService: MaintenanceService,
    private claimService: ClaimsService,
    private warrantyService: WarrantyService,
    private customerService: CustomerService) {
      super()
     }

  ngOnInit() {
    const claimId = parseInt(this.route.snapshot.paramMap.get("claimsid"));
    if (claimId) {
      this.claim$ = this.claimService.retrieve(claimId);
      this.claim$.subscribe(claim => {
        this.warranty$ = this.warrantyService.retrieve(claim.warrantyId);
        this.customer$ = this.customerService.retrieve(claim.customerId);

        forkJoin([this.warranty$, this.customer$]).subscribe(([warranty, customer]) => {
          this.loadMaintenanceRecords(claim).subscribe(maintRecords => {
            this.records$ = this.processMaintenanceRecords(warranty, customer, maintRecords);
            this.maintScheduleTable.refresh(this.records$);
          });
        })
      });
    }
    this.buildMaintScheduleTableColumns();
  }
  ngAfterViewInit(){
    super.build(this, 'maintenance-component')
  }

  loadMaintenanceRecords(claim: IClaim) : Observable<IMaintenance[]> {
    const maintenanceRetrieve = new MaintenanceRetrieve();
    maintenanceRetrieve.claimId = claim.id;
    maintenanceRetrieve.warrantyId = claim.warrantyId;

    return this.maintenanceService.retrieveAll(maintenanceRetrieve);
  }

  processMaintenanceRecords(warranty: IWarranty, customer: ICustomer, maintRecords: IMaintenance[])
    : Observable<MaintenanceRecord[]> {

    let odometerDate = warranty.effectiveDate;
    let odometerValue = warranty.vehicle.originalKms;

    const records: MaintenanceRecord[] = maintRecords.map((item, idx) => {

      const terms = CountryTerms[Country[Country[customer.countryId]]];
      let term = terms[item.term];
      if (!term) {
        term = terms[UnknownTerm];
      }

      odometerDate = moment(odometerDate).add(term.days, 'd').toDate();
      odometerValue += term.kms;

      const maxKms = idx == 0 ? term.kms : odometerValue;

      let record = {
        date: item.date,
        kms: item.kms,
        term: item.term,
        maxTermKms: item.maxKms,
        user: item.userId,
        dueDate: odometerDate,
        maxKms: maxKms,
        numDays: Math.abs(moment(item.date).diff(moment(odometerDate), 'days')),
        numKms: item.kms - maxKms
      } as MaintenanceRecord

      odometerDate = item.date;

      return record;
    });

    return of(records);
  }

  buildMaintScheduleTableColumns() {
    this.maintScheduleTableColumns = [
      new CellBuilder("Date of Maintenance", "date", CellType.date),
      new CellBuilder("KMs at this Date", "kms", CellType.number),
      new CellBuilder("Maintenance Term", "term", CellType.text),
      new CellBuilder("Max Term KMs", "maxTermKms", CellType.text),
      new CellBuilder("User Entering Maintenance Schedule", "user", CellType.text),
      new CellBuilder("Due Date", "dueDate", CellType.date),
      new CellBuilder("Max KMs", "maxKms", CellType.number),
      new CellBuilder("Number of Days", "numDays", CellType.number),
      new CellBuilder("Number of KMs", "numKms", CellType.number),
    ];
  }
}
