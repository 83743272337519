import { Component, Input, OnChanges, OnInit, QueryList, SimpleChange, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formatNumber } from '@angular/common';

/*-- Enums --*/
import { DealerStatus } from '../../../../../enums/dealer-status.enum';
import { FormState } from '../../../../../enums/form-state.enum';
import { Role } from '../../../../../enums/role.enum';

/*-- Interfaces --*/
import { IAutoCompleteItem } from '../../../../../interfaces/autocomplete-item.interface';
import { IDealer, IDealerGroup, IDealerStatus, IDealerType } from '../../../interfaces/dealer.interface';
import { IFranchise } from '../../../interfaces/franchise.interface';
import { IRepairCenter } from '../../../interfaces/repair-centre.interface';
import { IUser } from '../../../interfaces/user.interface';

/*-- Services --*/
import { AccountService } from '../../../../account/services/account.service';
import { DealersService } from '../../../services/dealers.service';
import { DealersSharedService } from '../../../services/dealers-shared.service';
import { RepairCentreService } from 'src-private/app/areas/claims/services/repair-centre.service';
import { debounceTime } from 'rxjs/operators';
import { IRepairCentre } from 'src-private/app/areas/claims/interfaces/repair-centre.interface';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnChanges, OnInit {
  @Input() settingsForm: FormGroup;
  @Input() dealer: IDealer;
  @Input() formState: FormState;
  @Input() submitted: boolean;

  private convenienceFee: number;
  private currentDealer: IDealer;
  private dealerAccountManager: string;
  private dealerAccountManagers: IUser[];
  private dealerGroup: string;
  private dealerGroups: IDealerGroup[];
  private dealerStatus: string;
  private dealerStatuses: IDealerStatus[];
  private dealerType: string;
  private dealerTypes: IDealerType[];
  private franchise: string;
  private franchises: IFranchise[];
  private isRegionalSalesManager: boolean;
  private isSubmitted: boolean;
  private repairCenter: string;
  private repairCenterId: number;
  private vehicleKmRange: number[];
  private vehicleYearRange: number[];

  public isDealerAccountManager: boolean;
  public isStatic: boolean;

  public filteredRepairCentres: IDealer[];
  public repairCentreAutoCompleteText: string;
  public isLoading: boolean = false;

  @ViewChildren(MatAutocompleteTrigger) autoCompletes: QueryList<MatAutocompleteTrigger>;

  constructor(
    private accountService: AccountService,
    private dealersService: DealersService,
    private dealersSharedService: DealersSharedService,
    private repairCentreService: RepairCentreService
  ) { }

  //#region - Lifecycle
  ngOnChanges(changes: SimpleChanges) {
    const dealer: SimpleChange = changes.dealer;
    const formState: SimpleChange = changes.formState;
    const submitted: SimpleChange = changes.submitted;

    if (dealer && dealer.currentValue) {
      this.currentDealer = dealer.currentValue;

      if (this.isStatic) {
        this.setFranchise();
        this.setRepairCenter();
        this.setVendorGroup();
        this.setVendorStatus();
        this.setVendorType();
      }

      if (dealer) {
        this.currentDealer = dealer.currentValue;

        this.repairCenterId = this.currentDealer.repairCentreId;

        this.settingsForm.controls['repairCentreId'].setValue(this.currentDealer.repairCentreId);
        if(this.currentDealer.repairCentreId != null){
          this.repairCentreService.retrieve(this.currentDealer.repairCentreId).subscribe((repairCentre) => {
            (<HTMLInputElement>document.getElementById("repairCentreSelect")).value = repairCentre.name
          });
        }
        
        if (this.isStatic && !this.isDealerAccountManager) {
          this.setDealerAccountManager();
        }

        this.retrieveConvenienceFee(this.currentDealer.vendorId);
      } else {
        this.retrieveConvenienceFee(null);
      }
    }

    if (formState && formState.currentValue) {
      switch (formState.currentValue) {
        case FormState.Add:
          this.isStatic = false;

          this.settingsForm.controls['vendorStatusId'].setValue(DealerStatus.Potential);
          this.settingsForm.controls['vendorStatusId'].disable();
          this.settingsForm.controls['convenienceFee'].setValue(this.convenienceFee);

          Object.keys(this.settingsForm.controls).forEach(key => {
            this.settingsForm.get(key).markAsUntouched();
          });

          break;
        case FormState.Modify:
          this.isStatic = this.accountService.isInRole(Role.DealerAccountManager) ||
            this.accountService.isInRole(Role.RegionalSalesManager);

          this.settingsForm.controls['vendorStatusId'].enable();

          break;
        case FormState.Static:
          this.isStatic = true;

          break;
      }
    }

    if (submitted && submitted.currentValue) {
      this.isSubmitted = submitted.currentValue;

      Object.keys(this.settingsForm.controls).forEach(key => {
        this.settingsForm.get(key).markAsTouched();
      });
    }
  }

  ngOnInit() {
    this.isDealerAccountManager = this.accountService.isInRole(Role.DealerAccountManager);
    this.isRegionalSalesManager = this.accountService.isInRole(Role.RegionalSalesManager);

    this.getVehicleKmRange().then(result => { this.vehicleKmRange = result; });
    this.getVehicleYearRange().then(result => { this.vehicleYearRange = result; });

    this.lookupFranchises();
    this.lookupVendorGroups();
    this.lookupVendorStatuses();
    this.lookupVendorTypes();
    this.retrieveDealerAccountManagers();

    var self = this

    window.addEventListener('scroll', () => {
      this.closeAutoComplete(self);
    }, true);
  }
  //#endregion

  //#region - Getters
  get f() { return this.settingsForm.controls; }
  //#endregion

  //#region - Events
  onConvenienceFeeBlur() {
    const convenienceFee = this.settingsForm.get('convenienceFee').value;

    if (!convenienceFee) {
      this.settingsForm.controls['convenienceFee'].setValue(this.convenienceFee);
    }
  }
  //#endregion

  //#region - Private Methods
  private async getVehicleKmRange() {
    const maxKm = 250000;
    const array = [];

    for (let minKm = 0; minKm <= maxKm; minKm += 10000) {
      array.push({
        text: formatNumber(minKm, 'en-CA'),
        value: minKm
      });
    }

    return array;
  }

  private async getVehicleYearRange() {
    const maxYear = 30;
    const array = [];

    for (let minYear = 0; minYear <= maxYear; minYear++) {
      array.push({
        text: formatNumber(minYear, 'en-CA'),
        value: minYear
      });
    }

    return array;
  }

  private setDealerAccountManager() {
    if (this.dealerAccountManagers) {
      this.dealerAccountManager = this.currentDealer.dealerAccountManagerId ?
        this.dealerAccountManagers.filter(item => item.id === this.currentDealer.dealerAccountManagerId)[0].name :
        null;
    }
  }

  private setFranchise() {
    if (this.franchises && this.currentDealer) {
      this.franchise = this.currentDealer.franchiseId ?
        this.franchises.filter(item => item.franchiseId === this.currentDealer.franchiseId)[0].label :
        null;
    }
  }

  private setRepairCenter() {
    if (this.currentDealer) {
      if(this.currentDealer.repairCentreId != null)
      {
        this.repairCentreService.retrieve(this.currentDealer.repairCentreId).subscribe((repairCentre) => {
          this.repairCenter = repairCentre.name;
        })
      }
      else {
        this.repairCenter = '';
      }
    }
  }

  private setVendorGroup() {
    if (this.dealerGroups && this.currentDealer) {
      this.dealerGroup = this.currentDealer.vendorGroupId ?
        this.dealerGroups.filter(item => item.vendorGroupId === this.currentDealer.vendorGroupId)[0].label :
        null;
    }
  }

  private setVendorStatus() {
    if (this.dealerStatuses && this.currentDealer) {
      this.dealerStatus = this.currentDealer.vendorStatusId ?
        this.dealerStatuses.filter(item => item.vendorStatusId === this.currentDealer.vendorStatusId)[0].label :
        null;
    }
  }

  private setVendorType() {
    if (this.dealerTypes && this.currentDealer) {
      this.dealerType = this.currentDealer.vendorTypeId ?
        this.dealerTypes.filter(item => item.vendorTypeId === this.currentDealer.vendorTypeId)[0].label :
        null;
    }
  }
  //#endregion

  //#region - API Methods
  private lookupFranchises(): void {
    this.dealersService.franchiseLookup().subscribe(
      response => {
        const data: IFranchise[] = response;

        this.franchises = data;

        if (this.currentDealer && this.isStatic) {
          this.setFranchise();
        }
      }
    );
  }

  private lookupVendorGroups(): void {
    this.dealersService.dealerGroupLookup().subscribe(
      response => {
        const data: IDealerGroup[] = response;

        this.dealerGroups = data;

        if (this.currentDealer && this.isStatic) {
          this.setVendorGroup();
        }
      }
    );
  }

  private lookupVendorStatuses(): void {
    this.dealersService.dealerStatusLookup().subscribe(
      response => {
        const data: IDealerStatus[] = response;

        this.dealerStatuses = data;

        if (this.currentDealer && this.isStatic) {
          this.setVendorStatus();
        }
      }
    );
  }

  private lookupVendorTypes(): void {
    this.dealersService.dealerTypeLookup().subscribe(
      response => {
        const data: IDealerType[] = response;

        this.dealerTypes = data;

        if (this.currentDealer && this.isStatic) {
          this.setVendorType();
        }
      }
    );
  }

  private retrieveConvenienceFee(dealerId: number): void {
    this.dealersService.convenienceFeeRetrieve(dealerId).subscribe(
      response => {
        const data: number = response;

        this.convenienceFee = data;

        if (!this.isStatic) {
          this.settingsForm.controls['convenienceFee'].setValue(this.convenienceFee);
        }
      }
    );
  }

  private retrieveDealerAccountManagers(): void {
    this.dealersService.userRetrieveByRole(Role.DealerAccountManager).subscribe(
      response => {
        const data: IUser[] = response;

        this.dealerAccountManagers = data;

        if (this.currentDealer && this.isStatic && !this.isDealerAccountManager) {
          this.setDealerAccountManager();
        }
      }
    );
  }

  filterRepairCentres(repairCentreText: string) {
    debounceTime(750);
    var loadingThis = this;
    let loadingTimer = setTimeout(() => {
      loadingThis.isLoading = true;
    }, 250);
    this.repairCentreAutoCompleteText = repairCentreText;
    if (repairCentreText.length > 0) {
      this.repairCentreService.repairCentreLookupByName(repairCentreText)
        .subscribe(repairCentres => {
          if (repairCentres) {
            this.loadRepairCentre(loadingTimer, repairCentres);
          }
        });
    }
    else {
      this.loadRepairCentre(loadingTimer);
    }
  }
  loadRepairCentre(loadingTimer: any, repairCentre = []) {
    //if you filter when more than 0 char than a visual bug can occur if the user deletes all char.
    //The lookup will send request on the last char, then on 0 char the list will clear, then the service
    //responds and populates the list even when the input is empty
    //therfore only populate list if adjuster text is still grater than 0
    if (this.repairCentreAutoCompleteText.length > 0) {
      this.filteredRepairCentres = repairCentre;
    }
    else {
      this.filteredRepairCentres = [];
    }
    this.isLoading = false;
    clearTimeout(loadingTimer)
  }

  onRepairCentreSelected(selected, event) {
    //ignores deselect trigger of previous selected
    if (event.isUserInput) {
      if (selected === null) { return; }
      this.settingsForm.controls['repairCentreId'].setValue(selected.id);
    }
  }

  repairCentreAutoCompleteDisplay(value: IRepairCentre) {
    if (value && value.id) {
      return `[${value.id}] ${value.name}`
    }
  }

  closeAutoComplete(self) {
    self.autoCompletes.forEach((autoComplete) => {
      autoComplete.closePanel();
    })
  }
}
