<mat-checkbox [(ngModel)]="coverage.IsChecked" [disabled]="disabled" color="primary">{{coverage.CoverageLabel}}</mat-checkbox>
<mat-form-field class="bonus-bucks-amount">
    <span matPrefix>$</span>  
    <input matInput type="number" [disabled]="disabled" min="0" oninput="this.value = Math.abs(this.value)" placeholder="Bonus Bucks" [(ngModel)]="coverage.BonusBucksAmount" [disabled]="!coverage.IsChecked">    
</mat-form-field>
<fa-icon class="arrow-style" [icon]="arrow"></fa-icon>

<table class="mat-elevation-z8 coverage-table">
    <tr>
      <th *ngFor="let col of filteredColumns">{{col.name}}</th>
      <th>Bonus Bucks</th>
      <th>Total</th>
    </tr>
    <tr *ngFor="let band of coverage.Bands">
        <td *ngFor="let col of filteredColumns">{{band[col.prop]}}</td>
        <td>{{coverage.BonusBucksAmount}}</td>
        <td >{{getTotal(band)}}</td>
    </tr>
</table>
