import { IRepairCentre } from '../interfaces/repair-centre.interface';
import { Country } from 'src-private/app/enums/bonus-bucks.enums';

export class RepairCentre implements IRepairCentre {
  id: number
  name: string
  contact: string
  contactExt: string
  address: string
  city: string
  province: string
  postalCode: string
  phone: string
  fax: string
  website: string
  email: string
  status: string
  type: string
  grade: string
  hourlyRate: number
  dealerId: string
  dealerName: string
  country: string
  preferred: boolean
  latitude: number
  longitude: number
  version: number

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  constructor(rc = null) {
    if (rc != null) {
      this.address = rc.repairCentreAddress,
        this.city = rc.repairCentreCity,
        this.contact = rc.repairCentreContact,
        this.country = rc.repairCentreCountry,
        this.dealerId = rc.repairCentreDealerId,
        this.dealerName = rc.repairCenterDealerName,
        this.email = rc.repairCentreEmail,
        this.fax = rc.repairCentreFax,
        this.grade = rc.repairCentreGrade,
        this.hourlyRate = rc.repairCentreHourlyRate,
        this.id = rc.repairCentreId,
        this.name = rc.repairCentreName,
        this.phone = rc.repairCentrePhone,
        this.postalCode = rc.repairCentrePostalCode,
        this.province = rc.repairCentreProvince,
        this.type = rc.repairCentreRepairType,
        this.status = rc.repairCentreStatus,
        this.type = rc.repairCentreType,
        this.website = rc.repairCentreWebsite,
        this.latitude = rc.repairCentreLatitude,
        this.longitude = rc.repairCentreLongitude
    } else {
      this.id = null;
      this.name = "";
      this.contact = "";
      this.contactExt = "";
      this.address = "";
      this.city = "";
      this.province = "";
      this.postalCode = "";
      this.phone = "";
      this.fax = "";
      this.website = "";
      this.email = "";
      this.status = "";
      this.type = "";
      this.grade = "";
      this.hourlyRate = null;
      this.dealerId = "";
      this.dealerName = "";
      this.country = Country[Country.Canada];
      this.preferred = false
    }
  }
}
