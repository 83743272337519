import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ChangeDetectorRef,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { Params, Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

/*-- Interfaces --*/
import { IEazeeTrakFile } from "./areas/eazeetrak/interfaces/eazeetrakfile.interface";
import { Notification } from "./interfaces/notification.interface";
import { ISearchResult } from "./interfaces/search-result.interface";
import { IRecentResult } from "./interfaces/recent-result.interface";
import { Sidebar as SidebarLocation } from "../app/enums/sidebar.enum";

/*-- Services --*/
import { AccountService } from "./areas/account/services/account.service";
import { AvatarService } from "./services/avatar.service";
import { EazeeTrakService } from "./areas/eazeetrak/services/eazeetrak.service";
import { WindowRef } from "./services/window.service";
import { NotificationService } from "./services/notification.service";
import { SearchResultService } from "./services/search-result.service";
import { MapService } from "./areas/claims/services/map.service";

/*-- Third Party --*/
import { Sidebar } from "ng-sidebar";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import {
  faPlus,
  faBell,
  faTimes,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { DynamicComponentService } from "./services/dynamic-component.service";
import { QuickAddComponent } from "./areas/actionbar/quick-add/quick-add.component";
import { OmniSearchComponent } from "./areas/actionbar/omni-search/omni-search.component";
import { NotificationComponent } from "./areas/actionbar/notification/notification.component";
import { isThisTypeNode } from "typescript";
import { NotificationSocketService } from "./services/notification-socket.service";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild("dynamicContainerTop", { read: ViewContainerRef, static: true })
  dynamicComponentTop: ViewContainerRef;
  @ViewChild("dynamicContainerRight", { read: ViewContainerRef, static: true })
  dynamicComponentRight: ViewContainerRef;
  @ViewChild("dynamicContainerBottom", { read: ViewContainerRef, static: true })
  dynamicComponentBottom: ViewContainerRef;
  @ViewChild("sidebarTop", { read: Sidebar })
  sidebarTop: Sidebar;
  @ViewChild("sidebarRight", { static: true }) sidebarRight: Sidebar;
  @ViewChild("sidebarBottom", { static: true }) sidebarBottom: Sidebar;

  notificationRefreshListner: EventEmitter<string> = new EventEmitter<string>();

  faBell = faBell;
  faPlus = faPlus;
  faTimes = faTimes;
  faSearch = faSearch;

  private eazeeTrakFile: IEazeeTrakFile;
  private notificationList: Notification[];
  private searchResults: ISearchResult[];
  private recentList: IRecentResult[];

  isAuthenticated = false;
  sidebarBottomOpened = false;
  sideBarNavigationMode: string;
  sidebarNavigationOpened: boolean;
  sidebarRightOpened = false;
  sidebarTopOpened = false;
  title = "app";
  userFullName: string;
  userId: string;
  visualIndicatorText: string;
  searchTerm: string = "";
  FilteredOption: string = "0";
  notificationsExist: boolean = false;
  isLoading = false;


  constructor(
    private accountService: AccountService,
    private avatarService: AvatarService,
    public dialog: MatDialog,
    private eazeeTrakService: EazeeTrakService,
    private notificationService: NotificationService,
    private searchResultService: SearchResultService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private mapService: MapService,
    private dynamicComponentService: DynamicComponentService,
    private notificationSocketService: NotificationSocketService,
    private oidcSecurityService: OidcSecurityService
  ) {
    router.events.subscribe((val) => {
      this.getVisualIndicatorInfo();
    });
  }

  //#region - Lifecycle
  ngOnInit() {
    this.recentList = [];

    this.oidcSecurityService.checkAuth().subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.oidcSecurityService.authorize();
        return;
      }

      this.userFullName = this.accountService.getUserName();
      this.userId = this.accountService.getUserId();
      this.isAuthenticated = true;
      const at = this.oidcSecurityService.getToken();

      this.handleNotifications();

      setTimeout(() => {
        this.handleEazeeTrakRedirect();
      });

      this.mapService.setupMapScript(this.elementRef).then(() => {
        // console.log("GOOGLE MAPS LOADED");
      });

      this.notificationSocketService.startNotificationSocket();

      this.notificationSocketService.refreshRequested.subscribe(() => {
        this.handleNotifications()
      });

      this.router.navigateByUrl('/eazeetrak');
    });
    
  }
  //#endregion

  //#region - Getters
  get avatarImageUrl() {
    return this.avatarService.getAvatarUrl(this.accountService.getUserName());
  }
  //#endregion

  //#region - Private Methods
  private handleEazeeTrakRedirect(): void {
    const eazeeTrakFileId: number = this.windowRef.nativeWindow.fileId;

    if (eazeeTrakFileId && eazeeTrakFileId !== 0) {
      this.retrieveEazeeTrakFile(eazeeTrakFileId).then(() => {
        const dealerId = this.eazeeTrakFile.vendorId;
        const param = { dealerId: dealerId };
        const queryParams: Params = { f: eazeeTrakFileId };

        this.router.navigate(["dealers", param], { queryParams: queryParams });

        this.windowRef.nativeWindow.fileId = null;
      });
    }
  }

  private handleNotifications(): void {
    this.notificationService.list(this.userId, false).subscribe((data: []) => {
      this.notificationsExist = false;
      if (data.length != 0)
        this.notificationsExist = true;
      this.cdr.detectChanges();
    });
  }


  //#endregion

  //#region - API Methods
  private retrieveEazeeTrakFile(eazeeTrakFileId: number): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.eazeeTrakService
        .eazeeTrakFileRetrieve(eazeeTrakFileId, true)
        .subscribe((response) => {
          const data: IEazeeTrakFile = response;

          this.eazeeTrakFile = data;

          resolve(null);
        });
    });

    return promise;
  }

  // private retrieveNotifications(): Promise<{}> {
  //   var promise = new Promise((resolve, reject) => {
  //     this.notificationService.list(this.userId, false).subscribe(
  //       (response) => {
  //         this.notificationList = [];
  //         var responseData = response as Notification[];

  //         for (let data of responseData) {
  //           this.notificationList.push(data);
  //         }

  //         if (this.notificationList.length == 0) {
  //           this.notificationList = [];
  //           var data = {} as Notification;
  //           data.ID = 0;
  //           data.isRead = true;
  //           data.title = "None found";
  //           data.description = "No notifications found";
  //           data.userID = "0";
  //           this.notificationsExist = false;

  //           this.notificationList.push(data);
  //         } else {
  //           this.notificationsExist = true;
  //         }

  //         resolve(null);
  //       },
  //       () => {
  //         this.notificationList = [];
  //         var data = {} as Notification;
  //         data.ID = 0;
  //         data.isRead = true;
  //         data.title = "Error";
  //         data.description = "Notifications failed!";
  //         data.userID = "0";
  //         this.notificationsExist = false;

  //         this.notificationList.push(data);
  //         reject();
  //       }
  //     );
  //   });
  //   return promise;
  // }

  // markAsRead(notification: Notification) {
  //   notification.isRead = true;
  //   if (notification.ID != 0) {
  //     this.notificationService.update(notification).subscribe(() => {
  //       this.handleNotifications();
  //     });
  //   }
  // }
  //#endregion

  logout() {
    this.accountService.logout();
  }

  private getVisualIndicatorInfo() {

    let route = this.router.url;
    let params = route.substr(1).split('/')

    if (route.includes('warranty')) {
      if (route.includes('claims')) {
        this.visualIndicatorText = 'Warranty - ' + params[3]
      }
      else {
        this.visualIndicatorText = 'Warranty - ' + params[1]
      }
    }

    else if (route.includes('repair-centre')) {

      this.visualIndicatorText = 'Repair Centre';
    }

    else if (route.includes('customer')) {
      if (route.includes('claims')) {
        this.visualIndicatorText = 'Customer - ' + params[3]
      }
      else {
        this.visualIndicatorText = 'Customer - ' + params[1]
      }
    }

    else if (route.includes('claims') && params.length > 1) {
      this.visualIndicatorText = 'Claims - ' + params[1]
    }

    else {
      this.visualIndicatorText = ""
    }

  }

  openQuickAdd() {
    (<HTMLInputElement>document.getElementById("quickAdd")).blur();

    const sidebarLocation = SidebarLocation.Right;
    this.dynamicComponentService.open(this, sidebarLocation);
    this.dynamicComponentService.setRootViewContainerRef(
      this.dynamicComponentRight
    );

    let instance: any = this.dynamicComponentService.addDynamicComponent(
      QuickAddComponent
    );

    instance.modalResponse.subscribe(() => {
      this.dynamicComponentService.closeAndClear(this, sidebarLocation, true);
    });
  }

  openOmniSearch() {
    (<HTMLInputElement>document.getElementById("omnisearch")).blur();

    const sidebarLocation = SidebarLocation.Right;
    this.dynamicComponentService.open(this, sidebarLocation);
    this.dynamicComponentService.setRootViewContainerRef(
      this.dynamicComponentRight
    );

    let instance: any = this.dynamicComponentService.addDynamicComponent(
      OmniSearchComponent
    );

    instance.modalResponse.subscribe(() => {
      this.dynamicComponentService.closeAndClear(this, sidebarLocation, true);
    });
  }

  openNotification() {
    (<HTMLInputElement>document.getElementById("notification")).blur();

    const sidebarLocation = SidebarLocation.Right;
    this.dynamicComponentService.open(this, sidebarLocation);
    this.dynamicComponentService.setRootViewContainerRef(
      this.dynamicComponentRight
    );

    let instance: any = this.dynamicComponentService.addDynamicComponent(
      NotificationComponent
    );
    instance.modalResponse.subscribe(() => {
      (<HTMLInputElement>document.getElementById("notification")).blur();
      this.dynamicComponentService.closeAndClear(this, sidebarLocation, true);
    });
  }

  public onNotificationPanelClose() {
    this.notificationSocketService.notifyHubtoEmitRefreshNotice();
    this.handleNotifications();
  }

  private searchDisplay() {
    var searchBar = document.getElementById("search");
    var searchIcon = document.getElementById("searchIcon");
    if (
      searchBar.classList.contains("search-active") &&
      searchIcon.classList.contains("nav-icon-menuOpen")
    ) {
      searchBar.classList.remove("search-active");
      searchIcon.classList.remove("nav-icon-menuOpen");
      this.searchTerm = "";
      this.FilteredOption = "0";
      this.searchResults = [];
    } else {
      searchBar.classList.add("search-active");
      searchIcon.classList.add("nav-icon-menuOpen");
    }
  }

  private closeSearch(event) {
    var target = event.target;
    if (!target.closest(".searchmenu")) {
      var searchBar = document.getElementById("search");
      var searchIcon = document.getElementById("searchIcon");

      if (
        searchBar.classList.contains("search-active") &&
        searchIcon.classList.contains("nav-icon-menuOpen")
      ) {
        searchBar.classList.remove("search-active");
        searchIcon.classList.remove("nav-icon-menuOpen");
      }
    }
  }

  navigateFunction(param: any) {
    this.notificationService.navigateFunction(param);
  }

  searchTermChange() {
    if (this.searchTerm === "") {
      this.searchResults = [];
    }
  }
}
