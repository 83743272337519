import { Routes, RouterModule } from '@angular/router';

import { DealsStatsComponent } from './components/deals-stats/deals-stats.component';
import { DealersComponent } from './components/dealers.component';
import { DealerInfoComponent } from './components/dealer-info/dealer-info.component';
import { NotesContactsComponent } from './components/notes-contacts/notes-contacts.component';
import { BonusBucksComponent } from './components/bonus-bucks/bonus-bucks.component';

const dealerRoutes: Routes = [
  {
    path: 'dealers',
    component: DealersComponent,
    children: [
      {
        path: 'deals-stats',
        component: DealsStatsComponent,
      },
      {
        path: 'bonus-bucks',
        component: BonusBucksComponent,
      },
      {
        path: 'other',
        component: NotesContactsComponent,
        data: { animation: 'NotesContactsComponent' },
      },
      {
        path: 'dealer-info',
        component: DealerInfoComponent,
        pathMatch: 'full',
        data: { animation: 'DealerInfoComponent' }
      },
      {
        path: '',
        redirectTo: 'dealer-info',
        pathMatch: 'full'
      },
    ]
  }
];

export const dealersRouting = RouterModule.forChild(dealerRoutes);
