import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MaintenanceRetrieve } from '../models/maintenance-retrieve.model';
import { IMaintenance } from '../interfaces/maintenance.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src-private/environments/environment';
import { map } from 'rxjs/operators';
import { IMaintenanceTerm } from '../interfaces/maintenance-term.interface';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(private http: HttpClient) { }

  retrieveAll(maintenanceRetrieve: MaintenanceRetrieve): Observable<IMaintenance[]> {
    let params = new HttpParams();
    Object.keys(maintenanceRetrieve).forEach(function (key) {
      params = params.append(key, maintenanceRetrieve[key]);
    });

    return this.http.get<IMaintenance[]>(`${environment.resourceUrl}api/gwc/maintenances`,
      { params: params });
  }

  add(maintenanceRecord: IMaintenance) {
    return this.http.post<IMaintenance>(`${environment.resourceUrl}api/gwc/maintenance`,
      maintenanceRecord);
  }

  retrieveTerms(): Observable<IMaintenanceTerm[]> {
    return this.http.get<IMaintenanceTerm[]>(`${environment.resourceUrl}api/gwc/maintenance/terms/`);
  }

  retrieveByDate(claimId : number, warrantyId : number, date : string): Observable<IMaintenance[]> {
    let params = new HttpParams();
    params = params.append('claimId', claimId.toString());
    params = params.append('warrantyId', warrantyId.toString());
    params = params.append('date', date.toString());
    return this.http.get<IMaintenance[]>(`${environment.resourceUrl}api/gwc/maintenance/`,
    { params: params });
  }
}
