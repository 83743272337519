<mat-tab-group mat-stretch-tabs dynamicHeight>
    <mat-tab label="Automotive">
        <ng-template matTabContent>
            <app-selected-coverages [coveragePackageArray]="automotiveCoverages"></app-selected-coverages>
        </ng-template>
    </mat-tab>
    <mat-tab label="Tire &amp; Rim">
        <ng-template matTabContent>
            <app-selected-coverages [coveragePackageArray]="tireAndRimCoverages"></app-selected-coverages>
        </ng-template>
    </mat-tab>
</mat-tab-group>
<br />
<div [hidden]="isCoverageSelected" class="alert alert-warning" role="alert">
    No Coverage Selected
</div>
<div>
    <button mat-button matStepperPrevious>Back</button>
    <button mat-button matStepperNext class="float-right" type="button">Next</button>
</div>