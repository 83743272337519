<form *ngIf="repairCentre" [ngClass]="{content : !claimId, standalone : !claimId}">
    <fieldset class="fieldset-form">
        <legend>
            <div class="row">
                <div class="col-6">
                    Repair Centre
                    <fa-icon [icon]="faEdit" class="pr-2" (click)="edit()" role="button"></fa-icon>
                </div>
                <div class="col-6 text-right" *ngIf="repairCentre.preferred">
                    <label class="preferred">Preferred Repair Centre</label>
                </div>
            </div>
        </legend>

        <div class="container-fluid">
            <div class="row">
                <div class="col-4">
                    <div class="form-group-static">
                        <label for="RepairCentre_Name">Repair Centre</label>
                        <input id="RepairCentre_Name" [ngModel]="repairCentre.name"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group-static">
                        <label for="RepairCentre_Contact">Contact</label>
                        <input id="RepairCentre_Contact" [ngModel]="repairCentre.contact"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
                <div class="col-1">
                    <div class="form-group-static">
                        <label for="RepairCentre_ContactExt">Contact Ext.</label>
                        <input id="RepairCentre_ContactExt" [ngModel]="repairCentre.contactExt"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="form-group-static">
                        <label for="RepairCentre_Address">Address</label>
                        <input id="RepairCentre_Address" [ngModel]="repairCentre.address"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group-static">
                        <label for="RepairCentre_City">City</label>
                        <input id="RepairCentre_City" [ngModel]="repairCentre.city"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group-static">
                        <label for="RepairCentre_Province">{{provinceStateLabel}}</label>
                        <input id="RepairCentre_Province" [ngModel]="repairCentre.province"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group-static">
                        <label for="RepairCentre_PostalCode">{{postalCodeStateLabel}}</label>
                        <input id="RepairCentre_PostalCode" [ngModel]="repairCentre.postalCode"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100"
                            [mask]="postalCodeStateMask" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
                <div class="col-2">
                    <div class="mat-form-field mat-form-field-static">
                        <label class="mat-label">Country</label>
                        <mat-radio-group [ngModel]="repairCentre.country" [ngModelOptions]="{standalone: true}"
                            [disabled]="true" (change)="onCountryChange()">
                            <mat-radio-button color="primary" value="Canada">CA</mat-radio-button>
                            <mat-radio-button color="primary" value="USA">US</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group-static">
                        <label for="RepairCentre_Phone">Phone</label>
                        <input id="RepairCentre_Phone" [ngModel]="repairCentre.phone"
                            [ngModelOptions]="{standalone: true}" mask="(000) 000-0000" type="text" readonly
                            class="w-100">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group-static">
                        <label for="RepairCentre_Fax">Fax</label>
                        <input id="RepairCentre_Fax" [ngModel]="repairCentre.fax" [ngModelOptions]="{standalone: true}"
                            mask="(000) 000-0000" type="text" readonly class="w-100">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group-static">
                        <label for="RepairCentre_Website">Website</label>
                        <a [href]="repairCentre.website | http">
                            <input id="RepairCentre_Website" [ngModel]="repairCentre.website"
                                [ngModelOptions]="{standalone: true}" type="text" readonly
                                class="w-100 darklink text-lowercase">
                        </a>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group-static">
                        <label for="RepairCentre_Email">Email</label>
                        <a href="mailto:{{repairCentre.email}}">
                            <input id="RepairCentre_Email" [ngModel]="repairCentre.email"
                                [ngModelOptions]="{standalone: true}" type="text" readonly
                                class="w-100 darklink text-lowercase">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
    <fieldset class="fieldset-form">
        <legend>Service Information</legend>
        <div class="container-fluid">
            <div class="row">
                <div class="col-3">
                    <div class="form-group-static">
                        <label for="RepairCentre_Status">Status</label>
                        <input id="RepairCentre_Status" [ngModel]="repairCentre.status"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="form-group-static">
                        <label for="RepairCentre_Type">Type</label>
                        <input id="RepairCentre_Type" [ngModel]="repairCentre.type"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group-static">
                        <label for="RepairCentre_Grade">Grade</label>
                        <input id="RepairCentre_Grade" [ngModel]="repairCentre.grade"
                            [ngModelOptions]="{standalone: true}" type="text" readonly class="w-100">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="form-group-static">
                        <label for="RepairCentre_HourlyRate">Hourly Rate</label>
                        <input id="RepairCentre_HourlyRate" [ngModel]="repairCentre.hourlyRate"
                            [ngModelOptions]="{standalone: true}" type=" text" readonly class="w-100"
                            aria-label="Hourly Rate" prefix="$" mask="comma_separator">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="form-group-static">
                        <label for="RepairCentre_AffiliatedDealer">Affiliated Dealer</label>
                        <a href="{{repairCentre.dealerWebsite}}">
                            <input id="RepairCentre_AffiliatedDealer" [ngModel]="repairCentre.dealerName"
                                [ngModelOptions]="{standalone: true}" type="text" readonly
                                class="w-100 link text-lowercase">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>
