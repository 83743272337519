import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { environment } from '../../../../../environments/environment';

/*-- Interfaces --*/
import { IEazeeTrakFile, IEazeeTrakFileHistory, IEazeeTrakFileNotification } from '../../interfaces/eazeetrakfile.interface';

/*-- Services --*/
import { AccountService } from '../../../account/services/account.service';
import { AvatarService } from '../../../../services/avatar.service';
import { EazeeTrakService } from '../../services/eazeetrak.service';

/*-- Third Party --*/
import { ToastrService } from 'ngx-toastr';
import { faCheck, faRedoAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-review-file',
  templateUrl: './review-file.component.html',
  styleUrls: ['./review-file.component.scss']
})
export class ReviewFileComponent implements OnInit {
  @Input() eazeeTrakFileId: number;

  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  // public eazeeTrakFile = {} as IEazeeTrakFile;
  public eazeeTrakFile: IEazeeTrakFile;
  public eazeeTrakForm: FormGroup;
  public eazeeTrakFileNotifications: IEazeeTrakFileNotification[];
  public eazeeTrakFileHistory: IEazeeTrakFileHistory[];
  public isSubmitted: boolean;
  public notificationForm: FormGroup;
  public faRedoAlt = faRedoAlt
  public faCheck = faCheck;
  public faTimes = faTimes;

  constructor(
    private accountService: AccountService,
    private avatarService: AvatarService,
    private eazeeTrakService: EazeeTrakService,
    private toastrService: ToastrService
  ) { }

  //#region - Lifecycle
  ngOnInit() {
    this.eazeeTrakForm = new FormGroup({
      comment: new FormControl(null, [Validators.required]),
      pin: new FormControl(null),
      pinDays: new FormControl(null),
      closeFile: new FormControl(null)
    });

    this.notificationForm = new FormGroup({});

    this.retrieveEazeeTrakFile().then(
      () => {
        this.eazeeTrakForm.get('pin').setValue(this.eazeeTrakFile.pinDays !== null);
        this.eazeeTrakForm.get('pinDays').setValue(this.eazeeTrakFile.pinDays);
      }
    );

    this.retrieveEazeeTrakFileHistory();

    this.retrieveEazeeTrakFileNotifications().then(
      () => {
        this.eazeeTrakFileNotifications.forEach(item => {
          this.notificationForm.addControl(item.userId.toString(), new FormControl(item.isPreSelected));
        });
      }
    );
  }
  //#endregion

  //#region - Events
  onCancelClick(): void {
    this.buttonClick.emit(false);
  }

  onReOpenClick(): void {
    this.eazeeTrakFile.isClosed = false;
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.eazeeTrakForm.valid) {
      const closeFile: boolean = this.eazeeTrakForm.get('closeFile').value;
      const comment = this.eazeeTrakForm.get('comment').value;
      const pin: number = this.eazeeTrakForm.get('pin').value;
      const pinDays: number = pin ? this.eazeeTrakForm.get('pinDays').value : 0;
      const eazeeTrakComment: string = this.eazeeTrakForm.get('comment').value;
      const notificationJson: string = JSON.stringify(this.getSelectedNotifications());

      const eazeeTrakFile: IEazeeTrakFile = {
        closeFile: closeFile,
        contactId: null,
        contactName: null,
        dealerAccountManager: null,
        dealerAccountManagerId: null,
        dueDate: null,
        eazeeTrakAssignment: null,
        eazeeTrakComment: comment,
        eazeeTrakFileAction: null,
        eazeeTrakFileId: this.eazeeTrakFileId,
        eazeeTrakFileNotification: null,
        eazeeTrakReason: null,
        eazeeTrakReasonId: null,
        eazeeTrakResult: null,
        eazeeTrakResultId: null,
        isClosed: null,
        isCreatedByUser: null,
        notificationJson: notificationJson,
        pinDays: pin ? pinDays : null,
        vendor: null,
        vendorId: null
      };

      this.updateEazeeTrakFile(eazeeTrakFile).then(
        () => {
          this.buttonClick.emit(true);
        }
      );
    }
  }
  //#endregion

  //#region - Getters
  get f() { return this.eazeeTrakForm.controls; }
  //#endregion

  //#region - Private Methods
  private getSelectedNotifications(): IEazeeTrakFileNotification[] {
    let notifications: IEazeeTrakFileNotification[] = [];

    Object.keys(this.notificationForm.controls).forEach((key: string) => {
      const control = this.notificationForm.get(key);

      if (control.value) {
        notifications.push(this.eazeeTrakFileNotifications.filter(item => item.userId === key)[0]);
      }
    });

    return notifications;
  }
  //#endregion

  //#region - Public Methods
  public getAvatarImageUrl(eazeeTrakFileHistory: IEazeeTrakFileHistory): string {
    return this.avatarService.getAvatarUrl(eazeeTrakFileHistory.user);
  }
  //#endregion

  //#region - API Methods
  private retrieveEazeeTrakFile(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.eazeeTrakService.eazeeTrakFileRetrieve(this.eazeeTrakFileId, true).subscribe(
        response => {
          const data: IEazeeTrakFile = response;

          this.eazeeTrakFile = data;

          resolve(true);
        }
      );
    });

    return promise;
  }

  private retrieveEazeeTrakFileHistory(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.eazeeTrakService.eazeeTrakFileHistoryRetrieve(this.eazeeTrakFileId, true).subscribe(
        response => {
          const data: IEazeeTrakFileHistory[] = response;

          this.eazeeTrakFileHistory = data;

          resolve(true);
        }
      );
    });

    return promise;
  }

  private retrieveEazeeTrakFileNotifications(): Promise<{}> {
    const promise = new Promise((resolve) => {
      this.eazeeTrakService.eazeeTrakFileNotificationRetrieve(this.eazeeTrakFileId, true).subscribe(
        response => {
          const data: IEazeeTrakFileNotification[] = response;

          this.eazeeTrakFileNotifications = data;

          resolve(true);
        }
      );
    });

    return promise;

  }

  private updateEazeeTrakFile(eazeeTrakFile: IEazeeTrakFile): Promise<{}> {
    const title = 'Update EazeeTrak File';

    const promise = new Promise((resolve, reject) => {
      this.eazeeTrakService.eazeeTrakFileUpdate(eazeeTrakFile).subscribe(
        response => {
          // send notifications
          this.sendNotifications(this.eazeeTrakFileId);

          // show toast
          this.toastrService.success('Your EazeeTrak File has been successfully updated.', title);

          resolve(true);
        },
        () => {
          // show error
          this.toastrService.error(environment.messages.apiError, title);

          reject();
        }
      );
    });

    return promise;
  }

  private sendNotifications(eazeeTrakFileId: number): void {
    const sender: string = this.accountService.getUserName();

    this.eazeeTrakService.eazeeTrakSendByResponse(eazeeTrakFileId, sender).subscribe(
      () => { }
    );
  }
  //#endregion
}
