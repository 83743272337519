import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { ClaimsRetrieve } from '../../models/claims-retrieve.model';
import { ClaimsService } from '../../services/claims.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IClaim } from '../../interfaces/claim';
import { ClaimsComponent } from '../claims.component';
import { Debounce } from '../../../../helpers/debounce.decorator';
import { AppConfig, APP_CONFIG } from '../../../../app.config';

//Third Party
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import {Subject} from "rxjs";

@Component({
  selector: 'app-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrls: ['./claims-list.component.scss']
})
export class ClaimsListComponent implements OnInit {

  public form: FormGroup;
  public claims: IClaim[];
  public faSearch = faSearch;
  public isLoading = false;
  public dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject<any>();
  private isDatatablesLoaded = false;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private claimsService: ClaimsService,
    private formBuilder: FormBuilder,
    private claimsComponent: ClaimsComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      claimId: [null],
      warrantyId: [null],
      customerFirstname: [''],
      customerLastname: [''],
      vin: [''],
      dealer: [''],
      assignedAdjuster: ['']
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      paging: true
    };

    this.claimsComponent.setSelectedClaim(null);
    this.sendClaimsRetrieve();
    this.onChanges();
  }

  onClaimIdTextEscKeyDown(): void {
    this.form.get('claimId').setValue('');
  }

  onWarrantyIdTextEscKeyDown(): void {
    this.form.get('warrantyId').setValue('');
  }

  onFirstNameTextEscKeyDown(): void {
    this.form.get('firstName').setValue('');
  }

  onLastNameTextEscKeyDown(): void {
    this.form.get('lastName').setValue('');
  }

  onVinTextEscKeyDown(): void {
    this.form.get('vin').setValue('');
  }

  onDealerTextEscKeyDown(): void {
    this.form.get('dealer').setValue('');
  }

  onAssignedAdjusterTextEscKeyDown(): void {
    this.form.get('assignedAdjuster').setValue('');
  }


  sendClaimsRetrieveDebounce() {
    this.sendClaimsRetrieve();
  }

  sendClaimsRetrieve() {
    this.isLoading = true;
    this.form.disable({ emitEvent: false });
    this.claimsComponent.statusForm.disable({ emitEvent: false });

    const claimsRetrieve = new ClaimsRetrieve();

    Object.keys(this.form.controls).forEach((key: string) => {
      const control = this.form.get(key);

      if (key in claimsRetrieve) {
        let value = control.value;

        if (typeof (value) === 'undefined' || value === '') {
          claimsRetrieve[key] = null;
        }
        else {
          claimsRetrieve[key] = value;
        }
      }
    });

    Object.keys(this.claimsComponent.statusForm.controls).forEach((key: string) => {
      const control = this.claimsComponent.statusForm.get(key);

      if (key in claimsRetrieve) {
        let value = control.value;

        if (typeof (value) === 'undefined' || value === '') {
          claimsRetrieve[key] = null;
        }
        else {
          claimsRetrieve[key] = value;
        }
      }
    });

    const promise = new Promise((resolve, reject) => {
      this.claimsService.retrieveAll(claimsRetrieve).subscribe(
        response => {
          const data: IClaim[] = response;
          this.claims = this.cleanData(data);
          this.isLoading = false;
          this.form.enable({ emitEvent: false });
          this.claimsComponent.statusForm.enable({ emitEvent: false });

          if (!this.isDatatablesLoaded) {
            this.isDatatablesLoaded = true;
            this.dtTrigger.next();
          }

          resolve(true);
        },
        err => {
          this.isLoading = false;
          this.form.enable({ emitEvent: false });
          this.claimsComponent.statusForm.enable({ emitEvent: false });
          reject();
        }
      );
    });

    return promise;
  }

  private onChanges(): void {
    this.form.valueChanges
      .pipe(debounceTime(2000))
      .subscribe((val) => this.sendClaimsRetrieveDebounce());
    this.claimsComponent.statusForm.valueChanges.subscribe(val => this.sendClaimsRetrieveDebounce());
  }

  generateCSV(): void {
    if (!this.claims && !this.claims.length) return;

    let headers = Object.keys(this.claims[0]);
    let str = headers.join(',');
    str += '\r\n';

    this.claims.forEach((claim: IClaim) => {
      let line = '';
      headers.forEach((header: string) => {
        line += claim[header] + ',';
      })
      str += line.slice(0, -1) + '\r\n';
    })

    const a = document.createElement('a');
    const blob = new Blob([str], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'claims_' + new Date() + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  onClaimClick(event, item) {
    this.claimsComponent.setSelectedClaim(item);

    this.router.navigate([item.id, 'detail'], {
      relativeTo: this.activatedRoute
    });
  }

  checkDate(date: Date) {
    var duration = moment.duration(moment().diff(date));
    var days = duration.asDays();

    if (days > this.config.claimsListHighlightRedDaysRangeStart && days < this.config.claimsListHighlightRedDaysRangeEnd) {
      return { 'claim-row-red': true };
    }
    else if (days > this.config.claimsListHighlightYellowDaysRangeStart && days < this.config.claimsListHighlightYellowDaysRangeEnd) {
      return { 'claim-row-yellow': true };
    }
    else {
      return { 'claim-row': true };
    }
  }

  cleanData(data: IClaim[]): IClaim[]{
    data.forEach(claim => {
      if(claim.customerFirstname[0] != undefined && claim.customerLastname[0] != undefined){
      claim.customerFirstname = `${claim.customerFirstname[0].toUpperCase()}${claim.customerFirstname.substr(1, claim.customerFirstname.length).toLowerCase()}`
      claim.customerLastname = `${claim.customerLastname[0].toUpperCase()}${claim.customerLastname.substr(1, claim.customerLastname.length).toLowerCase()}`
    }})
    return data
  }

}
