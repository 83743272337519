import { Component, OnInit } from '@angular/core';
import { BonusBucksValue } from '../../../models/bonus-bucks.Models';
import { BBAmountService } from '../../../services/bb-selected-amount.service';
import { BonusBucksValueType } from 'src-private/app/enums/bonus-bucks.enums';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent implements OnInit {

  selectedBonusBuck: BonusBucksValue;
  bonusBuckValues: BonusBucksValue[];
  constructor(private bbAmountService: BBAmountService) { }

  ngOnInit() {
    this.bonusBuckValues = this.bbAmountService.allBBVals;
    this.bbAmountService.currentBonusBuckValue.subscribe(newVal => this.selectedBonusBuck = newVal);
  }

  onValueClick(newBonusBuck: BonusBucksValue) {
    if (newBonusBuck.amount === -1) {
      newBonusBuck.isActive = true;
      this.selectedBonusBuck.isActive = false;
      return;
    }
    if (this.selectedBonusBuck) {
      this.selectedBonusBuck.isActive = false;
    }
    this.bbAmountService.setBonusBuckValue(newBonusBuck);
  }

  getbonusBucksDisplay(buck: BonusBucksValue) {
    const formatter = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 2
    });

    if (buck.amount === BonusBucksValueType.NoChange) {
      return 'No Change to BB Setup';
    } else if (buck.amount === BonusBucksValueType.Custom) {
      return 'Custom';
    } else if (buck.amount === BonusBucksValueType.CompanyBenchmark) {
      return 'Company\xa0Benchmarks';
    } else {
      return formatter.format(buck.amount);
    }
  }

}
