import { ValidatorFn, FormGroup, ValidationErrors, FormArray } from '@angular/forms';
import { PaymentMethod } from 'src-private/app/enums/bonus-bucks.enums';

export const incompleteRegistrationsValidator: ValidatorFn = (registration: FormGroup): ValidationErrors | null => {
    if (!registration) {
        return null;
    }

    if (registration.get('paymentType').value === PaymentMethod.EFT) {
        if ((registration.get('branchRouting').errors || registration.get('institutionCheck').errors || registration.get('account').errors) || registration.get('eftimage').errors) {
            return { 'invalidEFT': true };
        }
    } else if (registration.value.sin === '') {
        return { 'invalidSIN': true };
    }
};
