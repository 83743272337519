import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IWarranty } from '../interfaces/warranty.interface';
import { WarrantyRetrieve } from '../models/warranty-retrieve.model';

@Injectable({
  providedIn: 'root'
})
export class WarrantyService {

  constructor(private http: HttpClient) { }

  retrieveAll(params: WarrantyRetrieve): Observable<IWarranty[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        httpParams = httpParams.append(key, params[key]);
      }
    });
    return this.http.get<IWarranty[]>(environment.resourceUrl + 'api/gwc/warranty', { params: httpParams });
  }

  retrieve(id: number): Observable<IWarranty> {
    return this.http.get<IWarranty>(`${environment.resourceUrl}api/gwc/warranty/${id}`);
  }
}
