import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave, faWindowClose, faCarBuilding } from '@fortawesome/pro-solid-svg-icons';
import { AppConfig, APP_CONFIG } from 'src-private/app/app.config';
import { IRepairCentre } from '../../../interfaces/repair-centre.interface';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { RepairCentreService } from '../../../services/repair-centre.service'
import { ActivatedRoute } from '@angular/router';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';


@Component({
  selector: 'app-repair-centre-search-dialog',
  templateUrl: './repair-centre-search-dialog.component.html',
  styleUrls: ['./repair-centre-search-dialog.component.scss']
})
export class RepairCentreSearchDialogComponent implements OnInit {
  public faSave = faSave;
  public faWindowClose = faWindowClose;
  public faCarBuilding = faCarBuilding;

  public repairCentreId: number;
  public predictedCities: string[];
  public repairCentres: IRepairCentre[]

  public filteredRepairCentres: IRepairCentre[];
  public predictedRepairCentres: IRepairCentre[];
  public selectedFilter: string;

  public optionsMap = new Map();
  selection: SelectionModel<any>;
  public repairCentreRows: MatTableDataSource<any>;
  public repairCentreColumns = ['name', 'address', 'postalCode', 'phone'];
  private mouseOverInfoWindow: boolean = false;

  public selectedRepairCentre: IRepairCentre;
  private markers: google.maps.Marker[] = [];
  selectedCity: string = '';

  public statusForm: FormGroup;

  @ViewChild('mapContainer') gmap: ElementRef;
  @ViewChildren('mapWidget') gmapWidget: QueryList<ElementRef>;
  map: google.maps.Map;
  service: google.maps.places.PlacesService;
  geocoder: google.maps.Geocoder;
  infoWindow: google.maps.InfoWindow;
  autoCompleteService: any;
  autoCompleteSessionToken: any;

  @ViewChild('citiesAutoCompleteRef', { static: true, read: MatAutocompleteTrigger })
  citiesAutoCompleteRef: MatAutocompleteTrigger;
  @ViewChild('repairCentreInput', { static: true, read: MatAutocompleteTrigger })
  repairCentreInput: MatAutocompleteTrigger;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    private fb: FormBuilder,
    private repairCentreService: RepairCentreService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<RepairCentreSearchDialogComponent>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) data) {
    this.selection = new SelectionModel<any>(false, []);
    this.repairCentreId = data;
  }

  ngOnInit() {

    this.getDefaultCoordinates().then(res => {
      this.mapInitializer(res);

      if (this.repairCentreId) {
        this.repairCentreService.retrieve(this.repairCentreId).subscribe(res => {
          this.selectedRepairCentre = res;
          (<HTMLInputElement>document.getElementById('repairCentreInput')).value = this.selectedRepairCentre.name;

          this.geocodeCity(this.selectedRepairCentre.city).then(res => {
            this.getRepairCentresByCity(res as google.maps.LatLng)
          })
        });
      }
      else {
        this.getRepairCentresByCity(res as google.maps.LatLng);
      }
    });

    this.statusForm = this.fb.group({
      status: ['All'],
    });

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  getDefaultCoordinates() {
    return new Promise((resolve) => {
      if (this.repairCentreId) {
        this.repairCentreService.retrieve(this.repairCentreId).subscribe(res => {
          resolve(new google.maps.LatLng(res.latitude, res.longitude));
        });
      }
      else {
        const lat = 40.730610;
        const lng = -73.935242;
        resolve(new google.maps.LatLng(lat, lng));
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          resolve(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
        });
      }
    });
  }

  mapInitializer(defaultCoordinates) {
    const mapOptions: google.maps.MapOptions = {
      center: defaultCoordinates,
      zoom: 8,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DEFAULT,
        position: google.maps.ControlPosition.TOP_RIGHT
      }
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    this.geocoder = new google.maps.Geocoder();
    this.infoWindow = new google.maps.InfoWindow({
      content: ''
    });
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.autoCompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  sliderBox(controlDiv, map, place, selectedRepairCentreData) {

    if (document.contains(document.getElementById("sliderbox"))) {
      document.getElementById("map_canvas").innerHTML = null;
    }
    var box = document.createElement('div');
    box.id = 'sliderbox';
    box.style.height = '100%';
    box.style.width = '100%';
    box.style.backgroundColor = 'white';
    box.style.position = "relative";
    box.style.transition = '0.5s';
    box.style.zIndex = "1";
    controlDiv.appendChild(box);

    var toggleBtn = document.createElement('button');
    toggleBtn.id = 'toggleBtn';
    toggleBtn.type = 'button';
    toggleBtn.innerHTML = '<span>&#8249;</span>';
    toggleBtn.style.width = "17px";
    toggleBtn.style.fontSize = "20px";
    toggleBtn.style.height = '30px';
    toggleBtn.style.position = "absolute";
    toggleBtn.style.right = "-17px";
    toggleBtn.style.border = "none";
    toggleBtn.onclick = function () {
      var container = document.getElementById('sliderbox');
      container.style.width = '0px';
      let children: HTMLCollectionOf<HTMLElement> = container.children as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < children.length; i++) {
        if (children[i].id != 'imgLoc')
          children[i].style.display = "none";
      }
      document.getElementById('toggleBtnClose').style.display = "block";
    };
    toggleBtn.style.borderLeft = "1px solid #D4D4D4";

    box.appendChild(toggleBtn);

    toggleBtn = document.createElement('button');
    toggleBtn.id = 'toggleBtnClose';
    toggleBtn.type = 'button';
    toggleBtn.innerHTML = '<span>&#8250;</span>';
    toggleBtn.style.width = "17px";
    toggleBtn.style.fontSize = "20px";
    toggleBtn.style.height = '30px';
    toggleBtn.style.position = "absolute";
    toggleBtn.style.right = "-17px";
    toggleBtn.style.border = "none";
    toggleBtn.style.display = "none";
    toggleBtn.onclick = function () {
      var container = document.getElementById('sliderbox');
      container.style.width = '100%';
      let children: HTMLCollectionOf<HTMLElement> = container.children as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < children.length; i++) {
        if (children[i].id != 'imgLoc')
          children[i].style.display = "block";
      }
      document.getElementById('toggleBtnClose').style.display = "none";
    };
    toggleBtn.style.borderLeft = "1px solid #D4D4D4";

    box.appendChild(toggleBtn);

    if(place.photos != undefined && place.photos.length > 0) {
      var img = document.createElement('img');
      img.id = 'imgLoc';
      img.src =  place.photos[0].getUrl();
      img.style.height = "35%";
      img.style.maxHeight = "150px";
      img.style.width = "100%";
      box.appendChild(img);
    }
    else{
      var rating = document.createElement("div");
      rating.innerHTML = document.getElementById("buildingIcon").innerHTML;
      rating.style.width = "100%";
      rating.style.textAlign = "center";
      box.appendChild(rating); 
    }
    
    var name = document.createElement('h5');
    name.id = 'locName';
    name.innerText = selectedRepairCentreData.name;
    name.style.width = "100%";
    name.style.padding = "10px";
    box.appendChild(name);

    if(place.rating != undefined){
      var rating = document.createElement("h6");
      rating.className = 'rating';
      rating.innerText = '<b style="font-weight:500;">Rating:</b> ' + place.rating + '/5';
      rating.style.width = "100%";
      rating.style.padding = "10px";
      rating.style.paddingTop = "0px";
      rating.style.fontWeight = '300';
      box.appendChild(rating);  
    }

    if(selectedRepairCentreData.website != undefined && selectedRepairCentreData.website != '')
    {
      var rating = document.createElement("h6");
      rating.innerHTML = '<b style="font-weight:500;">Website:</b> <a target="_blank" href="//' + selectedRepairCentreData.website + '"> ' + selectedRepairCentreData.website + ' </a>';
      rating.style.width = "100%";
      rating.style.padding = "10px";
      rating.style.paddingTop = "0px";
      rating.style.fontWeight = '300';
      box.appendChild(rating); 
    }

    if(selectedRepairCentreData.email != undefined && selectedRepairCentreData.email != '')
    {
      var rating = document.createElement("h6");
      rating.innerHTML = '<b style="font-weight:500;">E-mail:</b> <a href="mailto:' + selectedRepairCentreData.email + '"> ' + selectedRepairCentreData.email + ' </a>';
      rating.style.width = "100%";
      rating.style.padding = "10px";
      rating.style.paddingTop = "0px";
      rating.style.fontWeight = '300';
      box.appendChild(rating); 
    }

    if(selectedRepairCentreData.phone != undefined && selectedRepairCentreData.phone != '')
    {
      var rating = document.createElement("h6");
      rating.innerHTML = '<b style="font-weight:500;">Phone:</b> ' + selectedRepairCentreData.phone;
      rating.style.width = "100%";
      rating.style.padding = "10px";
      rating.style.paddingTop = "0px";
      rating.style.fontWeight = '300';
      box.appendChild(rating); 
    }

    var address = document.createElement('h6');
    address.innerHTML = '<b style="font-weight:500;">Address:</b> ' + place.formatted_address;
    address.style.width = "100%";
    address.style.padding = "10px";
    address.style.paddingTop = "0px";
    address.style.fontWeight = '300';
    box.appendChild(address);

    map.appendChild(controlDiv);
  }

  clear() {
    this.setMapMarkers(null);
    this.markers = [];
    this.selectedCity = '';
    this.predictedCities = [];
    this.predictedRepairCentres = []
    this.selectedRepairCentre = null;
    (document.getElementById('repairCentreInput') as HTMLInputElement).value = '';
    this.repairCentres = [];
  }

  citiesAutoComplete(cityPartialText) {
    if (cityPartialText.length >= 3) {
      const predictedCitiesCallBack = (predictions, status) => {

        if (status === google.maps.places.PlacesServiceStatus.OK || predictions) {
          this.predictedCities = [];

          predictions.forEach((prediction) => {
            this.predictedCities.push(prediction.description);
          });
        }
      };

      let predictionParameters = {
        input: cityPartialText,
        types: ['(cities)'],
        componentRestrictions: { country: ['ca', 'us'] },
        sessionToken: this.autoCompleteSessionToken
      };
      this.autoCompleteService.getPlacePredictions(predictionParameters, predictedCitiesCallBack);
    }

    else if (cityPartialText === "") {
      this.predictedCities = [];
    }
  }

  repairCentreAutoComplete(repairCentrePartialText) {
    if (repairCentrePartialText !== '') {
      this.predictedRepairCentres = this.repairCentres.filter(r =>
        r.name.toLocaleLowerCase().startsWith(repairCentrePartialText.toLowerCase())
      )
    }
    else {
      this.predictedRepairCentres = this.repairCentres
      this.showLocation();
    }
    this.formatTable(this.predictedRepairCentres)
  }

  geocodeCity(city) {
    return new Promise(resolve => {
      this.selectedCity = city;
      this.geocoder.geocode({
        'address': `${city}`
      }, (results, status) => {
        if (status === 'OK') {
          resolve(results[0].geometry.location);
        }
      });
    })
  }

  getRepairCentresByCity(cityCoords: google.maps.LatLng) {
    this.repairCentreService.retrieveRepairCentresInCity(
      cityCoords.lat(),
      cityCoords.lng(),
      this.config.mapCityRadius)
      .subscribe(res => {
        this.repairCentres = res;
        this.predictedRepairCentres = res;
        this.filterRepairCenters().then(() => {
          this.showLocation()
          this.formatTable(this.filteredRepairCentres);
        });;
      })
  }

  resetSelectedRepairCentre() {
    this.selectedRepairCentre = null;
    (<HTMLInputElement>document.getElementById('repairCentreInput')).value = "";
  }

  selectCity(city, event) {
    //ignores deselect trigger of previous selected
    if (event.isUserInput) {
      this.resetSelectedRepairCentre();
      this.geocodeCity(city).then(res => {
        this.getRepairCentresByCity(res as google.maps.LatLng)
      })
    }
  }

  showLocation() {
    this.setMapMarkers(null);
    this.markers = [];
    let repairCentres: IRepairCentre[] = [];

    if (this.selectedRepairCentre) {
      repairCentres.push(this.selectedRepairCentre);
    } else {
      repairCentres = this.filteredRepairCentres;
    }

    repairCentres.forEach(repairCentre => {
      if ((repairCentre.latitude != 0 && (isFinite(repairCentre.latitude) && Math.abs(repairCentre.latitude) <= 90)) &&
        (repairCentre.longitude != 0 && (isFinite(repairCentre.longitude) && Math.abs(repairCentre.longitude) <= 180))) {
        console.log('Using Lat Long');
        this.createMarker(repairCentre, new google.maps.LatLng(repairCentre.latitude, repairCentre.longitude));
        var request = {
          query: repairCentre.address + ', ' + repairCentre.postalCode + ', ' + repairCentre.country,
          fields: ['photos', 'formatted_address', 'icon', 'name', 'rating', 'opening_hours', 'geometry', 'business_status', 'geometry.viewport', 'geometry.location', 'photos', 'place_id', 'plus_code', 'type']
        }
        this.service = new google.maps.places.PlacesService(this.map)
        this.service.findPlaceFromQuery(request, this.callback.bind(this));
      }
      else { //if lat/long aren't defined, try geolocating the repair centre address
        console.log('Geolocating');
        this.geocoder.geocode({
          'address': `${repairCentre.address}, ${repairCentre.city}, ${repairCentre.province}. ${repairCentre.country}`
        }, (results, status) => {
          if (status === 'OK') {
            this.createMarker(repairCentre, results[0].geometry.location);
          };
        });
      }
    });
    this.setMapMarkers(this.map);
  }

  callback(results, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      if (results) {
        var place = results[0];
        var sliderBoxDiv = document.createElement('div');
        this.sliderBox(sliderBoxDiv, this.gmapWidget.first.nativeElement, place, this.selectedRepairCentre);
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.gmapWidget.first.nativeElement);
      }
    }
    else {
      console.log('Address do not exist');
    }
  }

  createMarker(repairCentre: IRepairCentre, location: google.maps.LatLng) {
    this.map.setCenter(location);
    var marker = new google.maps.Marker({
      map: this.map,
      position: location
    });
    marker.addListener('mouseover', () => {
      this.infoWindow.setContent(
        `<p id="infoWindowContent"><strong>${repairCentre.name}</strong></p>
              <p>${repairCentre.address}, ${repairCentre.city}, ${repairCentre.province} ${repairCentre.postalCode}</p>`
      );
      this.infoWindow.setZIndex(-1);
      this.infoWindow.open(this.map, marker);
      this.addOpenInfoWindowListeners();
    });
    marker.addListener('mouseout', () => {
      setTimeout(() => {
        if (!this.mouseOverInfoWindow) {
          this.infoWindow.close();
        }
      }, 400);
    });
    this.markers.push(marker);
  }

  setMapMarkers(map: google.maps.Map | null) {
    this.markers.forEach(marker => {
      marker.setMap(map);
    });
  }

  addOpenInfoWindowListeners() {

    let infoWindowElement = ((document.getElementById('infoWindowContent') === null || document.getElementById('infoWindowContent') === undefined) ||
      (document.getElementById('infoWindowContent').parentNode === null || document.getElementById('infoWindowContent').parentNode === undefined) ||
      (document.getElementById('infoWindowContent').parentNode.parentNode === null || document.getElementById('infoWindowContent').parentNode.parentNode === undefined)) ?
      undefined : document.getElementById('infoWindowContent').parentNode.parentNode.parentNode;


    if (infoWindowElement) {
      infoWindowElement.addEventListener('mouseleave', () => {
        this.infoWindow.close();
        this.mouseOverInfoWindow = false;
      });

      infoWindowElement.addEventListener('mouseenter', () => {
        this.mouseOverInfoWindow = true;
      });
    }
  }

  formatTable(repairCentresToFormat: IRepairCentre[]) {

    let formattedRepairCenter = [];

    repairCentresToFormat.forEach(repairCentre => {
      let obj = {};
      this.repairCentreColumns.forEach(repairCentreColumns => {
        obj[repairCentreColumns] = repairCentre[repairCentreColumns];
      })
      this.optionsMap.set(obj, repairCentre);
      formattedRepairCenter.push(obj);
    })

    formattedRepairCenter.map(row => {
      if (row.selected) {
        this.selection.select(row);
      }
    });

    this.repairCentreRows = new MatTableDataSource(formattedRepairCenter);
  }

  getRepairCentreDisplay(repairCentre): string {
    let display = '';

    const numSectionWidth = Math.floor((document.getElementById('repairCentreInput') as HTMLInputElement).offsetWidth / Object.keys(repairCentre).length);

    let elementSpan = <HTMLSpanElement>document.getElementById('spanRuler');
    elementSpan.hidden = false;
    const charWidth = elementSpan.offsetWidth + 2;
    elementSpan.hidden = true;

    for (const key in repairCentre) {
      const extraSpaces = Math.floor((numSectionWidth / charWidth) - (repairCentre[key].length));
      if (extraSpaces > 0) {
        if (key !== Object.keys(repairCentre)[Object.keys(repairCentre).length - 1]) {
          display += (repairCentre[key] + Array(extraSpaces).fill('\xa0').join(''));
        } else {
          display += repairCentre[key];
        }
      } else {
        display += (repairCentre[key] + ' ');
      }
    }
    return display;
  }

  resetRepairCentreCursor() {
    (<HTMLInputElement>document.getElementById('repairCentreInput')).focus();
    (<HTMLInputElement>document.getElementById('repairCentreInput')).setSelectionRange(0, 0);
    (<HTMLInputElement>document.getElementById('repairCentreInput')).blur();
    (<HTMLInputElement>document.getElementById('repairCentreInput')).focus();
    this.closeAutoComplete()
  }

  setRepairCentre(value) {
    this.selectedRepairCentre = value
    this.resetRepairCentreCursor();
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' })
  }

  confirm() {
    if (this.selectedRepairCentre) {

      this.dialogRef.close({ data: this.selectedRepairCentre });
    }
  }

  setFilter(value) {
    this.selectedFilter = value;
    this.filterRepairCenters().then(() => {
      this.showLocation()
      this.formatTable(this.filteredRepairCentres);
    });
  }

  filterRepairCenters() {
    return new Promise(resolve => {
      if (this.selectedFilter === "Preferred") {
        this.filteredRepairCentres = this.predictedRepairCentres.filter(repairCenter =>
          repairCenter.preferred
        );
      }
      else {
        this.filteredRepairCentres = this.predictedRepairCentres;
      }
      resolve(null);
    })
  }

  closeAutoComplete() {
    this.citiesAutoCompleteRef.closePanel();
    this.repairCentreInput.closePanel();
  }

  openAutocomplete(trigger) {
    trigger.openPanel();
  }
}
